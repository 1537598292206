<div class="job-form" fxLayout="row" fxLayoutGap="20px">
  <div fxFlex="1 0 30%" class="job-form-info">
    <h5 class="job-form-title mb-2">
      New Job From XML<br /><span class="manufacturer-name">{{ (profile$ | async)?.manufacturer.name }}</span>
    </h5>
    <p>Begin a new job by bringing your own XML roofing geometry file from a third party.</p>
    <br />
    <p>This XML file must be formatted with 3D point, edge, and face information.</p>
    <br />
    <p><b>XML takeoffs from these third paries are acceptable:</b></p>
    <ul class="valid-documents">
      <li>EagleView</li>
      <li>TopView (v9.19 or later)</li>
      <li>Hover</li>
    </ul>
  </div>
  <div fxFlex="1 1 35%" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px" [formGroup]="newJobForm">
    <div fxFlex="0 0 auto" class="form-field">
      <div class="form-label">Job Name<sup>*</sup></div>
      <input pInputText class="p-inputtext-sm" type="text" formControlName="name" placeholder="" maxlength="75" />
      <div class="form-error">
        <span *ngIf="name.invalid && name.value.length == 0 && (name.touched || name.dirty)"
          >A job name is required</span
        >
        <span *ngIf="name.invalid && name.value.length > 0 && (name.touched || name.dirty)"
          >Job name cannot be longer than 75 characters.</span
        >
      </div>
    </div>
    <div fxFlex="0 0 auto" class="form-field">
      <div class="form-label">Job Type<sup>*</sup></div>
      <select formControlName="jobType">
        <option value="">Please select type of job.</option>
        <option *ngFor="let jobType of jobTypes" value="{{ jobType.name }}">
          {{ jobType.name }}
        </option>
      </select>
      <div class="form-error">
        <span *ngIf="jobType.invalid && (jobType.touched || jobType.dirty)">Job Type is required</span>
      </div>
    </div>
    <div *ngIf="branches?.length > 1" fxFlex="0 0 auto" class="form-field">
      <div class="form-label">Billing Branch<sup>*</sup></div>
      <select [formControl]="branchId">
        <option value="">-- Select Branch --</option>
        <option *ngFor="let branch of branches; let i = index" value="{{ branch.id }}">
          {{ branch.name }} ({{ branch.company_id }})
        </option>
      </select>
      <div class="form-error">
        <span *ngIf="branchId.value.length == 0 && (branchId.touched || branchId.dirty)"
          >A branch selection is required</span
        >
      </div>
    </div>
    <small *ngIf="errorMessage" id="username-help" class="p-error">{{ errorMessage }}</small>
  </div>
  <div fxFlex="1 1 35%" fxLayout="column">
    <div fxFlex="0 0 auto" class="file-upload-label">Upload 3D Geometry XML</div>
    <p-fileUpload
      #xmlFileUpload
      (onSelect)="onFileSelected($event)"
      (onClear)="clearFiles()"
      (onRemove)="onRemoveFile($event)"
      accept="text/xml"
      [multiple]="true"
      maxFileSize="200000000"
      [showUploadButton]="false"
    >
    </p-fileUpload>
    <div class="mt-4" fxFlex="0 0 auto" fxLayoutAlign="end center">
      <button
        pButton
        pRipple
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text p-button-secondary"
        [class.elevated]="!roofingWRXEnabled"
        (click)="close()"
      ></button>
      <p-button
        type="submit"
        label="Create Job"
        class="p-button-secondary"
        type="submit"
        [disabled]="submitButtonDisabled || !roofingWRXEnabled || !newJobForm.valid || files.length == 0"
        (onClick)="createJob()"
        styleClass="ml-2"
      ></p-button>
    </div>
  </div>
  <p-dialog
    [header]="dialogData?.header"
    [(visible)]="showJobErrorDialog"
    [modal]="true"
    [style]="{ width: '50vw' }"
    [draggable]="false"
    [resizable]="false"
  >
    <p class="m-0">
      {{ dialogData?.message }}
    </p>
  </p-dialog>
</div>
