import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { AuthModule, StsConfigHttpLoader, StsConfigLoader } from "angular-auth-oidc-client";
import { AppComponent } from "./app.component";
import { CookieService } from "ngx-cookie-service";
import { CoreModule } from "../core/core.module";
import { LoadingInterceptor } from "../core/interceptor/loading.interceptor";
import { HomeModule } from "./home/home.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppInitializerService } from "../shared/services/app-initializer.service";
import { AppState } from "../shared/services/app-state";
import { AuthenticationService } from "../shared/services/authentication.service";
import { NotificationService } from "../shared/services/notification.service";
import { FeaturesModule } from "../features/features.module";
import { SharedModule } from "../shared/shared.module";
import { MatDialogModule } from "@angular/material/dialog";
import { CommonModule } from "@angular/common";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { LicenseAgreementComponent } from "./license-agreement/license-agreement.component";

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    FeaturesModule,
    HomeModule,
    CoreModule,
    CommonModule,
    SharedModule,
    HttpClientModule,
    MatDialogModule,
    NgIdleKeepaliveModule,
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: authFactory,
        deps: [AppInitializerService],
      },
    }),
    RouterModule.forRoot([]),
  ],
  providers: [
    AppState,
    CookieService,
    NotificationService,
    AppInitializerService,
    AuthenticationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function appInitializerFactory(appInitializerService: AppInitializerService) {
  return () => appInitializerService.loadConfigs();
}

export function authFactory(appInitializerService: AppInitializerService) {
  return new StsConfigHttpLoader(appInitializerService.loadAuthConfiguration());
}
