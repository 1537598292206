import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AdminTask, AdminTaskTypeIds } from "src/app/core/data/models/AdminTask";
import { TaskService } from "src/app/shared/services/task.service";
import { AppState } from "src/app/shared/services/app-state";
import { MessageService } from "primeng/api";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { AdminTaskDetailsComponent } from "./admin-task-details.component";
import { AdminTaskUserRegistrationDetailsComponent } from "./admin-task-user-registration-details.component";

interface AdminTasksTableColumn {
  field: keyof AdminTask;
  header: string;
  dateFormat?: string;
}

@Component({
  selector: "app-admin-tasks-review",
  templateUrl: "./admin-tasks-review.component.html",
  styleUrls: ["./admin-tasks-review.component.scss"],
  providers: [MessageService, DialogService],
})
export class AdminTasksReviewComponent implements OnInit {
  ref: DynamicDialogRef | undefined;
  newCompanyCount: number = 0;
  newUserCount: number = 0;
  tasks: AdminTask[] = [];
  isLoadingTasks: boolean = false;
  adminTasksTableColumns: AdminTasksTableColumn[];

  filters: Record<keyof object, string> = {
    typeDisplay: "",
    partnerSystem: "",
    inviteeFullName: "",
    inviteeCompanyName: "",
    inviterCompanyName: "",
    createdOn: "",
  };

  constructor(
    public appState: AppState,
    private taskService: TaskService,
    private messageService: MessageService,
    private dialogService: DialogService,
    protected router: Router
  ) {}

  get canUseAdminTasks() {
    return this.appState.canUseAdminTasks;
  }

  ngOnInit(): void {
    if (!this.canUseAdminTasks) {
      this.returnToProjectList();
    }

    this.loadTasks();
    this.initializeColumns();
  }

  initializeColumns() {
    this.adminTasksTableColumns = [
      { field: "typeDisplay", header: "Type" },
      { field: "partnerSystem", header: "Partner System" },
      { field: "inviteeFullName", header: "Name" },
      { field: "inviteeCompanyName", header: "Invitee Company" },
      { field: "createdOn", header: "Created Date" },
    ];
  }

  loadTasks(): void {
    this.isLoadingTasks = true;

    this.taskService.getTasks().subscribe({
      next: (tasks) => {
        this.tasks = tasks;
        this.isLoadingTasks = false;

        this.tasks.forEach((x) => (x.typeDisplay = x.type.split(/(?=[A-Z])/).join(" ")));

        this.newCompanyCount = tasks.filter((x) => x.typeId === AdminTaskTypeIds.NewCompany).length;
        this.newUserCount = tasks.filter((x) => x.typeId === AdminTaskTypeIds.NewUser).length;
      },
      error: (err) => {
        this.showErrorToast(err.message);
        this.isLoadingTasks = false;
      },
    });
  }

  private showErrorToast(message: string): void {
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: message,
    });
  }

  returnToProjectList() {
    this.router.navigate(["jobs"]);
  }

  getTaskInfo(task: AdminTask) {
    const dialogConfig = new DynamicDialogConfig();
    (dialogConfig.closeOnEscape = true),
      (dialogConfig.modal = true),
      (dialogConfig.showHeader = false),
      (dialogConfig.contentStyle = { padding: "0px" });
    dialogConfig.data = task;

    this.ref = this.dialogService.open(
      task.typeId === AdminTaskTypeIds.NewCompany
        ? AdminTaskDetailsComponent
        : AdminTaskUserRegistrationDetailsComponent,
      dialogConfig
    );

    this.ref.onClose.subscribe((taskCompleted: boolean) => {
      if (taskCompleted === true) {
        this.loadTasks();
      }
    });
  }
}
