import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { EMPTY, Subscription } from "rxjs";
import { AppConstants } from "src/app/shared/constants/app-constants";
import { ManufacturerProfile } from "src/app/core/data/models/AppInitializationData";
import { MessageService } from "primeng/api";
import { EagelViewFileTypeIds } from "src/app/core/data/models/EagleViewImageFileTypeIds";
import { WindowRefService } from "src/app/shared/helpers/window-ref.service";
import { catchError, tap } from "rxjs/operators";
import { AppState } from "src/app/shared/services/app-state";
import { OrderService } from "src/app/shared/services/order.service";
import { FileSaverService } from "src/app/shared/services/file-saver.service";
import { SmartBuildReportFileDownloadService } from "src/app/shared/services/smartbuild-report-file-download.service";
import { SmartBuildService } from "src/app/shared/services/smartbuild.service";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-xml-job-details",
  templateUrl: "./xml-job-details.component.html",
  styleUrls: ["./xml-job-details.component.scss"],
  providers: [MessageService],
})
export class XMLJobDetailsComponent implements OnInit {
  submitButtonDislabed = true;
  errorMessage?: string;
  busy?: Subscription;
  roofingWRXJobsEnabled = false;
  profile: ManufacturerProfile;
  profile$ = this.appState.profile$;
  uploadedFiles: any[] = [];
  items: any;
  files: any;
  activeItem: any;
  comments: any;
  showUpdateJobModal = false;
  updateJobForm: FormGroup;
  smartBuildJobEditUrlBase = `${this.appState.configs.SmartBuildPath}Framer/EditWithToken/`;
  data: any;

  constructor(
    private appState: AppState,
    private dialogRef: DynamicDialogRef,
    private orderService: OrderService,
    private dialogConfig: DynamicDialogConfig,
    private fileSaverService: FileSaverService,
    private smartbuildReportFileDownloadService: SmartBuildReportFileDownloadService,
    private windowRef: WindowRefService,
    private smartbuildService: SmartBuildService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.data = this.dialogConfig.data;
    this.items = [{ label: "Download", icon: "pi pi-fw pi-download" }];
    this.files =
      this.data.status !== "Pending"
        ? [
            {
              label: "SmartBuild Output Report",
              icon: "pi pi-fw pi-file-pdf",
            },
          ]
        : (this.files = []);
  }

  close() {
    this.dialogRef.close();
  }

  cancelJob() {}

  public downloadSmartBuildReportFile(): void {
    this.smartbuildReportFileDownloadService
      .downloadSmartBuildReportFile(this.data.jobId)
      .pipe(
        catchError((e: HttpErrorResponse) => {
          var downloadErrorMessage = this.setSmartBuildOutputReportErrorMessage(e.status);
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: downloadErrorMessage,
          });
          return EMPTY;
        }),
        tap(() =>
          this.messageService.add({
            severity: "success",
            summary: "success",
            detail: "Report has been downloaded",
          })
        )
      )
      .subscribe();
  }

  downloadImage(imageTypeId: number, jobId: any): void {
    this.orderService.downloadFile(jobId, imageTypeId).subscribe({
      next: this.handleDownloadImageFile.bind(this, jobId, imageTypeId),
      error: this.handleImageFileDownloadError.bind(this),
    });
  }


  openJobInSB(): void {
    this.openSB(this.data);
  }

  public openSB(params: any): void {
    this.smartbuildService.getSmartBuildToken().subscribe((token) => {
      this.windowRef.nativeWindow.open(`${this.smartBuildJobEditUrlBase}${params.jobId}?token=${token}`);
    });
  }

  private setSmartBuildOutputReportErrorMessage(status: number) {
    var statusMessage: string;
    switch (status) {
      case 400:
        statusMessage = AppConstants.SmartBuildOutputReportErrors.NoDefaultOutputMessage;
        break;
      case 403:
        statusMessage = AppConstants.SmartBuildOutputReportErrors.UnauthorizedMessage;
        break;
      case 500:
        statusMessage = AppConstants.SmartBuildOutputReportErrors.ServerError;
        break;
      default:
        statusMessage = AppConstants.RoofingPassportCommunicationError;
    }
    return statusMessage;
  }

  private handleDownloadImageFile(jobId: number, imageTypeId: number, response: any): void {
    const blob: any = new Blob([response], {
      type: `${response.type}; charset=utf-8`,
    });
    const fileTypeName = EagelViewFileTypeIds[imageTypeId];
    const url = window.URL.createObjectURL(blob);
    this.fileSaverService.saveFile(blob, `job-${jobId}-${fileTypeName}.jpeg`);
  }

  private handleImageFileDownloadError(error: any) {
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: "Photo unavailable, try again later.",
    });
  }
}
