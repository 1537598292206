<div class="container login" [style.background-image]="imageUrl">
  <span role="img" aria-label="Wizard background"></span>
  <div class="wizard">
    <div class="wizard-container">
      <div *ngIf="showSteps" class="steps mb-5">
        <div *ngIf="!licenseAgreementAccepted" [class]="licenseAgreementAccepted ? 'step checked' : 'step active'">
          <div [class]="licenseAgreementAccepted ? 'check' : 'dot'"></div>
          <p>ToS</p>
        </div>
        <ng-template ngFor let-auth [ngForOf]="profile.authorizations" let-i="index">
          <hr *ngIf="!licenseAgreementAccepted || i > 0" />
          <div [class]="isAuthActive(auth) ? 'step active' : 'step'">
            <div [class]="isAuthComplete(auth) ? 'check' : 'dot'"></div>
            <p class="auth-name" [innerText]="getAuthName(auth)"></p>
          </div>
        </ng-template>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
