import { Component, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { AdminTask } from "src/app/core/data/models/AdminTask";
import { TaskService } from "src/app/shared/services/task.service";

@Component({
  selector: "app-admin-task-details",
  templateUrl: "./admin-task-details.component.html",
  styleUrls: ["./admin-task-details.component.scss"],
  providers: [MessageService],
})
export class AdminTaskDetailsComponent implements OnInit {
  taskDetails: AdminTask;
  isCompletingTask: boolean = false;

  constructor(
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private taskService: TaskService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.taskDetails = this.dialogConfig.data;
  }

  close(taskCompleted: boolean) {
    this.dialogRef.close(taskCompleted);
  }

  private showErrorToast(message: string): void {
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: message,
    });
  }

  completeTask(task: AdminTask): void {
    this.isCompletingTask = true;

    this.taskService.completeTask(task).subscribe({
      next: () => {
        this.isCompletingTask = false;
        this.close(true);
      },
      error: (err) => {
        this.showErrorToast(err.message);
        this.isCompletingTask = false;
      },
    });
  }
}
