<div *ngIf="this.profile != null">
  <h5 role="heading" class="mb-1">Updated Company Terms Of Service</h5>
  <p>This company has updated their Terms of Service. To continue under this profile you must accept the new terms.</p>
  <br />
  <a href="javascript:window.open('about:blank');" target="_blank"
    >License Agreement for {{ this.profile.manufacturer.name }}</a
  >
  <p-divider></p-divider>
  <div>
    <small *ngIf="errorMessage" id="username-help" class="p-error">{{ errorMessage }}</small>
    <div *ngIf="!errorMessage" class="validation-message">
      Select the box below to accept the terms of service then click the Next button.
    </div>
    <div class="steps-bottom">
      <div class="agreement">
        <div class="checkbox-container">
          <label>
            I agree to these Terms of Service
            <input type="checkbox" [(ngModel)]="licenseAccepted" (click)="errorMessage = null" />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div class="button-container">
        <button class="btn btn-primary" [disabled]="!licenseAccepted" (click)="accept()">Next</button>
      </div>
    </div>
  </div>
</div>
