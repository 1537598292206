import { NgModule } from "@angular/core";
import { ProjectListComponent } from "./project-list/project-list.component";
import { SharedModule } from "src/app/shared/shared.module";
import { CoreModule } from "src/app/core/core.module";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PrimengLibraryModule } from "src/app/shared/libraries/primeng-library.module";
import { DialogService } from "primeng/dynamicdialog";
import { NewJobDialogComponent } from "./new-job-dialog/new-job-dialog.component";
import { InitiateOrderModule } from "./initiate/initiate-orders.module";
import { DetailsModule } from "./details/details.module";
import { OrderService } from "src/app/shared/services/order.service";
import { FlexLayoutModule } from "@angular/flex-layout";

@NgModule({
  declarations: [ProjectListComponent, NewJobDialogComponent],
  imports: [
    SharedModule,
    CoreModule,
    CommonModule,
    ReactiveFormsModule,
    PrimengLibraryModule,
    FormsModule,
    InitiateOrderModule,
    DetailsModule,
    FlexLayoutModule,
  ],
  exports: [ProjectListComponent],
  providers: [DialogService, OrderService],
})
export class OrdersModule {}
