import { Injectable } from "@angular/core";
import * as fileSaver from "file-saver";

@Injectable({
  providedIn: "root",
})
export class FileSaverService {
  constructor() {}

  saveFile(data: string | Blob, filename: string, options?: fileSaver.FileSaverOptions): void {
    fileSaver.saveAs(data, filename, options);
  }
}
