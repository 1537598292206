<footer>
  <div class="e-spot">
    <a href="{{ configs.ElevateYourProducts }}" target="_blank"
      ><img alt="marketing espot" src="{{ marketingBannerUrl }}"
    /></a>
  </div>
  <div class="container footer-top">
    <div class="footer-container">
      <div class="share">
        <span>CONNECT WITH US</span>
        <a href="https://www.linkedin.com/showcase/sherwin-williams-coil-coatings/" target="blank" title="LinkedIn">
          <svg className="icon-svg linkedin">
            <use xlink:href="/assets/img/icons.svg#linkedin" />
          </svg>
        </a>
        <a href="https://www.youtube.com/user/Valsparbldg" target="blank" title="YouTube">
          <svg className="icon-svg youtube">
            <use xlink:href="/assets/img/icons.svg#youtube" />
          </svg>
        </a>
        <a href="https://www.instagram.com/swcoilcoatings/" target="blank" title="Instagram">
          <svg className="icon-svg instagram">
            <use xlink:href="/assets/img/icons.svg#instagram" />
          </svg>
        </a>
        <a href="https://twitter.com/SWCoilCoatings" target="blank" title="Twitter">
          <svg className="icon-svg twitter">
            <use xlink:href="/assets/img/icons.svg#twitter" />
          </svg>
        </a>
        <a href="https://www.pinterest.com/SWcoilcoatings/" target="blank" title="Pinterest">
          <svg className="icon-svg pinterest">
            <use xlink:href="/assets/img/icons.svg#pinterest" />
          </svg>
        </a>
      </div>
      <div class="elevate">
        <span>ELEVATE YOUR PRODUCTS</span>
        <p-button
          type="button"
          (click)="elevateYourProducts()"
          label="Learn More"
          styleClass="p-button-raised p-button-primary"
        ></p-button>
      </div>
    </div>
  </div>
  <div class="container footer-bottom">
    <div class="footer-container">
      <div class="row">
        <div class="col">
          <div class="copyright">
            <a [href]="copyrightUrl.url" target="blank">{{ copyrightUrl.urlText }}</a>
            <p>
              ©<span class="date">{{ currentYear }}</span>
              The Sherwin-Williams Company.
            </p>
            <p>
              Computer screens and printers vary in how colors are displayed, so the colors you see may not match the
              coating's actual color.
            </p>
          </div>
        </div>
        <div class="col">
          <ul class="contact">
            <li *ngFor="let item of contactData">
              <a href="{{ item.url }}" target="blank">{{ item.urlText }}</a>
            </li>
          </ul>
        </div>
        <div class="col">
          <ul class="terms marketinglinks">
            <li *ngFor="let item of marketingData">
              <a class="" href="{{ item.url }}" target="blank">{{ item.urlText }}</a>
            </li>
          </ul>
        </div>
        <div class="col">
          <ul class="terms termslinks">
            <li *ngFor="let item of policyData">
              <a class="" href="{{ item.url }}" target="blank">{{ item.urlText }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
