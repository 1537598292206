import { NgModule } from "@angular/core";
import { FaqDialogComponent } from "./faq-dialog/faq-dialog.component";
import { NotificationComponent } from "./notification/notification.component";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { CoreModule } from "../core/core.module";
import { CommonModule } from "@angular/common";
import { DetailsModule } from "./orders/details/details.module";
import { OrdersModule } from "./orders/orders.module";
import { ProfileModule } from "./profile/profile.module";
import { NotificationService } from "../shared/services/notification.service";
import { AdminModule } from "./admin/admin.module";
import { FeaturesComponent } from "./features.component";
import { SharedModule } from "../shared/shared.module";
import { FeaturesRoutingModule } from "./features-routing.module";
import { CompanyAffiliationComponent } from './company-affiliation/company-affiliation.component';

@NgModule({
  declarations: [FeaturesComponent, FaqDialogComponent, NotificationComponent, ConfirmDialogComponent, CompanyAffiliationComponent,
  ],
  imports: [
    CoreModule,
    CommonModule,
    SharedModule,
    DetailsModule,
    OrdersModule,
    ProfileModule,
    AdminModule,
    FeaturesRoutingModule,
  ],
  exports: [FaqDialogComponent, NotificationComponent, ConfirmDialogComponent],
  providers: [NotificationService],
})
export class FeaturesModule {}
