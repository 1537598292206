<p-panel class="admin-reporting">
  <ng-template pTemplate="header">
    <h5>KPI Reporting</h5>
  </ng-template>
  <app-order-statistics></app-order-statistics>
  <ng-template pTemplate="content">
    <p-table
      #ordersTable
      styleClass="p-datatable p-datatable-striped"
      [columns]="ordersTableColumns"
      [value]="orders"
      [rows]="10"
      [paginator]="true"
      [sortableColumns]="true"
      [(selection)]="selectedUser"
      [rowHover]="true"
      [loading]="isLoadingOrders"
    >
      <ng-template pTemplate="caption">
        <div class="flex w-full justify-content-between align-items-center">
          <div class="w-25rem">
            <label class="form-label" for="fromDateFilter">From Date</label>
            <p-calendar
              id="fromDateFilter"
              [(ngModel)]="fromDateFilter"
              (ngModelChange)="onDateChange()"
              [readonlyInput]="true"
              inputId="fromDateFilter"
              dateFormat="mm-dd-yy"
              styleClass="block"
              inputStyleClass="w-10rem"
            ></p-calendar>
          </div>
          <div>
            <button
              type="button"
              pButton
              pRipple
              icon="pi pi-file"
              (click)="exportOrdersCSV()"
              pTooltip="Download as CSV"
              tooltipPosition="bottom"
              class="mr-2"
            ></button>
            <button
              *ngIf="areFiltersApplied()"
              type="button"
              pButton
              pRipple
              icon="pi pi-filter-slash"
              (click)="clearFilters()"
              pTooltip="Clear all filters"
              tooltipPosition="bottom"
            ></button>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of ordersTableColumns" pSortableColumn="{{ col.field }}" id="{{ col.field }}" scope="col">
            {{ col.header }} <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </tr>
        <tr>
          <th *ngFor="let col of ordersTableColumns" id="{{ col.field }}" scope="col">
            <input
              *ngIf="col.field !== 'createdOn'"
              pInputText
              type="text"
              (input)="ordersTable.filter($event.target.value, col.field, 'contains')"
              [value]="ordersTable.filters[col.field]?.value"
              [(ngModel)]="filters[col.field]"
              [placeholder]="col.header"
              class="w-full"
              class="w-full"
            />
            <p-calendar
              *ngIf="col.field === 'createdOn'"
              [(ngModel)]="filters.createdOn"
              (ngModelChange)="ordersTable.filter(this.filters.createdOn, 'createdOn', 'dateEquals')"
              [placeHolder]="col.header"
              [readonlyInput]="true"
              dateFormat="mm-dd-yy"
              styleClass="block"
              inputStyleClass="w-full"
              [placeholder]="col.header"
              panelStyleClass="w-25rem"
            ></p-calendar>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-order>
        <tr>
          <td headers="jobId">{{ order.jobNumber }}</td>
          <td headers="jobName">{{ order.jobName }}</td>
          <td headers="createdDate">{{ order.createdOn | date: "MM/dd/yyyy" : "+0000" }}</td>
          <td headers="createdBy">{{ order.createdBy }}</td>
          <td headers="jobStatus">{{ order.jobStatus }}</td>
        </tr>
      </ng-template>
    </p-table>
  </ng-template>
</p-panel>
