import { Component, OnInit } from "@angular/core";
import { AppState } from "src/app/shared/services/app-state";
import { SmartBuildService } from "src/app/shared/services/smartbuild.service";

@Component({
  selector: "app-banner",
  templateUrl: "./banner.component.html",
})
export class BannerComponent implements OnInit {
  staticImagesBasePath = this.appState.configs.StaticImagesBasePath.endsWith("/")
    ? this.appState.configs.StaticImagesBasePath
    : this.appState.configs.StaticImagesBasePath.padEnd(this.appState.configs.StaticImagesBasePath.length + 1, "/");
  siteHeaderUrl = `${this.staticImagesBasePath}roofing-passport-site-header.jpg?${this.appState.imageRefreshToken}`;
  jobSummaryCounts: { active: string; archived: string; pending: string };

  constructor(
    private appState: AppState,
    private smartbuildService: SmartBuildService
  ) {}

  ngOnInit(): void {
    this.getJobList();
  }

  private getJobList() {
    this.smartbuildService.getJobSummary().subscribe(
      (jobSummary) => {
        this.jobSummaryCounts = {
          active: jobSummary.active.toString(),
          archived: jobSummary.archived.toString(),
          pending: jobSummary.pending.toString(),
        };
      },
      () => {
        this.jobSummaryCounts = {
          active: "--",
          archived: "--",
          pending: "--",
        };
      }
    );
  }
}
