import { NgModule } from "@angular/core";
import { AdminComponent } from "./admin.component";
import { AdminInvitationComponent } from "./admin-invitation/admin-invitation.component";
import { AdminSettingsComponent } from "./admin-settings/admin-settings.component";
import { AdminUsersComponent } from "./admin-users/admin-users.component";
import { CommonModule } from "@angular/common";
import { SharedModule } from "src/app/shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AdminRoutingModule } from "./admin-routing.module";
import { AdminReportingComponent } from "./admin-reporting/admin-reporting.component";
import { NgxsModule } from "@ngxs/store";
import { CoreModule } from "src/app/core/core.module";
import { AdminService } from "src/app/shared/services/admin.service";
import { MessageModule } from "primeng/message";
import { CalendarModule } from "primeng/calendar";
import { OrderStatisticsComponent } from "./admin-reporting/order-statistics/order-statistics.component";
import { WorkQueueComponent } from './work-queue/work-queue.component';
import { AdminTasksReviewComponent } from "./admin-tasks/admin-tasks-review.component";
import { AdminTaskDetailsComponent } from "./admin-tasks/admin-task-details.component";
import { AdminTaskUserRegistrationDetailsComponent } from "./admin-tasks/admin-task-user-registration-details.component";


@NgModule({
  declarations: [
    AdminComponent,
    AdminInvitationComponent,
    AdminSettingsComponent,
    AdminUsersComponent,
    AdminReportingComponent,
    AdminTasksReviewComponent,
    AdminTaskDetailsComponent,
    AdminTaskUserRegistrationDetailsComponent,
    OrderStatisticsComponent,
    WorkQueueComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    ReactiveFormsModule,
    FormsModule,
    AdminRoutingModule,
    MessageModule,
    CalendarModule,
    NgxsModule.forFeature([]),
  ],
  exports: [
    AdminComponent,
    AdminInvitationComponent,
    AdminSettingsComponent,
    AdminUsersComponent,
    AdminReportingComponent,
    AdminTasksReviewComponent,
    AdminTaskDetailsComponent,
    AdminTaskUserRegistrationDetailsComponent,
    OrderStatisticsComponent,
  ],
  providers: [AdminService],
})
export class AdminModule { }
