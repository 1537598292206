import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ReplaySubject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppConstants } from "src/app/shared/constants/app-constants";
import {
  ManufacturerProfile,
  ThirdPartyAuth,
} from "src/app/core/data/models/AppInitializationData";
import { AppState } from "src/app/shared/services/app-state";
import { AuthContext } from "src/app/shared/services/authentication.service";

@Component({
  selector: "app-profile-status",
  templateUrl: "./profile-status.component.html",
  styleUrls: ["./profile-status.component.scss"],
})
export class ProfileStatusComponent implements OnInit, OnDestroy {
  private destroyed$ = new ReplaySubject(1);
  profile: ManufacturerProfile;
  authorizations: ThirdPartyAuth[];
  busy: Subscription;
  errorMessage: string;
  allComplete: boolean;
  roofingWRXEnabled: boolean;

  constructor(private appState: AppState, private router: Router) {}

  ngOnInit(): void {
    this.appState.profile$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((profile) => {
        this.updateStatus(profile);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  updateStatus(profile: ManufacturerProfile) {
    this.profile = profile;
    this.roofingWRXEnabled = this.appState.roofingWRXEnabled;
    this.authorizations = this.profile.authorizations?.filter(
      (a) => this.roofingWRXEnabled || a.context != AuthContext.RoofingWRX
    );
    this.allComplete =
      this.profile.authorizations?.find((a) => !a.registrationCompleted) ==
      null;
  }

  getAuthName(auth: ThirdPartyAuth): string {
    return AppConstants.ThirdPartyServiceName[auth.context] || "";
  }

  continue() {
    // manually set our registration status 'skip' flag so we don't see it again immediately
    this.appState.skipRegistrationStatus = true;

    // navigate to root profile route
    this.router.navigate(["profile"]);
  }
}
