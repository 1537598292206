<p-toast [style]="{ marginBottom: '10px' }" position="bottom-right"></p-toast>
<p-panel>
  <ng-template pTemplate="header">
    <div class="flex align-items-center gap-2">
      <h5 class="mt-3" style="padding-left: 1rem">Complete {{ contextName }} Registration</h5>
      <br />
    </div>
  </ng-template>
  <ng-template pTemplate="body">
    <div fxLayout="row" *ngIf="taskDetails">
      <!-- New Invited User -->
      <div fxFlex="1 1 50%" class="mb-2">
        <h5 class="header">New User ({{ taskDetails.inviteeCompanyName }})</h5>
        <div class="task-title mb-2">
          Full Name:
          <span class="task-value"> {{ taskDetails.inviteeFirstName }} {{ taskDetails.inviteeLastName }}</span>
        </div>
        <div class="task-title mb-2">
          Email:
          <span class="task-value"> {{ taskDetails.inviteeEmail }}</span>
        </div>
        <div class="task-title mb-2">
          Phone Number:
          <span class="task-value"> {{ taskDetails.inviteePhoneNumber }}</span>
        </div>
      </div>
      <br />
      <!-- Inviter Information -->
      <div fxFlex="1 1 50%" class="mb-2">
        <h5 class="header">Invited By ({{ taskDetails.inviterCompanyName }})</h5>
        <div class="task-title mb-2">
          Full Name:
          <span class="task-value"> {{ taskDetails.inviterFirstName }} {{ taskDetails.inviterLastName }}</span>
        </div>
        <div class="task-title mb-2">
          Email:
          <span class="task-value"> {{ taskDetails.inviterEmail }}</span>
        </div>
        <div class="task-title mb-2">
          Phone Number:
          <span class="task-value"> {{ taskDetails.inviterPhoneNumber }}</span>
        </div>
      </div>

      <p-divider></p-divider>

      <div fxLayout="column" [formGroup]="registrationForm">
        <div fxLayout="column">
          <div class="flex align-items-center">
            <p-checkbox binary="true" (onChange)="onChecked('existingAccount')"></p-checkbox>
            <label class="ml-2">Is Existing Account?</label>
          </div>

          <div class="flex align-items-center" *ngIf="taskDetails.partnerSystem === 'EagleView'">
            <p-checkbox binary="true" (onChange)="onChecked('evCardOnFile')"></p-checkbox>
            <label class="ml-2">Credit Card on file?</label>
          </div>

          <div class="flex align-items-center" *ngIf="taskDetails.partnerSystem === 'RoofingWRX'">
            <p-checkbox binary="true" (onChange)="onChecked('wrxCardOnFile')"></p-checkbox>
            <label class="ml-2">Credit Card on file?</label>
          </div>
          <br />
          <div fxFlex="1 1 100%" class="form-field">
            <div class="form-label">Username<sup>*</sup></div>
            <input
              type="text"
              style="width: 12rem"
              formControlName="username"
              placeholder="Username"
              autocomplete="off"
            />
            <div class="form-error">
              <span *ngIf="username.invalid && (username.touched || username.dirty)">A username is required</span>
            </div>
          </div>

          <div fxFlex="1 1 100%" *ngIf="!isExistingAccount" class="form-field">
            <div class="form-label">Temporary Password<sup>*</sup></div>
            <div></div>
            <input
              [type]="showPassword ? 'text' : 'password'"
              formControlName="password"
              placeholder="Temporary Password"
              autocomplete="off"
              style="width: 12rem"
            />
            <button
              *ngIf="showPassword"
              pButton
              pRipple
              icon="pi pi-eye"
              class="p-button-text p-button-primary"
              (click)="showPassword = false"
              style="margin-top: -25px; margin-bottom: -12px"
            ></button>
            <button
              *ngIf="!showPassword"
              pButton
              pRipple
              icon="pi pi-eye-slash"
              class="p-button-text p-button-primary"
              (click)="showPassword = true"
              style="margin-top: -25px; margin-bottom: -12px"
            ></button>
          </div>
          <div class="form-error">
            <span *ngIf="password.invalid && (password.touched || password.dirty)">A password is required</span>
          </div>
        </div>
      </div>
      <!-- 
      <ng-container *ngIf="isCompletingTask">
        <p-skeleton width="0rem" height="0rem"></p-skeleton>
        <p-skeleton width="0rem" height="0rem"></p-skeleton>
        <p-skeleton width="0rem" height="0rem"></p-skeleton>
      </ng-container> -->
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div style="padding-left: 10rem">
      <button
        pButton
        pRipple
        label="Close"
        icon="pi pi-times"
        class="p-button-text p-button-secondary"
        (click)="close(false)"
      ></button>
      <p-button
        label="Submit"
        class="p-button-secondary"
        type="submit"
        [disabled]="username.value == null || (!isExistingAccount && password.value == null)"
        [class.spinner]="busy && !busy.closed"
        (onClick)="submit()"
      ></p-button>
    </div>
  </ng-template>
</p-panel>
