import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { AuthContext, AuthenticationService } from "./authentication.service";
@Injectable({
  providedIn: "root",
})
export class IdleService {
  warningTimeInSeconds: number = 600; //10 minute timer after warning prompt.
  interval;

  constructor(
    private cookieService: CookieService,
    private authService: AuthenticationService
  ) {}

  startTimeoutWarningTimer() {
    this.interval = setInterval(() => {
      if (this.warningTimeInSeconds > 0) {
        this.warningTimeInSeconds--;
      } else {
        this.logout();
      }
    }, 1000);
  }

  stopTimeoutWarningTimer() {
    this.warningTimeInSeconds = 600; //reset 10 minute timer after warning prompt.
    clearInterval(this.interval);
  }

  public logout() {
    this.cookieService.deleteAll();
    this.authService.logout(AuthContext.Azure);
  }
}
