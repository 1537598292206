import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import jobTypesList from "src/app/core/data/job-types";
import { AppConstants } from "src/app/shared/constants/app-constants";
import { RoofingWRXBranch } from "src/app/core/data/models/AppInitializationData";
import { SpinnerService } from "src/app/core/spinner/spinner.service";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { AppState } from "src/app/shared/services/app-state";
import { OrderService } from "src/app/shared/services/order.service";
import { DialogService } from "src/app/shared/services/dialog.service";
import { IErrorDialogData } from "src/app/core/data/models/i-error-dialog-data";
import { CreateOrderRequest, Order, OrderSequence, PartnerSystem } from "src/app/core/data/models/CreateOrderRequest";

@Component({
  selector: "app-xml-initiate-order",
  templateUrl: "./xml-initiate-order.component.html",
  styleUrls: ["./xml-initiate-order.component.scss"],
})
export class XmlInitiateOrderComponent implements OnInit {
  @ViewChild("fileUpload") fileUpload: ElementRef;

  newJobForm: UntypedFormGroup = this.fb.group({
    name: ["", [Validators.required, Validators.maxLength(75)]],
    jobType: ["", [Validators.required]],
    branchId: [""],
    user_Id: [""],
  });

  jobTypes = jobTypesList;
  files: File[] = [];
  branches: RoofingWRXBranch[] = [];
  submitButtonDisabled = false;
  roofingWRXEnabled = false;
  errorMessage?: string;
  busy?: Subscription;
  profile$ = this.appState.profile$;
  showJobErrorDialog = false;
  dialogData: IErrorDialogData;

  constructor(
    private appState: AppState,
    private fb: UntypedFormBuilder,
    private dialogRef: DynamicDialogRef,
    private orderService: OrderService,
    private dialogService: DialogService,
    private spinner: SpinnerService
  ) {}

  get name() {
    return this.newJobForm.get("name");
  }

  get jobType() {
    return this.newJobForm.get("jobType");
  }

  get branchId() {
    return this.newJobForm.get("branchId");
  }

  private addFiles(files: FileList) {
    if (files && files.length > 0) {
      this.files = Array.from(files);
    }
  }

  ngOnInit(): void {
    this.appState.profile$.subscribe((profile) => {
      this.roofingWRXEnabled = this.appState.roofingWRXEnabled;
      this.branches = this.appState.roofingWRXBranches;
      if (this.branches.length > 1) {
        this.branchId.setValidators(Validators.required);
      }
    });
    if (!this.roofingWRXEnabled) {
      this.dialogData = {
        header: "XML Upload not enabled.",
        message:
          "XML Upload is currently not enabled for your account. Please, contact your administrator for more information.",
      };
      this.showJobErrorDialog = true;
    }

    this.busy = this.appState.profileOnce$.subscribe();
  }

  close() {
    this.dialogRef.close();
  }

  onFileSelected(event) {
    this.addFiles(event.files);
    this.fileUpload.nativeElement.value = null;
  }

  onBrowseClicked() {
    this.fileUpload.nativeElement.click();
  }

  onDropFiles(event) {
    event.preventDefault();
    this.addFiles(event.dataTransfer.files);
  }

  onDragOverFiles(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  onRemoveFile(index: number) {
    if (index >= 0 && index < this.files.length) {
      this.files.splice(index, 1);
    }
  }

  clearFiles() {
    this.files = [];
  }

  createJob() {
    if (this.branches.length == 0) {
      this.dialogService.roofingWRXBillingSetup();
      return;
    } else if (this.branches.length == 1) {
      this.branchId?.setValue(this.branches[0].id);
    }

    const order: Order = {
      branch_id: this.branchId.getRawValue(),
      comments: null,
      name: this.name.getRawValue(),
      isRevision: false,
    };

    const request: CreateOrderRequest = {
      jobName: this.name.getRawValue(),
      jobType: this.jobType.getRawValue(),
      orderSequence: OrderSequence.SmartBuildToRoofingWRXXML,
      roofData: {
        orderModel: JSON.stringify(order),
        orderModelPartnerSystem: PartnerSystem.RoofingWRX,
      },
    };

    if (this.newJobForm.valid) {
      this.submitButtonDisabled = true;
      this.errorMessage = null;
      this.orderService.processNewOrder(request, this.files).subscribe(
        () => {
          this.dialogRef.close();
        },
        (responseError) => {
          this.submitButtonDisabled = false;
          if (typeof responseError.error === "object") {
            if (responseError.error.error == "billing_error") {
              this.dialogService.roofingWRXBillingError(responseError.error.detail, this.branchId.value);
            } else {
              this.errorMessage = responseError.error.detail ?? AppConstants.XmlGenericError;
            }
          } else {
            this.errorMessage = responseError.error;
          }
        }
      );
    }
  }
}
