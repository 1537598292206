import { NgModule } from "@angular/core";
import { EagleViewJobDetailsComponent } from "./eagle-view-job-details/eagle-view-job-details.component";
import { RoofingWrxJobDetailsComponent } from "./roofing-wrx-job-details/roofing-wrx-job-details.component";
import { XMLJobDetailsComponent } from "./xml-job-details/xml-job-details.component";
import { SharedModule } from "src/app/shared/shared.module";
import { CoreModule } from "src/app/core/core.module";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { WindowRefService } from "src/app/shared/helpers/window-ref.service";
import { FileSaverService } from "src/app/shared/services/file-saver.service";
import { SmartBuildService } from "src/app/shared/services/smartbuild.service";
import { OrderService } from "src/app/shared/services/order.service";
import { SmartBuildReportFileDownloadService } from "src/app/shared/services/smartbuild-report-file-download.service";

@NgModule({
  declarations: [EagleViewJobDetailsComponent, RoofingWrxJobDetailsComponent, XMLJobDetailsComponent],
  imports: [SharedModule, CoreModule, CommonModule, FlexLayoutModule],
  exports: [EagleViewJobDetailsComponent, RoofingWrxJobDetailsComponent, XMLJobDetailsComponent],
  providers: [OrderService, FileSaverService, SmartBuildReportFileDownloadService, SmartBuildService, WindowRefService],
})
export class DetailsModule {}
