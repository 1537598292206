import { Manufacturer } from "src/app/core/data/models/AppInitializationData";

export namespace AppActions {
  export class ToggleIsLoading {
    static readonly type = "[App] ToggleIsLoading";
    constructor(
      public isLoading: boolean,
      public loadingMessage: string = "Loading..."
    ) {}
  }

  export class FetchManufacturers {
    static readonly type = "[App] Manufacturers - fetch user manufacturers";
    constructor() {}
  }

  export class SetManufacturers {
    static readonly type =
      "[App] Set user manufacturers to be fetched wherever needed.";
    constructor(public manufacturers: Manufacturer[]) {}
  }
  export class SetAppStateError {
    static readonly type = "[App] Set error.";
    constructor(
      public type: string,
      public code: string,
      public message: string
    ) {}
  }

  export class ClearAppStateError {
    static readonly type = "[App] Clear rror;";
  }
}
