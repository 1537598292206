import { Injectable } from "@angular/core";

import { Subject, Observable } from "rxjs";

import { AppState } from "./app-state";
import { WindowRefService } from "../helpers/window-ref.service";

@Injectable({
  providedIn: "root",
})
export class PictometryService {
  private ipa;
  any;
  private mapImageDataSubject: Subject<boolean> = new Subject<boolean>();
  private shapeDataSubject: Subject<{ lat: number; long: number }> = new Subject<{ lat: number; long: number }>();
  private addressSearchSubject: Subject<any> = new Subject<any>();
  private imageViewChangeCalled = true;

  constructor(
    private windowRefService: WindowRefService,
    private appState: AppState
  ) {
    this.ipa = windowRefService.nativeWindow.ipa;

    this.ipa.ready = () => {
      this.ipa.showDashboard({
        zoom: true,
        imageFilter: false,
        layers: false,
        nextPrevious: false,
        tools: false,
        annotations: false,
        rotation: false,
        clearMeasurements: false,
        exportPdf: false,
        dualPane: false,
        imageDate: false,
        panTool: false,
        exportImage: false,
        areaTool: false,
        distanceTool: false,
        heightTool: false,
        locationTool: false,
        elevationTool: false,
        bearingTool: false,
        slopeTool: false,
        xyzTool: false,
        identifyPoint: false,
        identifyBox: false,
      });

      //   this.ipa.setMapOrientation({
      //     angle: this.ipa.MAP_ANGLE.ORTHO,
      //     orientation: this.ipa.MAP_ORIENTATION.NORTH
      // });
      this.ipa.setImageFilterLatest();

      this.ipa.setLocation({
        y: this.appState.configs.DefaultMapLocation.lat,
        x: this.appState.configs.DefaultMapLocation.long,
        drawMarker: false,
        parcelSearch: false,
        zoom: 19,
      });
      //keep zoom level between 16-19

      this.ipa.addListener("onshapemodified", this.handleShapeModifiedEvent.bind(this));
      this.ipa.addListener("onchangeview", this.handleOnChangeViewEvent.bind(this));
    };
  }

  public setAddress(addressString: string): void {
    this.imageViewChangeCalled = false;
    this.ipa.removeAllShapes();
    this.ipa.gotoAddress(
      {
        searchString: addressString,
      },
      this.addresSearchCallback.bind(this)
    );
  }

  public onImageDataSet(): Observable<boolean> {
    return this.mapImageDataSubject.asObservable();
  }

  public onPinPositionSet(): Observable<{ lat: number; long: number }> {
    return this.shapeDataSubject.asObservable();
  }

  public onAddressSearchComplete(): Observable<any> {
    return this.addressSearchSubject.asObservable();
  }

  private addresSearchCallback(result): void {
    const marker = {
      type: this.ipa.SHAPE_TYPE.MARKER,
      center: { y: result.features[0].y, x: result.features[0].x },
      markerImageHeight: 43,
      markerImageWidth: 28,
      markerOffsetX: 0,
      markerOffsetY: -20,
      markerImage: window.location.origin + "/assets/img/map-pin.png",
      onShapeClick: true,
      draggable: true,
    };
    this.addressSearchSubject.next(result);
    this.ipa.addShapes([marker], (event) => {});
  }

  private handleShapeModifiedEvent(result): void {
    this.shapeDataSubject.next({
      lat: result.newLocation.y,
      long: result.newLocation.x,
    });
  }

  private handleImageFilterOptionsEvent(result) {
    this.mapImageDataSubject.next(result.imageCount > 0);
  }

  private handleOnChangeViewEvent(): void {
    if (!this.imageViewChangeCalled) {
      this.ipa.getImageFilterOptions(this.handleImageFilterOptionsEvent.bind(this));
      this.imageViewChangeCalled = true;
    }
  }
}
