import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from "@angular/core";
import { NgxsModule } from "@ngxs/store";
import { SpinnerComponent } from "./spinner/spinner.component";
import { AppStateComponent } from "./store/app.state";
import { environment } from "src/environments/environment";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { CommonModule } from "@angular/common";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { BannerComponent } from "./banner/banner.component";
import { PrimengLibraryModule } from "../shared/libraries/primeng-library.module";
import { ConfirmComponent } from "./confirm/confirm.component";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { AppInitializerService } from "../shared/services/app-initializer.service";
import { ConfirmService } from "./confirm/confirm.service";
import { AdminService } from "../shared/services/admin.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DialogComponent } from "./dialog/dialog.component";

@NgModule({
  declarations: [
    SpinnerComponent,
    FooterComponent,
    HeaderComponent,
    BannerComponent,
    ConfirmComponent,
    DialogComponent,
  ],
  imports: [
    ProgressSpinnerModule,
    PrimengLibraryModule,
    CommonModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot([AppStateComponent], {
      developmentMode: !environment.production,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
  ],
  exports: [SpinnerComponent, FooterComponent, HeaderComponent, BannerComponent, ConfirmComponent, DialogComponent],
  providers: [
    ConfirmService,
    AdminService,
    {
      provide: APP_INITIALIZER,
      deps: [AppInitializerService],
      multi: true,
      useFactory: () => () => {
        return new Promise<void>((resolve) => {
          resolve();
        });
      },
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error("CoreModule has already been loaded.  Should only be a highlander import into AppModule");
    }
  }
}
