import { AppConstants } from "src/app/shared/constants/app-constants";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ReplaySubject, Subscription } from "rxjs";
import { SpinnerService } from "src/app/core/spinner/spinner.service";
import { ManufacturerProfile, ProfilePayee, ProfileStatus } from "src/app/core/data/models/AppInitializationData";
import { AppState } from "src/app/shared/services/app-state";
import { ProfileService } from "src/app/shared/services/profile.service";

@Component({
  selector: "app-profile-accept",
  templateUrl: "./profile-accept.component.html",
})
export class ProfileAcceptComponent implements OnInit {
  profile: ManufacturerProfile;
  busy: Subscription;
  errorMessage: string;
  licenseAccepted: boolean = false;
  hasManufacturerPayee: boolean = false;
  hasManufacturerTOS: boolean = false;
  private destroyed$ = new ReplaySubject<boolean>(1);

  constructor(
    private appState: AppState,
    private router: Router,
    private profileService: ProfileService,
    private spinner: SpinnerService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.profile = this.appState.currentProfile;
    this.licenseAccepted = false;
    this.continue();
  }

  accept() {
    this.errorMessage = null;
    this.profileService.acceptInvitation(this.profile.manufacturer.id.toString()).subscribe(({ success, error }) => {
      if (success) {
        this.profile.status = ProfileStatus.Accepted;
        this.profile.licenseAgreement = {
          accepted: true,
          lastAcceptedDateTime: new Date(),
        };
        this.router.navigate(["profile", "link", AppConstants.ThirdPartyServiceName.SmartBuild]);
      } else {
        this.errorMessage = error;
        this.licenseAccepted = false;
      }
    });
  }

  reject() {
    if (
      window.confirm("Are you sure you want to reject this invitation from " + this.profile.manufacturer.name + "?")
    ) {
      this.errorMessage = null;
      this.busy = this.profileService
        .rejectInvitation(this.profile.manufacturer.id.toString())
        .subscribe(({ success, error }) => {
          if (success) {
            this.profile.status = ProfileStatus.Rejected;
            this.continue();
          } else {
            this.errorMessage = error;
            this.licenseAccepted = false;
          }
        });
    }
  }

  continue() {
    if (!this.profile) {
      this.router.navigate(["profile"]);
    } else {
      this.hasManufacturerPayee = this.profile.payee == ProfilePayee.Manufacturer;
      this.hasManufacturerTOS = this.profile.manufacturer.termsUrl && this.profile.manufacturer.termsUrl.length > 0;
    }
  }
}
