<div>
  <h4 class="mb-2" style="text-align: center">Choose a Company Profile</h4>
  <p-divider></p-divider>
  <div class="grid">
    <div class="col-12">
      <div class="flex align-items-center justify-content-center">
        <p-listbox
          [options]="profiles"
          class="listbox1 w-full px-2 overflow-hidden"
          optionLabel="manufacturer.name"
          [listStyle]="{ 'max-height': '100%' }"
          (onChange)="selectProfile($event.value)"
        ></p-listbox>
      </div>
    </div>
  </div>
</div>
