import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { AppState } from "./app-state";
import { AuthenticationService } from "./authentication.service";
import { SmartBuildProject } from "src/app/core/data/models/SmartBuildProject";
import { ApiEndpoints } from "../constants/api-endpoints";
import { JobSummary } from "src/app/core/data/models/JobSummary";

@Injectable({
  providedIn: "root",
})
export class SmartBuildService {
  constructor(
    private http: HttpClient,
    private appState: AppState,
    private authService: AuthenticationService
  ) {}

  getProjects(archived: string): Observable<SmartBuildProject[]> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.getSmartBuildProjects}`;
    let params = new HttpParams();
    params = params.append("archived", archived);
    return this.http.get<SmartBuildProject[]>(url, { headers, params });
  }

  getJobSummary(): Observable<JobSummary> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.getJobSummary}`;
    return this.http.get<JobSummary>(url, { headers });
  }

  getSmartBuildToken(): Observable<string> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.getSmartBuildToken}`;
    return this.http.get<string>(url, { headers });
  }
}
