<div class="hero-container homepage">
  <div class="img-container">
    <img src="{{ siteHeaderUrl }}" alt="Roofing Passport" />
  </div>
  <div class="hero-content">
    <h3>Job Status Overview</h3>
    <div class="row">
      <div class="col col-4-md">
        <div class="number blue">
          {{ jobSummaryCounts?.active }}
        </div>
        <p>Active Jobs</p>
      </div>
      <div class="col col-4-md">
        <div class="number grey">
          {{ jobSummaryCounts?.archived }}
        </div>
        <p>Archived Jobs</p>
      </div>
      <div class="col col-4-md">
        <div class="number gold">
          {{ jobSummaryCounts?.pending }}
        </div>
        <p>Pending Dimensions</p>
      </div>
    </div>
  </div>
</div>
