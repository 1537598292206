import { Injectable, NgZone } from "@angular/core";
import { LogLevel, OpenIdConfiguration } from "angular-auth-oidc-client";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { AppState } from "./app-state";
import { AuthContext, AuthenticationService } from "./authentication.service";
import { IConfigs } from "src/app/core/data/models/iconfigs";
import { AppInitializationData } from "src/app/core/store/app.models";
import { environment } from "src/environments/environment";
import { ApiEndpoints } from "../constants/api-endpoints";
import { runInZone } from "../pipes/run-in-zone.pipe";

@Injectable({
  providedIn: "root",
})
export class AppInitializerService {
  constructor(
    private httpClient: HttpClient,
    private appState: AppState,
    private authService: AuthenticationService,
    private ngZone: NgZone
  ) {}

  public loadConfigs(): Promise<any> {
    var timestamp = Date.now();
    return this.httpClient
      .get("assets/configs.json?t=" + timestamp)
      .pipe(map((settings: { configs: IConfigs }) => this.appState.initializeConfigs(settings.configs)))
      .toPromise();
  }

  public getAppInitialData(): Observable<AppInitializationData> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.getAppInitializationData}`;
    return this.httpClient.get<AppInitializationData>(url, { headers }).pipe(
      runInZone(this.ngZone),
      map((data) => {
        return this.appState.setAppData(data);
      }),
      catchError((err) => {
        // if we get a 401 error, assume that our Azure B2C token is expired and log out
        // we also want to clear our session storage so that .well-known endpoints are reloaded for the oauth plugin
        sessionStorage.clear();
        this.authService.logout();
        return of(null);
      })
    );
  }

  public getIpaMapUrlSignature(): Observable<string> {
    const overrideIpaKey = environment.ipaKey ? `?key=${environment.ipaKey}&secret=${environment.ipaSecret}` : "";
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.getIPASignature(overrideIpaKey)}`;
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
    });
    return this.httpClient.get<string>(url, { headers, responseType: "json" }).pipe(
      map((response) => response),
      catchError((err) => of(""))
    );
  }

  public loadAuthConfiguration(): Promise<OpenIdConfiguration>[] {
    const appInit = this.loadConfigs();
    return [
      appInit.then(() => ({
        configId: AuthContext.Azure,
        authority: this.appState.configs.AzureB2CAuth.Authority,
        clientId: this.appState.configs.AzureB2CAuth.ClientId,
        redirectUrl: this.appState.configs.ClientBaseUrl,
        postLogoutRedirectUri: this.appState.configs.ClientBaseUrl,
        scope: this.appState.configs.AzureB2CAuth.Scopes,
        responseType: "id_token token",
        autoUserInfo: false,
        triggerAuthorizationResultEvent: true,
        useRefreshToken: true,
        logLevel: LogLevel.Debug,
      })),
      appInit.then(() => ({
        configId: AuthContext.SmartBuild,
        authority: this.appState.configs.SmartBuildAuth.Authority,
        clientId: this.appState.configs.SmartBuildAuth.ClientId,
        redirectUrl: this.appState.configs.ClientBaseUrl,
        postLogoutRedirectUri: this.appState.configs.ClientBaseUrl,
        scope: this.appState.configs.SmartBuildAuth.Scopes,
        responseType: "code",
        useRefreshToken: false,
        logLevel: LogLevel.Error,
        autoUserInfo: false,
      })),
      appInit.then(() => ({
        configId: AuthContext.EagleView,
        authority: this.appState.configs.EagleViewAuth.Authority,
        clientId: this.appState.configs.EagleViewAuth.ClientId,
        redirectUrl: this.appState.configs.ClientBaseUrl,
        postLogoutRedirectUri: this.appState.configs.ClientBaseUrl,
        scope: this.appState.configs.EagleViewAuth.Scopes,
        responseType: "code",
        useRefreshToken: false,
        logLevel: LogLevel.Error,
      })),
      appInit.then(() => ({
        configId: AuthContext.RoofingWRX,
        authWellknownEndpointUrl: this.appState.configs.RoofingWRXAuth.Authority + "auth/config",
        authority: this.appState.configs.RoofingWRXAuth.Authority,
        clientId: this.appState.configs.RoofingWRXAuth.ClientId,
        redirectUrl: this.appState.configs.ClientBaseUrl,
        postLogoutRedirectUri: this.appState.configs.ClientBaseUrl,
        scope: this.appState.configs.RoofingWRXAuth.Scopes,
        responseType: "code",
        useRefreshToken: false,
        logLevel: LogLevel.Error,
      })),
    ];
  }
}
