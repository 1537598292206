let jobTypesList = [
  {
    name: "Residential Re-roof",
  },
  {
    name: "New Residence",
  },
  {
    name: "Commercial",
  },
];

export default jobTypesList;
