import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { SpinnerService } from "src/app/core/spinner/spinner.service";
import { AppState } from "src/app/shared/services/app-state";
import { UserInfoService } from "src/app/shared/services/user-info.service";

@Component({
  selector: "app-license-agreement",
  templateUrl: "./license-agreement.component.html",
})
export class LicenseAgreementComponent implements OnInit {
  licenseAccepted = false;
  thirdPartyLoginNeeded = true; // !this.appState.hasValidThirdPartyTokens;
  staticImagesBasePath = this.appState.configs.StaticImagesBasePath.endsWith("/")
    ? this.appState.configs.StaticImagesBasePath
    : this.appState.configs.StaticImagesBasePath.padEnd(this.appState.configs.StaticImagesBasePath.length + 1, "/");
  imageUrl = `url(${this.staticImagesBasePath}roofing-passport-site-login-background.jpg?${this.appState.imageRefreshToken})`;

  constructor(
    private appState: AppState,
    private userInfoService: UserInfoService,
    private router: Router,
    private spinner: SpinnerService
  ) {}

  ngOnInit(): void {}

  accept() {
    this.userInfoService
      .acceptRoofingPassportLicenseAgreement()
      .pipe(
        map((data) => data),
        catchError((err) => of(err))
      )
      .subscribe(this.handleLicenseUpdate.bind(this));
  }

  private handleLicenseUpdate() {
    if (this.appState.needsSetupFlow) {
      this.router.navigate(["profile"]);
    } else {
      this.router.navigate(["jobs"]);
    }
  }
}
