import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { forkJoin, Subscription } from "rxjs";
import { EagleViewInitiateOrderComponent } from "../initiate/eagle-view-initiate-order/eagle-view-initiate-order.component";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { AppState } from "src/app/shared/services/app-state";
import { AppInitializerService } from "src/app/shared/services/app-initializer.service";
import { ProfileService } from "src/app/shared/services/profile.service";
import { TabView } from "primeng/tabview";

@Component({
  selector: "app-new-job-dialog",
  templateUrl: "./new-job-dialog.component.html",
  styleUrls: ["./new-job-dialog.component.scss"],
})
export class NewJobDialogComponent implements OnInit {
  @ViewChild(EagleViewInitiateOrderComponent)
  evOrderComponent: EagleViewInitiateOrderComponent;
  @ViewChild("jobTabs")
  jobTabs: TabView;

  busy: Subscription;

  activeTabIndex: number = 0;

  constructor(
    private appState: AppState,
    private appInitService: AppInitializerService,
    private profileService: ProfileService,
    private dialogRef: DynamicDialogRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    // validate profile tokens and get url for map
    this.busy = forkJoin({
      profile: this.profileService.validateProfile(),
      ipaUrl: this.appInitService.getIpaMapUrlSignature(),
    }).subscribe(
      (data) => {
        if (this.appState.needsSetupFlow) {
          this.dialogRef.close();
          this.router.navigate(["profile"]);
        } else if (this.evOrderComponent) {
          this.evOrderComponent.setIpaUrl(data.ipaUrl + "&app_id=pictometry_ipa");
        }
      },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.router.navigate(["profile"]);
        }
      }
    );
  }
}
