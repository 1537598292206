import { Injectable } from "@angular/core";
import { AppState } from "./app-state";
import { IErrorDialogData } from "src/app/core/data/models/i-error-dialog-data";

@Injectable({
  providedIn: "root",
})
export class ErrorDialogService {
  constructor(private appState: AppState) {}

  roofingWRXBillingSetup(): IErrorDialogData {
    var branches = this.appState.roofingWRXBranches;
    var branchesNeedingSetup = branches.filter((b) => b.billing_status != 1);
    var canUpdatePayment = this.appState.isRoofingWRXAdmin && branchesNeedingSetup.length > 0;
    var dialogData: IErrorDialogData;

    // determine message based on number of accessible branches and how many need to be set up
    var message = `Billing information has not been set up for `;
    if (branches.length > 1) {
      if (branchesNeedingSetup.length == branches.length) {
        message += `any of your accessible Roofing WRX branches. You will not be able to create jobs through Roofing WRX or through XML upload until payment methods are updated.`;
      } else {
        message += `some of your accessible Roofing WRX branches. You will not be able to create jobs through Roofing WRX or through XML upload using these branches until payment is updated.`;
      }
    } else if (branches.length > 0) {
      message += `your Roofing WRX branch. You will not be able to create jobs through Roofing WRX or through XML upload until your payment method is updated.`;
    } else {
      message = ` You have not been assigned to any branches in Roofing WRX. You will not be able to create jobs through Roofing WRX or through XML upload until this is corrected on roofingwrx.com.`;
    }

    if (canUpdatePayment) {
      message += ` As a Roofing WRX admin, please update your billing profile in Roofing WRX using the button below.`;
    } else {
      message +=
        ` Please contact your ` +
        (this.appState.isBillingToManufacturer ? `associated manufacturer` : `company administrator`) +
        ` for assistance or more information.`;
    }

    dialogData = {
      header: "Roofing WRX Billing Setup",
      message: message,
    };

    return dialogData;
  }
}
