import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { ReplaySubject, Subscription } from "rxjs";

import { ICustomWindow, WindowRefService } from "../shared/helpers/window-ref.service";
import { AppInitializationData, ProfileStatus } from "../core/data/models/AppInitializationData";
import { UserSetting } from "../core/data/models/user-setting";
import { OAuthErrorEventParams } from "../core/data/models/OAuthErrorEventParams";
import { CookieService } from "ngx-cookie-service";
import { AppInitializerService } from "../shared/services/app-initializer.service";
import { AppState } from "../shared/services/app-state";
import { AuthenticationService, AuthContext } from "../shared/services/authentication.service";
import { IdleService } from "../shared/services/idle.service";
import { ProfileService } from "../shared/services/profile.service";
import { ConfirmService } from "../core/confirm/confirm.service";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  showRouterOutput = false;
  busy: Subscription;
  idleTimerLeft: string;
  totalTimeoutWarnings: number = 0;
  configs = this.appState.configs;
  userSettings: UserSetting[] = [];
  private window: ICustomWindow;
  private clientBaseUrl = encodeURIComponent(this.appState.configs.ClientBaseUrl);
  private $destroyed: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private isInitialized: boolean = false;

  get claims() {
    if (this.authService.isAuthenticated(AuthContext.Azure)) {
      return this.authService.claims(AuthContext.Azure);
    }
    return false;
  }

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private appState: AppState,
    private windowRefService: WindowRefService,
    private appInitializerService: AppInitializerService,
    private profileService: ProfileService,
    private confirmService: ConfirmService,
    private idle: Idle,
    private cd: ChangeDetectorRef,
    private idleService: IdleService,
    private cookieService: CookieService
  ) {
    idle.setIdle(900);
    idle.setTimeout(1500);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleStart.subscribe(() => {
      this.idleService.startTimeoutWarningTimer();
      this.confirmService.confirmEnhanced(
        "Session Timeout",
        "Your session is about to expire due to inactivity.",
        "pi pi-clock",
        () => {
          this.idleService.stopTimeoutWarningTimer();
          this.confirmService.close();
        },
        () => {
          this.logout();
        },
        "p-button-primary",
        "p-button-secondary",
        "Continue",
        "Log Out"
      );
    });

    idle.onIdleEnd.subscribe(() => {
      cd.detectChanges();
    });

    idle.onTimeout.subscribe(() => {
      this.logout();
    });

    this.window = this.windowRefService.nativeWindow;
  }

  ngOnInit() {
    this.configure();
    this.setStates();

    // listen for navigation events
    this.router.events.subscribe((routerEvent) => {
      if (routerEvent instanceof NavigationStart) {
        if (!this.isInitialized) {
          this.isInitialized = true;
          this.checkAuth();
        }
      }
    });
  }

  setStates() {
    this.idle.watch();
  }

  ngOnDestroy() {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

  public unlinkAll() {
    this.confirmService.confirm(
      "Warning",
      "Are you sure you want to unlink all accounts?",
      "pi pi-question-circle",
      () => {
        // attempt to clear all tokens for the current profile
        this.profileService.clearTokens().subscribe((success) => {
          if (!success) {
            alert("An error occured and we could not log you out");
          } else {
            this.authService.logoutLocal(AuthContext.SmartBuild);
            this.authService.logoutLocal(AuthContext.EagleView);
            this.authService.logoutLocal(AuthContext.RoofingWRX);
            this.router.navigate(["profile"]);
          }
        });
      },
      () => {
        this.confirmService.close();
      }
    );
  }

  public chooseProfile(id: number) {
    this.busy = this.profileService.chooseProfile(id).subscribe((profile) => {
      if (this.appState.needsSetupFlow) {
        this.router.navigate(["profile"]);
      }
    });
  }

  private configure() {
    const clientBaseUrl = encodeURIComponent(this.appState.configs.ClientBaseUrl);
    this.appState.configs.AccountUrl = this.appState.configs.AccountUrl.replace(
      "[B2cClientID]",
      this.appState.configs.B2cClientID
    ).replace("[ClientBaseUrl]", clientBaseUrl);
  }

  public login() {
    this.authService.login(AuthContext.Azure);
  }

  public logout() {
    this.cookieService.deleteAll();
    this.authService.logout(AuthContext.Azure);
  }

  // perform check of all auth configurations
  private checkAuth() {
    this.authService.checkAuthMutiple().subscribe(
      () => {
        if (this.authService.isAuthenticated(AuthContext.Azure)) {
          // try to get initialization data
          this.appInitializerService.getAppInitialData().subscribe(this.handleAppDataInit.bind(this));
        } else {
          // no authentication
          this.login();
        }
      },
      (params: OAuthErrorEventParams) => {
        if (params.error == "access_denied") {
          if (params.error_description.includes("AADB2C90118")) {
            // redirect to forgot password flow
            const redir = this.appState.configs.B2CPasswordResetUrl.replace(
              "[B2cClientID]",
              this.appState.configs.B2cClientID
            ).replace("[ClientBaseUrl]", this.clientBaseUrl);
            this.window.location.href = redir;
          } else if (params.error_description.includes("AADB2C90091")) {
            // user has cancelled out of password reset
            this.login();
          }
        }
      }
    );
  }

  private handleAppDataInit(data: AppInitializationData) {
    if (data == null) {
      console.log("null App initialization data");
    } else if (
      !data.profiles.find((x) => x.status === ProfileStatus.Accepted) &&
      !data.profiles.find((x) => x.status === ProfileStatus.Pending)
    ) {
      this.confirmService.confirmEnhanced(
        "Inactive User",
        "Your Roofing Passport record is not active.  Please contact your administrator.",
        "pi pi-user",
        () => {
          this.logout();
        },
        () => {},
        "p-button-primary",
        "",
        "Ok",
        "",
        "",
        "",
        ""
      );
    } else if (this.appState.needsCurrentProfile) {
      // if we need to select a profile navigate to setup without validating a current profile
      this.navigateToBase(data);
    } else {
      // validate the current selected profile before navigating
      this.profileService.validateProfile().subscribe(() => {
        this.navigateToBase(data);
      });
    }
  }

  private navigateToBase(data: AppInitializationData) {
    this.showRouterOutput = true;

    if (data.license?.lastAcceptedDateTime == null) {
      // navigate to RP license agreement page
      this.router.navigate(["license-agreement"]);
    } else if (this.appState.needsSetupFlow) {
      // navigate to profile setup to prompt user to choose a profile
      const navigatingUrl = this.router.getCurrentNavigation()?.initialUrl?.toString();
      if (!navigatingUrl || !navigatingUrl.startsWith("/profile")) {
        this.router.navigate(["profile"]);
      }
    } else {
      const currentUrl = this.router.url;
      this.router.navigate([currentUrl || "jobs"]);
    }
  }
}
