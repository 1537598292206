<p-tabView [(activeIndex)]="activeTabIndex">
  <p-tabPanel header="EagleView">
    <app-eagle-view-initiate-order (onSelectTab)="activeTabIndex = $event"></app-eagle-view-initiate-order>
  </p-tabPanel>
  <p-tabPanel header="Roofing WRX">
    <app-roofing-wrx-initiate-order></app-roofing-wrx-initiate-order>
  </p-tabPanel>
  <p-tabPanel header="Upload XML">
    <app-xml-initiate-order></app-xml-initiate-order>
  </p-tabPanel>
</p-tabView>
