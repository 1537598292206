import { Component, OnInit, Input } from "@angular/core";
import { AppNotification } from "src/app/core/data/models/AppNotification";
import { NotificationAligment } from "src/app/core/data/models/NotificationAligment";
import { NotificationService } from "src/app/shared/services/notification.service";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
})
export class NotificationComponent implements OnInit {
  @Input() notification: AppNotification = {
    height: "0px",
    width: "0px",
    align: NotificationAligment.bottomCenter,
    content: "",
    showOKButton: false,
    okButtonText: "OK",
    responseData: "",
    containerWidth: "0%",
    containerHeight: "0%",
  };
  showCloseButton = true;

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {}

  public hideNotification(): void {
    this.notificationService.hideNotification();
  }

  get containerPositionClass() {
    switch (this.notification.align) {
      case NotificationAligment.bottomCenter:
        return "bottomCenter";
      case NotificationAligment.bottomLeft:
        return "bottomLeft";
      case NotificationAligment.bottomRight:
        return "bottomRight";
      case NotificationAligment.middleCenter:
        return "middleCenter";
      case NotificationAligment.middleLeft:
        return "middleLeft";
      case NotificationAligment.middleRight:
        return "middleRight";
      case NotificationAligment.topCenter:
        return "topCenter";
      case NotificationAligment.topLeft:
        return "topLeft";
      case NotificationAligment.topRight:
        return "topRight";
      default:
        return "middleCenter";
    }
  }
}
