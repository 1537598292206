import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AppState } from "./app-state";
import { AuthenticationService } from "./authentication.service";
import { saveAs } from "file-saver";
import { Manufacturer } from "src/app/core/store/app.models";
import { ApiEndpoints } from "../constants/api-endpoints";
import { runInZone } from "../pipes/run-in-zone.pipe";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  constructor(
    private httpClient: HttpClient,
    private appState: AppState,
    private authService: AuthenticationService,
    private ngZone: NgZone
  ) {}

  public updateManufacturerSettings(
    manufacturer: Manufacturer
  ): Observable<{ manufacturer: Manufacturer; error: HttpErrorResponse }> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.updateManufacturerSettings}`;
    return this.httpClient.post<Manufacturer>(url, manufacturer, { headers }).pipe(
      runInZone(this.ngZone),
      map((response) => ({ manufacturer: response, error: null })),
      catchError((err: HttpErrorResponse) =>
        of({
          manufacturer: null,
          error: err,
        })
      )
    );
  }

  public downloadUserReport(manufacturerId: number): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.downloadUserReport(manufacturerId)}`;
    return this.httpClient.get(url, { headers, observe: "response", responseType: "blob" }).pipe(
      runInZone(this.ngZone),
      map((res) => {
        this.saveBlobResponse(res);
        return of(res.body);
      })
    );
  }

  downloadAdminUserGuide(): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.downloadAdminUserGuide}`;
    return this.httpClient.get(url, { headers, observe: "response", responseType: "blob" }).pipe(
      runInZone(this.ngZone),
      map((res) => {
        this.saveBlobResponse(res);
        return of(res.body);
      })
    );
  }

  public getUsersByManufacturerId(manufacturerId: number): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.getUserReportByManufacturerId(manufacturerId)}`;
    return this.httpClient.get(url, { headers, observe: "response", responseType: "json" }).pipe(
      runInZone(this.ngZone),
      map((res) => {
        return res.body;
      })
    );
  }

  private saveBlobResponse(response: HttpResponse<Blob>) {
    const disposition = response.headers.get("Content-Disposition");
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    const filename = matches?.length > 0 ? matches[1].replace(/['"]/g, "") : "user_export.csv";
    saveAs(response.body, filename);
  }
}
