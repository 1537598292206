import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { FeaturesComponent } from "./features.component";
import { ProjectListComponent } from "./orders/project-list/project-list.component";

const FeaturesRoutes: Routes = [
  {
    path: "jobs",
    component: FeaturesComponent,
    children: [
      {
        path: "",
        component: ProjectListComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(FeaturesRoutes)],
  exports: [RouterModule],
})
export class FeaturesRoutingModule {}
