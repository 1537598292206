import { Inject, Injectable, NgZone } from "@angular/core";

import { Observable } from "rxjs";

import { OidcSecurityService, LoginResponse } from "angular-auth-oidc-client";
import { DOCUMENT } from "@angular/common";
import { OAuthErrorEventParams } from "src/app/core/data/models/OAuthErrorEventParams";
import { runInZone } from "../pipes/run-in-zone.pipe";

// Configuration IDs within OIDC auth module
export enum AuthContext {
  None = "",
  Azure = "AzureB2C",
  SmartBuild = "SmartBuild",
  EagleView = "EagleView",
  RoofingWRX = "RoofingWRX",
}

export interface Credentials {
  password: string;
  userName: string;
}

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private ngZone: NgZone,
    @Inject(DOCUMENT) private readonly doc: any
  ) {}

  private getRouteParams(): any {
    var hash = this.doc.location.hash.substr(1);
    return hash.split("&").reduce((resultData: any, item: string) => {
      const parts = item.split("=");
      resultData[parts.shift() as string] = parts.join("=");
      return resultData;
    }, {});
  }

  // Check all authorization configurations
  public checkAuthMutiple(url: string = null): Observable<never> {
    // OAuth 'state' and route parameters are cleared after 'checkAuthMultiple' is called so we need to store them first

    return new Observable((observer) => {
      this.oidcSecurityService
        .checkAuthMultiple(url)
        .pipe(runInZone(this.ngZone))
        .subscribe((responses: LoginResponse[]) => {
          responses.forEach((resp) => {
            sessionStorage.removeItem(resp.configId + "_error");
            if (resp.errorMessage && resp.errorMessage != "no state in url") {
              // OIDC library doesn't retain the raw authentication result and error description
              // so we are putting it into the session storage
              //observer.error(resp);
              sessionStorage.setItem(resp.configId + "_error", resp.errorMessage);
            } else if (resp.isAuthenticated && resp.configId == AuthContext.Azure) {
              sessionStorage.setItem("access_token", resp.accessToken);
              sessionStorage.setItem("id_token", resp.idToken);
            }
          });
          observer.next();
        });
    });
  }

  public checkAuth(context: string, url: string = null): Observable<LoginResponse> {
    const routeParams = this.getRouteParams();

    return new Observable((observer) => {
      this.oidcSecurityService.checkAuth(url, context).subscribe((response: LoginResponse) => {
        if (response.errorMessage) {
          observer.error(routeParams as OAuthErrorEventParams);
        }
        if (response.configId == AuthContext.Azure) {
          sessionStorage.setItem("access_token", response.accessToken);
          sessionStorage.setItem("id_token", response.idToken);
        }
        observer.next(response);
      });
    });
  }

  // Get local authentication status for a given auth configuration
  public isAuthenticated(context: AuthContext = AuthContext.Azure): boolean {
    return this.oidcSecurityService.isAuthenticated(context);
  }

  // Fetch access token for given auth configuration
  public accessToken(context: AuthContext = AuthContext.Azure): string {
    return this.oidcSecurityService.getAccessToken(context);
  }

  // Fetch refresh token for given auth configuration
  public refreshToken(context: AuthContext = AuthContext.Azure): string {
    return this.oidcSecurityService.getRefreshToken(context);
  }

  // Get the current oauth 'state' for the given auth configuration
  public authResult(context: AuthContext = AuthContext.Azure): string {
    return this.oidcSecurityService.getAuthenticationResult(context);
  }

  // Get the current oauth 'state' for the given auth configuration
  public getAuthState(context: AuthContext = AuthContext.Azure): string {
    return this.oidcSecurityService.getState(context);
  }

  // Set the current oauth 'state' for the given auth configuration
  public setAuthState(state: string, context: AuthContext = AuthContext.Azure) {
    this.oidcSecurityService.setState(state, context);
  }

  // Pulls claims from either fetched user profile data or id token data
  public claims(context: AuthContext = AuthContext.Azure): any {
    return this.oidcSecurityService.getPayloadFromIdToken(false, context);
  }

  public getLastError(context: AuthContext = AuthContext.Azure, clear: boolean = true): string {
    var key = context + "_error";
    var error = sessionStorage.getItem(key);
    if (clear) {
      sessionStorage.removeItem(key);
    }
    return error;
  }

  // Perform authentication sequence for a given auth configuration
  public login(context: AuthContext = AuthContext.Azure, customParams: any = null) {
    if (customParams != null) {
      this.oidcSecurityService.authorize(context, {
        customParams: customParams,
      });
    } else {
      this.oidcSecurityService.authorize(context);
    }
  }

  public loginWithPopup(context: AuthContext = AuthContext.Azure): Observable<LoginResponse> {
    return this.oidcSecurityService.authorizeWithPopUp(null, null, context);
  }

  // Perform single logout sequence for a given config
  public logout(context: AuthContext = AuthContext.Azure) {
    this.oidcSecurityService.logoff(context);
  }

  // Perform single logout sequence for a given config
  public logoutLocal(context: AuthContext = AuthContext.Azure) {
    this.oidcSecurityService.logoffLocal(context);
  }
}
