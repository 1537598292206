import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { map, skipWhile, switchMap } from "rxjs/operators";
import { AppConstants } from "src/app/shared/constants/app-constants";
import { AuthStatus, ManufacturerProfile, ThirdPartyAuth } from "src/app/core/data/models/AppInitializationData";
import { AuthContext, AuthenticationService } from "src/app/shared/services/authentication.service";
import { AppState } from "src/app/shared/services/app-state";
import { ProfileService } from "src/app/shared/services/profile.service";
import { DialogService } from "src/app/shared/services/dialog.service";

@Component({
  selector: "app-profile-link",
  templateUrl: "./profile-link.component.html",
  styleUrls: ["./profile-link.component.css"],
})
export class ProfileLinkComponent implements OnInit, OnDestroy {
  credentials = this.fb.group({
    username: ["", [Validators.required]],
    password: ["", [Validators.required]],
  });

  auth$ = this.route.paramMap.pipe(
    switchMap((params) => {
      this.context = (params.get("context") as AuthContext) || AuthContext.None;
      return this.appState.profile$.pipe(
        skipWhile((p) => p == null),
        map((p) => {
          this.profile = p;
          return this.appState.getAuthData(this.context);
        })
      );
    })
  );

  context: AuthContext = AuthContext.None;
  isAuthenticated: boolean;
  isExpired: boolean = false;
  isLinked: boolean = false;
  isWorking: boolean = false;

  allowCredentials: boolean = false;
  useCredentials: boolean = false;

  claims = [];
  info?: string;
  name?: string;
  profile?: ManufacturerProfile;
  errorMessage?: string;
  busy?: Subscription;
  sub?: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private appState: AppState,
    private authService: AuthenticationService,
    private profileService: ProfileService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.sub = this.auth$.subscribe(this.init.bind(this));
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  get username() {
    return this.credentials.get("username");
  }

  get password() {
    return this.credentials.get("password");
  }

  updateClaims(auth: ThirdPartyAuth) {
    var tokenClaims = this.authService.claims(this.context);
    if (tokenClaims.email) {
      this.claims = [{ name: "email", label: "Email", value: tokenClaims.email }];
    } else {
      this.claims = [];
    }
  }

  init(auth: ThirdPartyAuth) {
    this.allowCredentials = this.appState.configs[auth.context + "Auth"]?.LegacyLogin == true;
    this.useCredentials = false;
    this.name = AppConstants.ThirdPartyServiceName[auth.context];
    this.info = AppConstants.ThirdPartyServiceInfo[auth.context];
    this.isAuthenticated = this.authService.isAuthenticated(auth.context);
    if (this.isAuthenticated) {
      this.updateClaims(auth);
    } else if (this.authService.getLastError(auth.context)) {
      if (auth.context === AuthContext.SmartBuild) {
        this.profileService.sendSmartBuildOutageMessage().subscribe();
        this.errorMessage =
          "We are unable to connect this user to Roofing Passport due to problems communicating with SmartBuild. Please contact " +
          this.name +
          " for assistance";
      } else {
        this.errorMessage =
          "We are unable to connect this user to Roofing Passport. Please contact " + this.name + " for assistance";
      }
    }
    this.isExpired = auth.status === AuthStatus.Expired;
    this.isLinked = auth.status === AuthStatus.Valid;
  }

  login(auth: ThirdPartyAuth) {
    this.errorMessage = null;
    this.isWorking = true;

    this.authService.login(auth.context);
  }

  legacyLogin(auth: ThirdPartyAuth) {
    this.isWorking = true;
    this.errorMessage = null;
    this.profileService.loginWithCredentials(this.credentials.value as Credential, auth.context).subscribe({
      next: () => {
        (auth.status = AuthStatus.Valid), (this.isLinked = true);
        this.isWorking = false;
      },
      error: (err: any) => {
        this.errorMessage = "Invalid username or password. Please try again.";
        this.isWorking = false;
      },
    });
  }

  logout(auth: ThirdPartyAuth) {
    this.errorMessage = null;
    this.authService.logout(this.context);
    this.init(auth);
  }

  link(auth: ThirdPartyAuth) {
    this.isWorking = true;
    this.errorMessage = null;
    this.profileService.storeUserToken(this.context).subscribe({
      next: () => {
        (auth.status = AuthStatus.Valid), (this.isLinked = true);
        this.isWorking = false;
      },
      error: (err: any) => {
        if (err.status == 401) {
          this.errorMessage =
            'This login token is no longer valid. Please click "Log out of ' + auth.context + '" and log in again.';
        } else if (err.status == 403) {
          this.errorMessage =
            "Cannot link this " +
            auth.context +
            " account under manufacturer <b>" +
            this.profile?.manufacturer.name +
            "</b>. Be sure to use the account credentials that were issued to you specifically for the Roofing Passport program.";
        } else {
          this.errorMessage = "An unexpected error has occured. Please try again later.";
        }
        this.isWorking = false;
      },
    });
  }

  continue(auth: ThirdPartyAuth) {
    var goNext = () => {
      // move to the next link step if needed
      this.router.navigate(["profile"]);
    };

    // clear the local cached auth information for this context
    this.authService.logoutLocal(auth.context);

    // for RoofingWRX we need to check the billing status of the current billing branch and display a setup warning if needed
    if (auth.context == AuthContext.RoofingWRX && this.appState.needsRoofingWRXBillingSetup) {
      this.dialogService.roofingWRXBillingSetup().subscribe(goNext);
    } else {
      goNext();
    }
  }
}
