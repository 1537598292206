import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { ApiEndpoints } from "../constants/api-endpoints";
import { AppState } from "./app-state";
import { AuthenticationService } from "./authentication.service";
import { AdminTask } from "src/app/core/data/models/AdminTask";

@Injectable({
  providedIn: "root",
})
export class TaskService {
  constructor(
    private http: HttpClient,
    private appState: AppState,
    private authService: AuthenticationService
  ) {}

  getTasks(): Observable<AdminTask[]> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });

    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.getTasks}`;

    return this.http.get<AdminTask[]>(url, { headers });
  }

  completeTask(task: AdminTask): Observable<boolean> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });

    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.completeTask}`;

    return this.http.post<boolean>(url, task, { headers });
  }
}
