import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { Observable, Subscription } from "rxjs";
import { filter, tap, withLatestFrom } from "rxjs/operators";
import { INavMenu } from "../core/data/models/i-nav-menu.model";

export abstract class BaseChildRedirectComponent {
  abstract menuItems$: Observable<INavMenu[]>;

  abstract defaultRouterLink: string[];

  private get definedChildRoutes() {
    return this.route.snapshot.routeConfig?.children;
  }

  get targetChildRoutes() {
    return this.definedChildRoutes?.find(
      (x) => x.path === location.pathname.split("/").pop()
    );
  }

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected store: Store
  ) {}

  routerSubscribe(): Subscription {
    return this.router.events
      .pipe(
        filter(
          (e: any) => e instanceof NavigationEnd && !this.targetChildRoutes
        ),
        tap((r) => {
          this.navigateToDefaultChildRoute(this.defaultRouterLink);
        })
      )
      .subscribe();
  }

  menuItemSubscribe(menuAction: any): Subscription {
    return this.store
      .dispatch(menuAction)
      .pipe(
        withLatestFrom(this.menuItems$),
        tap((m: any) => {
          if (m?.length >= 2 && m[1].length > 0) {
            const firstMenuItem = m[1][0];
            const routerLink = firstMenuItem?.items[0]?.routerLink;
            const lastSegment = location.pathname.split("/").pop();
            const lastSegmentIsInMenu = m[1][0].items.findIndex((i: any) =>
              i.routerLink.includes(lastSegment)
            );
            if (lastSegmentIsInMenu < 0 && routerLink) {
              this.defaultRouterLink = routerLink;
              this.navigateToDefaultChildRoute(routerLink);
            }
          }
        })
      )
      .subscribe();
  }

  navigateToDefaultChildRoute(routerLink: string[]) {
    this.router.navigate(routerLink, {
      relativeTo: this.route,
    });
  }
}
