<p-toast [style]="{ marginBottom: '10px' }" position="bottom-right"></p-toast>
<div fxLayout="row">
  <div fxFlex="1 1 100%" class="ipamap">
    <iframe id="pictometry_ipa" [src]="pictometryUrl | safe: 'resourceUrl'" height="100%" width="100%"></iframe>
  </div>

  <div fxFlex="0 0 475px" fxLayout="column" [formGroup]="newProjectForm">
    <h5 class="job-form-title mb-2">
      New Job From Address<br /><span class="manufacturer-name">{{ (profile$ | async)?.manufacturer.name }}</span>
    </h5>

    <div fxLayout="row" fxLayoutGap="10px">
      <div fxFlex="1 1 50%" class="form-field">
        <div class="form-label">Job Name<sup>*</sup></div>
        <input
          pInputText
          class="p-inputtext-sm"
          type="text"
          formControlName="projectName"
          placFeholder=""
          maxlength="100"
        />
        <div class="form-error">
          <span *ngIf="projectName.invalid && (projectName.touched || projectName.dirty)">A job name is required</span>
        </div>
      </div>
      <div fxFlex="1 1 50%" class="form-field">
        <div class="form-label">Promotion Code</div>
        <input
          pInputText
          class="p-inputtext-sm"
          type="text"
          formControlName="promoCode"
          placeholder=""
          maxlength="30"
        />
        <div class="form-error">
          <span *ngIf="promoCode.hasError('pattern') && (promoCode.touched || promoCode.dirty)"
            >The promotion code can only contain alphanumeric characters and hyphens</span
          >
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="10px">
      <div fxFlex="1 1 50%" class="form-field">
        <div class="form-label">Address<sup>*</sup></div>
        <input pInputText class="p-inputtext-sm" type="text" formControlName="address" placeholder="" maxlength="50" />
        <div class="form-error">
          <span *ngIf="address.invalid && (address.touched || address.dirty)">An address is required</span>
        </div>
      </div>
      <div fxFlex="1 1 50%" class="form-field">
        <div class="form-label">City<sup>*</sup></div>
        <input pInputText class="p-inputtext-sm" type="text" formControlName="city" placeholder="" maxlength="75" />
        <div class="form-error">
          <span *ngIf="city.invalid && (city.touched || city.dirty)">A city is required</span>
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="10px">
      <div fxFlex="1 1 50%" class="form-field">
        <div class="form-label">State / Province<sup>*</sup></div>
        <select formControlName="state">
          <option value="">Please select a state</option>
          <option *ngFor="let state of states" value="{{ state.abbreviation }}">
            {{ state.name }}
          </option>
        </select>
        <div class="form-error">
          <span *ngIf="usState.invalid && (usState.touched || usState.dirty)">A state is required</span>
        </div>
      </div>
      <div fxFlex="1 1 50%" class="form-field">
        <div class="form-label">Zip Code<sup>*</sup></div>
        <input
          pInputText
          class="p-inputtext-sm"
          type="text"
          class="width-auto"
          formControlName="zip"
          placeholder=""
          maxlength="7"
          size="8"
        />
        <div class="form-error">
          <span *ngIf="zip.hasError('required') && (zip.touched || zip.dirty)">The Zip Code is required</span>
          <span *ngIf="zip.hasError('pattern') && (zip.touched || zip.dirty)">Not a valid US or Canadian Zip Code</span>
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="10px">
      <div fxFlex="1 1 50%" class="form-field">
        <div class="form-label">Job Type<sup>*</sup></div>
        <select formControlName="jobType">
          <option value="">Please select type of job.</option>
          <option *ngFor="let jobType of jobTypes" value="{{ jobType.name }}">
            {{ jobType.name }}
          </option>
        </select>
        <div class="form-error">
          <span *ngIf="jobType.invalid && (jobType.touched || jobType.dirty)">Job Type is required</span>
        </div>
      </div>
    </div>

    <div fxLayout="row">
      <div fxFlex="1 1 100%" class="form-field">
        <div class="form-label">Job Comments</div>
        <textarea
          pInputTextarea
          class="width-100-pct"
          formControlName="comments"
          placeholder=""
          rows="3"
          columns="50"
          maxlength="512"
        ></textarea>
      </div>
    </div>

    <div fxLayout="row">
      <div fxFlex="0 0 auto" class="form-field">
        <div class="flex align-items-center">
          <p-checkbox name="changesInLast4Years" value="changesInLast4Years" (onChange)="onChecked()"></p-checkbox>
          <label class="ml-2 mb-0">Changed in the last 4 years</label>
        </div>
      </div>
    </div>

    <div fxLayout="row">
      <p-button label="Locate Address" class="p-button-secondary" type="submit" (click)="findAddress()"></p-button>
    </div>

    <div fxFlex="1 1 100%" class="messages colorGreen mt-2">
      <div class="text-green mb-2" *ngIf="showAddressDiffMessage">
        {{ addressDiffMessage }}
      </div>
      <div class="text-green" *ngIf="showSearchResultMessage">
        {{ searchResultMessage }}
      </div>
      <div class="projectCreationError" *ngIf="message && message.length > 0">
        {{ message }}
      </div>
    </div>

    <div fxFlex="0 0 auto" fxLayoutAlign="end center">
      <button
        pButton
        pRipple
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text p-button-secondary"
        (click)="close()"
      ></button>
      <p-button
        type="submit"
        label="Create Job"
        class="p-button-secondary"
        type="submit"
        [disabled]="submitButtonDisabled || !newProjectForm.valid"
        (onClick)="submitButtonDisabled = true; createProject()"
        styleClass="ml-2"
      ></p-button>
    </div>
  </div>
</div>
