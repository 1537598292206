export enum EagelViewFileTypeIds {
  Invoice = 2, // Invoice of the order.
  PremiumReport = 3, // Premium report.
  NeighborhoodReport = 4, // Neighborhood report.
  ExclusiveCover = 5, // Exclusive cover page.
  TopImage = 6, // Top image of the property.
  ParkingLotReport = 7, // Parking lot report.
  SunSiteReport = 8, // SunSite report.
  PropertyOwner = 12, // Property owner report.
  PreStandard = 13, // Pre-standard report.
  ImagesOnly = 17, // Images only report.
  EagleViewXML = 18, // EagleView xml.
  SouthSideImage = 22, // North image of the property.
  NorthSideImage = 23, // South image of the property.
  WestSideImage = 24, // East image of the property.
  EastSideImage = 25, // West image of the property.
  AutoCAD = 26, // Autodesk DXF file.
  AppliCAD = 27, // Applicad Roof Exchange Format file (RXF).
  LengthDiagram = 28, // Length diagram from the report.
  NotesDiagram = 29, // Notes diagram from the report.
  PitchDiagram = 31, // Pitch diagram from the report.
  AreaDiagram = 32, // Area diagram from the report.
  DetailedLengthDiagram = 42, // Detailed length diagram from the report.
  NotesNoLabelDiagram = 43, // Notes diagram with no labels from the report.
  ClaimsReadyReport = 51, // ClaimsReady report.
  AA3DReport = 52, // AA3D report.
  AA2DReport = 53, // AA2D report.
  EC3DReport = 71, // EC3D report.
  EC2DReport = 73, // EC2D report.
  ECPremiumReport = 75, // EC Premium report.
  PremiumAWReport = 76, // Premium AW report.
  WallsAddOn = 77, // Walls add-on report.
  WallAreaDiagram = 79, // Wall area diagram from the report.
  AlternateWallView = 80, // Alternate wall view from the report.
  MissingWallDiagram = 81, // Missing wall diagram from the report.
  NorthElevationDiagram = 82, // North elevation diagram from the report.
  SouthElevationDiagram = 83, // South elevation diagram from the report.
  EastElevationDiagram = 84, // East elevation diagram from the report.
  WestElevationDiagram = 85, // West elevation diagram from the report.
  RoofPenetrationDiagram = 86, // Roof penetration diagram from the report.
  WallsOnlyReport = 87, // WallsOnly report.
  WallsOnlyMetadata = 88, // WallsOnly meta data.
  RiskManagementMetadata = 89, // Risk Management meta data.
  LivingAreaDiagram = 90, // Living area diagram from the report.
  WallsNoLabelsDiagram = 91, // Walls diagram with no labels from the report.
  AreaViewImage = 92, // Area view image from the report.
  PitchRoofPenetrationDiagram = 93, // Pitch roof penetration diagram from the report.
  BldgFootprintDiagram = 94, // Building footprint diagram from the report.
  SiteMapDiagram = 95, // Sitemap diagram from the report.
  OutbuildingDiagram = 96, // Outbuilding diagram from the report.
  RoofGeometryReport = 98, // Roof geometry report.
  QuickSquaresReport = 105, // QuickSquares report.
  EVMeasurementJSON = 107, // EagleView measurement JSON.
  GutterReport = 109, // Gutter report.
  DiagramReport = 111, // Diagram report.
  QuickSquaresECReport = 116, // QuickSquares EC report.
  EC2DPrePitchReport = 118, // EC2D Pre-pitch Report
  WallsLiteReport = 121, // WallsLite report.
  SunSiteCompleteReport = 132, // SunSite Complete Report.
}
