<p-toast [style]="{ marginBottom: '10px' }" position="bottom-right"></p-toast>
<p-panel>
  <ng-template pTemplate="header">
    <div class="flex align-items-center gap-2">
      <h5 class="mt-3" style="padding-left: 1rem">Task Details</h5>
      <br />
    </div>
  </ng-template>
  <ng-template pTemplate="body">
    <div fxFlex="1 0 30%" class="job-form-info">
      <p class="task-title mb-2">
        Company Name:
        <span class="task-value"> {{ taskDetails.inviteeCompanyName }} </span>
      </p>
      <p class="task-title mb-2">
        First Name:
        <span class="task-value"> {{ taskDetails.inviteeFirstName }} </span>
      </p>
      <p class="task-title mb-2">
        Last Name:
        <span class="task-value"> {{ taskDetails.inviteeLastName }} </span>
      </p>
      <p class="task-title mb-2">
        Email:
        <span class="task-value"> {{ taskDetails.inviteeEmail }} </span>
      </p>
      <p class="task-title mb-2">
        Contact Phone Number:
        <span class="task-value"> {{ taskDetails.inviteePhoneNumber }} </span>
      </p>
      <p class="task-title mb-2">
        Company Address:
        <span class="task-value">
          {{ taskDetails.inviteeCompanyAddress.street }}, {{ taskDetails.inviteeCompanyAddress.city }}
          {{ taskDetails.inviteeCompanyAddress.state }} {{ taskDetails.inviteeCompanyAddress.postalCode }}
        </span>
      </p>
      <p class="task-title mb-2">
        Company Phone Number:
        <span class="task-value"> {{ taskDetails.inviteeCompanyPhoneNumber }} </span>
      </p>
      <ng-container *ngIf="isCompletingTask">
        <p-skeleton width="0rem" height="0rem"></p-skeleton>
        <p-skeleton width="0rem" height="0rem"></p-skeleton>
        <p-skeleton width="0rem" height="0rem"></p-skeleton>
      </ng-container>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div style="padding-left: 10rem">
      <button
        pButton
        pRipple
        label="Close"
        icon="pi pi-times"
        class="p-button-text p-button-secondary"
        (click)="close()"
      ></button>
      <p-button
        pRipple
        label="Complete"
        icon="pi pi-check-circle"
        class="p-button-text"
        (click)="completeTask(taskDetails)"
        style="padding-left: 1rem"
      ></p-button>
    </div>
  </ng-template>
</p-panel>
