import { Injectable } from "@angular/core";
import { ConfirmEventType, Confirmation, ConfirmationService } from "primeng/api";
import { Observable, from } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ConfirmService {
  constructor(private confirmationService: ConfirmationService) {}

  /**
   * Triggers display of a general confirmation dialog, with a ceck icon by default
   * @param header Dialogh header verbiage
   * @param message Dialog body verbiage
   * @param icon Class of the icon to display in the dialog body
   * @param onAccepted Anonymous method to invoke if accepted
   * @param OnRejected Anonymous method to invoke if rejected
   */

  confirm(header: string, message: string, icon: string = "pi pi-check", OnAccepted = () => {}, onRejected = () => {}) {
    this.confirmationService.confirm({
      header,
      message,
      icon,
      accept: () => OnAccepted(),
      reject: () => onRejected(),
      acceptButtonStyleClass: "p-button-primary",
      rejectButtonStyleClass: "p-button-secondary",
    });
  }

  /**
   * Triggers display of a general confirmation dialog, with a ceck icon by default
   * @param header Dialogh header verbiage
   * @param message Dialog body verbiage
   * @param icon Class of the icon to display in the dialog body
   * @param onAccepted Anonymous method to invoke if accepted
   * @param OnRejected Anonymous method to invoke if rejected
   * @param acceptButtonStyleClass Style class of the accept button.
   * @param rejectButtonStyleClass Style class of the reject button.
   * @param acceptIcon Icon of the accept button.
   * @param rejectIcon Icon of the reject button.
   * @param defaultFocus Element to receive the focus when the dialog gets visible, valid values are "accept", "reject", "close" and "none"
   */

  confirmEnhanced(
    header: string,
    message: string,
    icon: string = "pi pi-check",
    OnAccepted = () => {},
    onRejected = (type: ConfirmEventType) => {},
    acceptButtonStyleClass: string = "p-button-primary",
    rejectButtonStyleClass: string = "p-button-secondary",
    acceptLabel: string,
    rejectLabel: string,
    acceptIcon?: string,
    rejectIcon?: string,
    defaultFocus?: string
  ) {
    this.confirmationService.confirm({
      header,
      message,
      icon,
      accept: () => OnAccepted(),
      reject: (type: ConfirmEventType) => onRejected(type),
      acceptButtonStyleClass,
      rejectButtonStyleClass,
      acceptLabel,
      rejectLabel,
      acceptIcon,
      rejectIcon,
      defaultFocus,
    });
  }

  confirm$(confirmationOptions: Confirmation): Observable<boolean> {
    return from(
      new Promise<boolean>((resolve) => {
        this.confirmationService.confirm({
          ...confirmationOptions,
          accept: () => resolve(true),
          reject: () => resolve(false),
        });
      })
    );
  }

  confirmEnhanced$(confirmationOptions: Confirmation): Observable<number | boolean> {
    return from(
      new Promise<number | boolean>((resolve) => {
        this.confirmationService.confirm({
          ...confirmationOptions,
          accept: () => resolve(true),
          reject: () => resolve(false),
        });
      })
    );
  }

  close() {
    this.confirmationService.close();
  }
}
