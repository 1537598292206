import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AuthContext } from "./authentication.service";
import { CookieService } from "ngx-cookie-service";
import { skipWhile, take } from "rxjs/operators";
import { Store } from "@ngxs/store";
import { IConfigs } from "src/app/core/data/models/iconfigs";
import { SpinnerService } from "src/app/core/spinner/spinner.service";
import {
  AdminContext,
  AuthStatus,
  Manufacturer,
  ManufacturerProfile,
  ProfilePayee,
  ProfileStatus,
  RoofingWRXBranch,
  ThirdPartyAuth,
  UserRole,
} from "src/app/core/data/models/AppInitializationData";
import { AppInitializationData } from "src/app/core/store/app.models";
import { AppActions } from "src/app/core/store/app.actions";

@Injectable()
export class AppState {
  checkedLogin = false;
  loginAttempted = false;

  private appConfigs: IConfigs;

  private _appData: AppInitializationData = null;
  private _appData$ = new BehaviorSubject<AppInitializationData>(this._appData);
  private _profile: ManufacturerProfile = null;
  private _profile$ = new BehaviorSubject<ManufacturerProfile>(this._profile);
  private _profileId: number = +this.cookieService.get("manufacturer_id");

  //Temporary Fix
  private _activeProfiles = this._appData?.profiles;
  private _activeProfiles$ = new BehaviorSubject<ManufacturerProfile[]>(this._activeProfiles);

  constructor(
    private cookieService: CookieService,
    private spinner: SpinnerService,
    private store: Store
  ) {}

  get configs(): IConfigs {
    return this.appConfigs;
  }

  get activeProfiles() {
    return this._activeProfiles;
  }

  get activeProfiles$() {
    return this._activeProfiles$.pipe(skipWhile((i) => i == null));
  }

  get appData() {
    return this._appData;
  }

  get appData$() {
    return this._appData$.pipe(skipWhile((i) => i == null));
  }

  get appDataOnce$() {
    return this.appData$.pipe(take(1));
  }

  /** Profile state ************************************/

  get profile$() {
    return this._profile$.pipe(skipWhile((i) => i == null));
  }

  get profileOnce$() {
    return this.profile$.pipe(take(1));
  }

  get profileId() {
    return this._profileId;
  }

  get profiles(): ManufacturerProfile[] {
    return this._appData?.profiles || [];
  }

  get featureFlags(): Record<string, boolean>[] {
    return this._appData?.featureFlags || [];
  }

  get needsCurrentProfile(): boolean {
    return !this._profileId;
  }

  get managedManufacturers() {
    if (this.isAdminOrRPAdmin) {
      return this._appData?.manufacturers ?? [];
    }
    return this.profiles.filter((p) => p.role === UserRole.Admin).map((p) => p.manufacturer);
  }

  get authorizations(): ThirdPartyAuth[] {
    return this._profile?.authorizations ?? [];
  }

  get isAdminOrRPAdmin() {
    const role = this._appData?.user.roles;
    const adminContext = this._appData?.user.adminContext ?? AdminContext.Internal;

    return role?.some(
      (s) => (s === UserRole.RPAdmin || s === UserRole.Admin) && adminContext === AdminContext.Internal
    );
  }

  get isThirdPartyAdmin() {
    const isAdmin = this._appData?.user.roles.find((s) => s === UserRole.Admin);
    const adminContext = this._appData?.user.adminContext;

    return isAdmin && adminContext != null && adminContext !== AdminContext.Internal;
  }

  get adminContext() {
    return this._appData?.user.adminContext ?? AdminContext.Internal;
  }

  get isRPAdmin() {
    return (
      this._appData?.user.roles?.some((s) => s === UserRole.RPAdmin) && this.adminContext === AdminContext.Internal
    );
  }

  get roles() {
    return this.appData?.user.roles;
  }

  get canUseReporting() {
    return this._appData?.user.roles.some(s => s === UserRole.RPAdmin || s === UserRole.Reporting);
  }

  get canUseAdminTasks() {
    return this._appData?.user.roles.some(s => s === UserRole.RPAdmin || s === UserRole.PSAdmin );
  }

  get canUseCog() {
    return this._appData?.user.roles.some((s) => s === UserRole.Admin || s === UserRole.RPAdmin || s === UserRole.Reporting || s === UserRole.PSAdmin);
  }

  get isAdmin() {
    return this.isAdminOrRPAdmin || this.isThirdPartyAdmin || this._profile?.role === UserRole.Admin;
  }

  get isBillingToCompany() {
    return !this.isBillingToManufacturer;
  }

  get isBillingToManufacturer() {
    return this._profile?.payee == ProfilePayee.Manufacturer;
  }

  get currentProfileIsPending() {
    return this._profile?.status === ProfileStatus.Pending;
  }

  get currentProfile() {
    return this._profile;
  }

  get profileManufacturer() {
    return [this.currentProfile.manufacturer];
  }

  /**
   * We should show the status screen if the user's profile is still waiting on
   * any 3rd party accounts AND we have not set the 'skipRegistrationStatus' flag
   * on their profile entity OR in the session.
   *
   * We set the session flag to prevent the status screen from showing while linking
   * their SmartBuild account for the first time. After that the profile flag is set
   * and this screen will never show again.
   */
  get showRegistrationStatus(): boolean {
    return this.authorizations.find((a) => !a.registrationCompleted) != null;
  }

  set skipRegistrationStatus(skip: boolean) {
    if (skip) {
      sessionStorage.setItem("skipRegistrationStatus", "true");
    } else {
      sessionStorage.removeItem("skipRegistrationStatus");
    }
  }

  /**
   * Determine if we need to enter the profile setup flow:
   * 1. any profiles that pending acceptance
   * 2. no current profile but user has multiple (profile selection)
   * 3. current profile is waiting on registration completion
   * 4. current profile needs to refresh any 3rd party authorizations
   */
  get needsSetupFlow(): boolean {
    return (
      this.currentProfileIsPending ||
      this.needsCurrentProfile ||
      this.showRegistrationStatus ||
      this.nextInvalidAuth() != null
    );
  }

  /** Roofing WRX state ************************************/

  get roofingWRXEnabled() {
    return this.authorizations.find((p) => p.context == AuthContext.RoofingWRX) != null;
  }

  get roofingWRXJobsEnabled() {
    return this.roofingWRXEnabled && (this._profile.roofingWRXJobsEnabled ?? false);
  }

  get isRoofingWRXAdmin() {
    if (this._profile?.payee != ProfilePayee.Company) {
      return false;
    }
    const auth = this.getAuthData(AuthContext.RoofingWRX);
    return auth?.claims != null && auth.claims["role"] == "admin";
  }

  get needsRoofingWRXBillingSetup() {
    if (!this.roofingWRXEnabled) {
      return false;
    }
    const branches = this.roofingWRXBranches;

    return branches.length == 0;
  }

  get roofingWRXBranches(): RoofingWRXBranch[] {
    const auth = this.getAuthData(AuthContext.RoofingWRX);
    return auth?.claims?.branches ?? [];
  }

  get imageRefreshToken() {
    return this._appData?.imageRefreshToken ?? Date.now();
  }

  /** Methods for updating current state ************************************/

  public getSessionData(key: string): string {
    return sessionStorage.getItem(key);
  }

  /**
   * Set the loaded configuration data
   * @param data The loaded configuration data
   */
  public initializeConfigs(data: IConfigs): void {
    if (!this.appConfigs) {
      this.appConfigs = data;
    }
  }

  /**
   * Initialite the app state
   * @param data App initialization data
   * @returns
   */
  public setAppData(data: AppInitializationData): AppInitializationData {
    this._appData = data;
    if (!this._appData.profiles) {
      this._appData.profiles = [];
    }

    if (!this._appData.manufacturers && this.isAdminOrRPAdmin) {
      this._appData.manufacturers = [];
    } else {
      this.store.dispatch(new AppActions.SetManufacturers(this._appData.manufacturers));
    }

    this._appData.profiles.forEach((p) => {
      p.authorizations?.forEach((a) => {
        if (a.claims?.length == 0) {
          a.claims = [{ name: "email", label: "Email", value: "" }];
        }
      });
    });

    // make sure we have a valid profile id
    if (this.profiles.length == 1) {
      this.setProfile(this.profiles[0].manufacturer.id);
    } else if (this._profileId) {
      this.setProfile(this._profileId);
    }

    this._appData$.next(this._appData);
    this.spinner.hide();
    return this._appData;
  }

  /**
   * Updates manufactufer data
   * @param manufacturer The manufactufer to update
   */
  public updateManufacturer(manufacturer: Manufacturer) {
    // check profiles and update manufacturer info if needed
    const profile = this.profiles.find((p) => p.manufacturer.id == manufacturer.id);
    if (profile) {
      profile.manufacturer = manufacturer;
      this._appData.profiles.sort((a, b) => a.manufacturer.name.localeCompare(b.manufacturer.name));
    }

    // RP admins should also check their manufactufer list
    let manufacturersCopy;
    if (this.isAdminOrRPAdmin) {
      const index = this._appData.manufacturers.findIndex((m) => m.id == manufacturer.id);
      if (index >= 0) {
        manufacturersCopy = [...this._appData.manufacturers];
        manufacturersCopy.splice(index, 1, manufacturer);
        this._appData.manufacturers = manufacturersCopy;
      } else {
        this._appData.manufacturers.push(manufacturer);
      }
      this._appData.manufacturers.sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  /**
   * Search for a user profile by manufacturer id
   * @param profileId the manufacturer id
   * @returns
   */
  public getProfile(profileId: number): ManufacturerProfile {
    return this.profiles.find((p) => p.manufacturer.id == profileId) || this.profiles.find((p) => p !== undefined);
  }

  /**
   * set the current selected manufacturer profile by id
   * @param profileId The manufacturer id
   * @returns
   */
  public setProfile(profileId: number): ManufacturerProfile {
    const profile = this.getProfile(profileId);
    if (profile) {
      this._profileId = profile.manufacturer.id;
      this.cookieService.set("manufacturer_id", this._profileId.toString());
    } else {
      this._profileId = null;
    }
    return profile;
  }

  /**
   * Add or replace profile data within the profile array
   * @param profile The updated profile data
   */
  public updateProfile(profile: ManufacturerProfile): ManufacturerProfile {
    if (this._appData) {
      // splice or append the profile into the application data array
      const index = this.profiles.findIndex((p) => p.manufacturer.id == profile.manufacturer.id);
      if (index >= 0) {
        this.profiles.splice(index, 1, profile);
      } else {
        this.profiles.push(profile);
      }
      // update profile subject if this is our current profile
      if (profile.manufacturer.id == this._profileId) {
        this._profile = profile;
        this._profile$.next(profile);
      }
    }
    return profile;
  }

  /**
   * Set the
   * @param branches
   * @returns
   */
  public updateProfileRoofingWRXBranches(branches: RoofingWRXBranch[]): RoofingWRXBranch[] {
    if (this._profile) {
      this._profile.roofingWRXBranches = branches;
    }
    return branches;
  }

  /**
   * Replaces third party auth data for the current profile
   * @param auth
   */
  public updateAuth(auth: ThirdPartyAuth): ThirdPartyAuth {
    if (this._profile) {
      const index = this._profile.authorizations.findIndex((a) => a.context == auth.context);
      if (index >= 0) {
        this._profile.authorizations.splice(index, 1, auth);
      } else {
        this._profile.authorizations.push(auth);
      }
    }
    return auth;
  }

  /**
   * Get third party authorization information for current profile
   * @param context The 3rd party auth context
   * @returns
   */
  public getAuthData(context: AuthContext): ThirdPartyAuth {
    return (
      this.authorizations?.find((auth) => auth.context === context) || {
        context: AuthContext.None,
        status: AuthStatus.None,
        claims: {},
      }
    );
  }

  /**
   * Reset auth data for the current profile by context
   * @param context The 3rd party auth context
   */
  public expireAuthData(context: AuthContext) {
    const auth = this.authorizations.find((i) => i.context === context);
    if (auth) {
      auth.status = AuthStatus.Expired;
    }
  }

  /**
   * Get the next 3rd party authorization that needs to be renewed
   * - returns 'null' if all are valid
   * - won't return RoofingWRX unless it is enabled
   */
  public nextInvalidAuth(): ThirdPartyAuth {
    return this.authorizations.find((auth) => auth.status !== AuthStatus.Valid);
  }

  public setActiveProfiles() {
    this._activeProfiles = this.profiles.filter((x) => x.status !== ProfileStatus.Inactive);
    this._activeProfiles$.next(this._activeProfiles);
  }
}
