import { CommonModule, CurrencyPipe, PercentPipe } from "@angular/common";
import { GridComponent } from "./grid/grid.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DialogService, DynamicDialogComponent, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { NgModule } from "@angular/core";
import { PrimengLibraryModule } from "./libraries/primeng-library.module";
import { SideNavigationComponent } from "./side-navigation/side-navigation.component";
import { ConfirmationService, MessageService } from "primeng/api";
import { SmartBuildService } from "./services/smartbuild.service";
import { WindowRefService } from "./helpers/window-ref.service";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ErrorDialogService } from "./services/error.service";
import { DialogModule } from 'primeng/dialog';
import {DividerModule} from 'primeng/divider';
import { StatusBadgeComponent } from './status-badge/status-badge.component';
@NgModule({
  declarations: [GridComponent, SideNavigationComponent, StatusBadgeComponent],
  imports: [CommonModule, FontAwesomeModule, ReactiveFormsModule, PrimengLibraryModule, FormsModule, FlexLayoutModule, DialogModule,DividerModule],
  exports: [GridComponent, PrimengLibraryModule, SideNavigationComponent, ReactiveFormsModule, FontAwesomeModule, DialogModule,DividerModule,StatusBadgeComponent],
  providers: [
    PercentPipe,
    CurrencyPipe,
    DynamicDialogComponent,
    DialogService,
    DynamicDialogRef,
    DynamicDialogConfig,
    MessageService,
    SmartBuildService,
    WindowRefService,
    ConfirmationService,
    ErrorDialogService,
  ],
})
export class SharedModule { }
