<h2 mat-dialog-title>{{ data?.title }}</h2>
<div mat-dialog-content [innerHtml]="data?.message"></div>
<div mat-dialog-actions class="mt-4 flex justify-content-end">
  <button mat-raised-button (click)="onCancel()" *ngIf="data?.secondaryButtonText" class="mr-2">
    {{ data?.secondaryButtonText ?? "No" }}
  </button>
  <button mat-raised-button (click)="onConfirm()" color="primary" *ngIf="data?.primaryButtonText">
    {{ data?.primaryButtonText ?? "Yes" }}
  </button>
</div>
