<div *ngIf="this.profile != null">
  <div *ngIf="!allComplete">
    <h4 role="heading" class="mb-2">Your Registration is Pending</h4>
    <p>
      Your <b>{{ this.profile.manufacturer.name }}</b> Company Account is currently being created. You will receive an
      email when all necessary credentials have been established with a button directing you to return to this page to
      complete your Roofing Passport Setup.
    </p>
  </div>
  <div *ngIf="allComplete">
    <h4 role="heading" class="mb-2">Your Registration is ready to proceed</h4>
    <p>
      Your <b>{{ this.profile.manufacturer.name }}</b> Company Account has been created. You should have received an
      email with your partner system credentials. Please have this email ready then click on the &lt;Continue&gt; button
      below to complete your Roofing Passport Setup.
    </p>
  </div>
  <br />
  <p-divider></p-divider>
  <h5 class="mb-2">Registration Status</h5>
  <div class="registration-status">
    <ng-template ngFor let-auth [ngForOf]="authorizations" let-i="index">
      <p>
        {{ getAuthName(auth) }}: <span class="complete" *ngIf="auth.registrationCompleted">Complete</span
        ><span class="pending" *ngIf="!auth.registrationCompleted">Pending</span>
      </p>
      <br />
    </ng-template>
  </div>
  <p-divider></p-divider>
  <div>
    <div class="steps-bottom">
      <div class="button-container">
        <button class="btn btn-primary" [disabled]="!allComplete" (click)="continue()">Continue</button>
      </div>
    </div>
  </div>
</div>
