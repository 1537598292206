import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { skipWhile, take } from "rxjs/operators";
import { AppConstants } from "src/app/shared/constants/app-constants";
import { SpinnerService } from "src/app/core/spinner/spinner.service";
import { ManufacturerProfile } from "src/app/core/data/models/AppInitializationData";
import { AppState } from "src/app/shared/services/app-state";
import { ProfileService } from "src/app/shared/services/profile.service";

@Component({
  selector: "app-profile-license",
  templateUrl: "./profile-license.component.html",
})
export class ProfileLicenseComponent implements OnInit {
  licenseAccepted = false;
  profile: ManufacturerProfile;
  errorMessage: string;
  busy: Subscription;

  staticImagesBasePath = this.appState.configs.StaticImagesBasePath.endsWith("/")
    ? this.appState.configs.StaticImagesBasePath
    : this.appState.configs.StaticImagesBasePath.padEnd(this.appState.configs.StaticImagesBasePath.length + 1, "/");
  imageUrl = `url(${this.staticImagesBasePath}roofing-passport-site-login-background.jpg?${this.appState.imageRefreshToken})`;

  constructor(
    private appState: AppState,
    private profileService: ProfileService,
    private router: Router,
    private spinner: SpinnerService
  ) {}

  ngOnInit(): void {
    // wait until we have a non-null profile
    this.appState.profile$
      .pipe(
        skipWhile((p) => p == null),
        take(1)
      )
      .subscribe((profile) => (this.profile = profile));
  }

  accept() {
    this.profileService
      .acceptManufacturerLicenseAgreement(this.profile.manufacturer.id)
      .subscribe(({ success, error }) => {
        if (success) {
          this.handleLicenseUpdate();
        } else {
          console.log("unable to accept license agreement: " + error);
          this.errorMessage = AppConstants.RoofingPassportCommunicationError;
          this.licenseAccepted = false;
        }
      });
  }

  private handleLicenseUpdate() {
    this.profile.licenseAgreement = {
      accepted: true,
      lastAcceptedDateTime: new Date(),
    };
    this.router.navigate(["profile"]);
  }
}
