import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { AppNotification } from "src/app/core/data/models/AppNotification";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  private notificationSubject: Subject<AppNotification> = new Subject<AppNotification>();
  private notificationClosedSubject: Subject<any> = new Subject<any>();

  private currentNotifcation: AppNotification;

  constructor() {}

  public onNotification(): Observable<AppNotification> {
    return this.notificationSubject.asObservable();
  }

  public onClosedNotification(): Observable<any> {
    return this.notificationClosedSubject.asObservable();
  }

  public showNotification(notification: AppNotification): void {
    this.currentNotifcation = notification;
    this.notificationSubject.next(notification);
  }

  public hideNotification(): void {
    this.notificationClosedSubject.next(this.currentNotifcation.responseData);
  }
}
