import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MenuItem } from "primeng/api";

@Component({
  selector: "roofing-passport-side-navigation",
  templateUrl: "./side-navigation.component.html",
  styleUrls: ["./side-navigation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavigationComponent {
  @Input() menuItems: MenuItem[];
  @Input() headerText: string;
  @Input() returnButtonText: string = "Go back";
  @Input() returnButtonRouterLink?: any[] = undefined;
  @Input() useAltStyle = false;

  constructor() {}
}
