import { Component, OnDestroy, OnInit } from "@angular/core";
import { AuthContext, AuthenticationService } from "../shared/services/authentication.service";
import { AppState } from "../shared/services/app-state";
import { AppNotification } from "../core/data/models/AppNotification";
import { NotificationService } from "../shared/services/notification.service";
import { skipWhile, takeUntil } from "rxjs/operators";
import { LocalStorageService } from "../shared/services/local-storage.service";
import { ReplaySubject } from "rxjs";
import { CookiePolicyAccepted } from "../core/data/models/CookiePolicyAccepted";
import { NotificationAligment } from "../core/data/models/NotificationAligment";
import { UiTemplateData } from "../core/data/models/AppInitializationData";

@Component({
  selector: "roofing-passport-features",
  templateUrl: "./features.component.html",
  styleUrls: ["./features.component.scss"],
})
export class FeaturesComponent implements OnInit, OnDestroy {
  appNotification: AppNotification;
  showNotification = false;
  private $destroyed: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public appVersion: string = this.appState.configs.AppVersion;
  footerData: UiTemplateData[];

  get claims() {
    if (this.authService.isAuthenticated(AuthContext.Azure)) {
      return this.authService.claims(AuthContext.Azure);
    }
    return false;
  }
  constructor(
    private authService: AuthenticationService,
    private appState: AppState,
    private notificationService: NotificationService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.initializeData();
  }

  ngOnDestroy() {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

  initializeData() {
    this.notificationService
      .onNotification()
      .pipe(takeUntil(this.$destroyed))
      .subscribe(this.handleNotifications.bind(this));

    this.notificationService
      .onClosedNotification()
      .pipe(takeUntil(this.$destroyed))
      .subscribe(this.handleClosedNotification.bind(this));

    this.appState.appData$
      .pipe(skipWhile((d) => d === null))
      .subscribe((appData) => (this.footerData = appData.templateData));

    if (!this.localStorageService.getLocalStorage().getItem("hasAcceptedCookiePolicy")) {
      const response: CookiePolicyAccepted = { policyAccepted: true };
      this.notificationService.showNotification(<AppNotification>{
        containerHeight: "100px",
        containerWidth: null,
        height: "100px",
        width: "1000px",
        align: NotificationAligment.bottomCenter,
        showOKButton: true,
        okButtonText: "OK",
        responseData: response,
        content:
          'This site uses <a href="https://privacy.sherwin-williams.com/privacy-policy#cookies-web-beacons">cookies and other tracking technologies</a> to improve your browsing experience, analyze site traffic, and improve our marketing efforts.  See our <a href="https://privacy.sherwin-williams.com/privacy-policy">Privacy Policy</a> for more information on these technologies.',
      });
    }
  }

  private handleNotifications(value: AppNotification) {
    this.appNotification = value;
    this.showNotification = true;
  }

  private handleClosedNotification(data: any) {
    this.showNotification = false;
    if (data.policyAccepted) {
      this.localStorageService.getLocalStorage().setItem("hasAcceptedCookiePolicy", "true");
    }
  }
}
