import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { ManufacturerProfile, RoofingWRXBranch } from "src/app/core/data/models/AppInitializationData";
import jobTypesList from "src/app/core/data/job-types";
import {
  CreateOrderRequest,
  Order,
  OrderSequence,
  PartnerSystem,
} from "../../../../core/data/models/CreateOrderRequest";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DialogService } from "src/app/shared/services/dialog.service";
import { AppState } from "src/app/shared/services/app-state";
import { OrderService } from "src/app/shared/services/order.service";
import { ErrorDialogService } from "src/app/shared/services/error.service";
import panelTypesList from "../../../../core/data/panel-types";
import { PanelTypesEnum } from "../../../../core/enums/panel-types.enum";

@Component({
  selector: "app-roofing-wrx-initiate-order",
  templateUrl: "./roofing-wrx-initiate-order.component.html",
  styleUrls: ["./roofing-wrx-initiate-order.component.scss"],
})
export class RoofingWrxInitiateOrderComponent implements OnInit {
  @ViewChild("fileUpload") fileUpload: ElementRef;
  PanelTypesEnum = PanelTypesEnum;
  newJobForm: UntypedFormGroup = this.fb.group({
    name: ["", [Validators.required, Validators.maxLength(29)]],
    comments: [],
    branch_Id: [""],
    jobType: ["", [Validators.required]],
    panelType: [""],
    user_Id: [""],
    coverageWidth: [0],
    ridgeOffset: [0],
    hipOffset: [0],
    valleyOffset: [0],
    eaveOffset: [0],
    maxLength: [200],
    overlap: [0],
    clipSpacingCfc: [24],
    fastenersCfc: [1],
    fastenersCfs: [12],
    fastenersXf: [85],
    roofingWRXOnly: [false],
    pricingmodel: ["Single", Validators.required],
  });
  jobTypes = jobTypesList;
  panelTypes = panelTypesList;
  files: File[] = [];
  branches: RoofingWRXBranch[] = [];
  submitButtonDisabled = false;
  errorMessage?: string;
  busy?: Subscription;
  roofingWRXJobsEnabled = false;
  profile: ManufacturerProfile;
  profile$ = this.appState.profile$;
  showJobErrorDialog = false;
  dialogData: any;
  roofingWRXOnlyEnabled = false;

  constructor(
    private appState: AppState,
    private dialogService: DialogService,
    private errorDialogService: ErrorDialogService,
    private fb: UntypedFormBuilder,
    private dialogRef: DynamicDialogRef,
    private orderService: OrderService
  ) {}

  get branchId() {
    this.newJobForm.get("overlap");
    return this.newJobForm.get("branch_Id");
  }

  get jobName() {
    return this.newJobForm.get("name");
  }

  get jobType() {
    return this.newJobForm.get("jobType");
  }

  get panelType() {
    return this.newJobForm.get("panelType");
  }

  get jobNotes() {
    return this.newJobForm.get("comments");
  }

  get coverageWidth() {
    return this.newJobForm.get("coverageWidth");
  }

  get ridgeOffset() {
    return this.newJobForm.get("ridgeOffset");
  }

  get hipOffset() {
    return this.newJobForm.get("hipOffset");
  }

  get valleyOffset() {
    return this.newJobForm.get("valleyOffset");
  }

  get eaveOffset() {
    return this.newJobForm.get("eaveOffset");
  }

  get maxLength() {
    return this.newJobForm.get("maxLength");
  }

  get overlap() {
    return this.newJobForm.get("overlap");
  }

  get fastenersCfs() {
    return this.newJobForm.get("fastenersCfs");
  }

  get fastenersXf() {
    return this.newJobForm.get("fastenersXf");
  }

  get fastenersCfc() {
    return this.newJobForm.get("fastenersCfc");
  }

  get clipSpacingCfc() {
    return this.newJobForm.get("clipSpacingCfc");
  }

  get roofingWRXOnly() {
    return this.newJobForm.get("roofingWRXOnly");
  }

  get pricingmodel() {
    return this.newJobForm.get("pricingmodel");
  }

  private addFiles(files: FileList) {
    if (files && files.length > 0) {
      this.files.push(...Array.from(files));
    }
  }

  ngOnInit(): void {
    this.appState.profile$.subscribe((profile: any) => {
      this.profile = profile;
      this.roofingWRXJobsEnabled = this.appState.roofingWRXJobsEnabled;
      this.branches = this.appState.roofingWRXBranches;
      if (this.branches.length > 1) {
        this.branchId.setValidators(Validators.required);
      }
    });
    if (!this.roofingWRXJobsEnabled) {
      this.dialogData = {
        header: "Roofing WRX jobs not enabled. ",
        message:
          "Roofing WRX is currently not enabled for your account. Please, contact your administrator for more information.",
      };
      this.showJobErrorDialog = true;
    }

    this.busy = this.appState.profileOnce$.subscribe();
    this.roofingWRXOnlyEnabled = this.appState.featureFlags["RoofingWRXOnly"];
  }

  close() {
    this.dialogRef.close();
  }

  onFileSelected(event) {
    this.addFiles(event.files);
    this.fileUpload.nativeElement.value = null;
  }

  clearFiles() {
    this.files = [];
  }

  onBrowseClicked() {
    this.fileUpload.nativeElement.click();
  }

  onDropFiles(event) {
    event.preventDefault();
    this.addFiles(event.dataTransfer.files);
  }

  onDragOverFiles(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  onRemoveFile(event) {
    this.files.splice(this.files.indexOf(event.file), 1);
  }

  setDefaultMaxLength() {
    const maxLengthControl = this.newJobForm.get("maxLength");
    if (!maxLengthControl.value) {
      maxLengthControl.setValue(200);
    }
  }

  onChangeRoofingWRXOnly() {
    const newValue = !this.roofingWRXOnly.value;
    this.newJobForm.get("roofingWRXOnly").setValue(newValue);

    const controls = [
      { name: "panelType", validators: [Validators.required] },
      { name: "coverageWidth", validators: [Validators.required] },
      { name: "ridgeOffset", validators: [Validators.required, Validators.min(-12), Validators.max(12)] },
      { name: "hipOffset", validators: [Validators.required, Validators.min(-12), Validators.max(12)] },
      { name: "valleyOffset", validators: [Validators.required, Validators.min(-12), Validators.max(12)] },
      { name: "eaveOffset", validators: [Validators.required, Validators.min(-12), Validators.max(12)] },
      { name: "maxLength", validators: [Validators.required, Validators.min(1), Validators.max(200)] },
      { name: "overlap", validators: [Validators.required, Validators.min(0), Validators.max(24)] },
      { name: "clipSpacingCfc", validators: [Validators.required, Validators.min(1), Validators.max(120)] },
      { name: "fastenersCfc", validators: [Validators.required, Validators.min(1), Validators.max(5)] },
      { name: "fastenersCfs", validators: [Validators.required, Validators.min(1), Validators.max(120)] },
      { name: "fastenersXf", validators: [Validators.required, Validators.min(1), Validators.max(300)] },
    ];

    controls.forEach((control) => {
      if (newValue) {
        this.newJobForm.controls[control.name].setValidators(control.validators);
      } else {
        this.newJobForm.controls[control.name].clearValidators();
      }
      this.newJobForm.controls[control.name].updateValueAndValidity();
    });
  }

  createJob() {
    if (this.branches.length == 0) {
      this.dialogData = this.errorDialogService.roofingWRXBillingSetup();
      this.showJobErrorDialog = true;
    } else if (this.branches.length == 1) {
      this.branchId.setValue(this.branches[0].id);
    }

    const maxLength = this.newJobForm.get("maxLength").value;
    if (!maxLength || maxLength === 0) {
      this.newJobForm.get("maxLength").setValue(200);
    }

    if (this.branches?.length > 0 && this.newJobForm.valid && this.roofingWRXJobsEnabled) {
      this.errorMessage = null;
      this.submitButtonDisabled = true;

      let clipValue = null;
      let fastenerValue = null;

      const WRXOnly = this.roofingWRXOnly.getRawValue();

      if (WRXOnly) {
        clipValue = this.panelType.getRawValue() === PanelTypesEnum.CFC ? this.clipSpacingCfc.getRawValue() : 0;
        fastenerValue =
          this.panelType.getRawValue() === PanelTypesEnum.CFC
            ? this.fastenersCfc.getRawValue()
            : this.panelType.getRawValue() === PanelTypesEnum.CFS
              ? this.fastenersCfs.getRawValue()
              : this.fastenersXf.getRawValue();
      }

      const order: Order = {
        name: this.jobName.getRawValue(),
        branch_id: this.branchId.getRawValue(),
        comments: this.jobNotes.getRawValue(),
        isRevision: false,
        coverageWidth: WRXOnly ? this.coverageWidth.getRawValue() : null,
        ridgeOffset: WRXOnly ? this.ridgeOffset.getRawValue() : null,
        hipOffset: WRXOnly ? this.hipOffset.getRawValue() : null,
        valleyOffset: WRXOnly ? this.valleyOffset.getRawValue() : null,
        eaveOffset: WRXOnly ? this.eaveOffset.getRawValue() : null,
        maxLength: WRXOnly ? this.maxLength.getRawValue() : null,
        overlap: WRXOnly ? this.overlap.getRawValue() : null,
        panelType: WRXOnly ? this.panelType.getRawValue() : PanelTypesEnum.Normal,
        clipValue: clipValue,
        fastenerValue: fastenerValue,
        pricingmodel: this.pricingmodel.getRawValue(),
      };

      const request: CreateOrderRequest = {
        jobName: this.jobName.getRawValue(),
        jobType: this.jobType.getRawValue(),
        jobComments: this.jobNotes.getRawValue(),
        orderSequence: this.roofingWRXOnly.getRawValue()
          ? OrderSequence.RoofingWRXOnly
          : OrderSequence.SmartBuildToRoofingWRXJob,
        roofData: {
          orderModel: JSON.stringify(order),
          orderModelPartnerSystem: PartnerSystem.RoofingWRX,
        },
      };

      this.orderService.processNewOrder(request, this.files).subscribe(
        () => {
          this.dialogRef.close();
        },
        (responseError) => {
          this.submitButtonDisabled = false;

          if (typeof responseError.error === "object") {
            if (responseError.error.error == "billing_error") {
              this.dialogService.roofingWRXBillingError(responseError.error.detail, this.branchId.value);
            } else {
              this.errorMessage = responseError.error.detail ?? this.appState.configs.NewOrderCommunicationErrorMessage;
            }
          } else {
            this.errorMessage = responseError.error;
          }
        }
      );
    }
  }
}
