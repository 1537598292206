<p-toast [style]="{ marginBottom: '10px' }" position="bottom-right"></p-toast>
<form novalidate [formGroup]="trainingStatusUpdatesForm" (ngSubmit)="submit()">
  <div class="container login" [style.background-image]="imageUrl">
    <span role="img" aria-label="Wizard background"></span>
    <div class="wizard">
      <div class="wizard-container">
        <h4 class="mb-2 text-center">Update Training Status</h4>
        <p-divider></p-divider>

        <div fxLayout="row">
          <!-- New Invited User -->
          <div fxFlex="1 1 50%" class="mb-2">
            <h5>({{ trainingStatusUpdatesForm?.controls.companyName.value }})</h5>
            <div>
              {{ trainingStatusUpdatesForm?.controls.firstName.value }}
              {{ trainingStatusUpdatesForm?.controls.lastName.value }}
            </div>
            <div>{{ trainingStatusUpdatesForm?.controls.email.value }}</div>
          </div>

          <p-divider></p-divider>
        </div>

        <div class="error-container" *ngIf="errorMessage">
          <span [innerHtml]="errorMessage"></span>
        </div>

        <!-- Training step data for SmartBuild context -->
        <div fxlayout="row" fxLayoutGap="10px" *ngIf="isSmartBuild" class="mt-3">
          <div [fxFlex]="isForAdmin ? '1 1 50%' : '1 1 100%'" class="form-field">
            <div class="form-label">RP Onboard Training</div>
            <select formControlName="trainingStatus">
              <option *ngFor="let step of trainingSteps; let i = index" value="{{ step.value }}">
                {{ step.label }}
              </option>
            </select>
          </div>

          <div *ngIf="isForAdmin" fxFlex="1 1 50%" class="form-field">
            <div class="form-label">SB Admin Training</div>
            <select formControlName="adminTrainingStatus">
              <option *ngFor="let step of adminTrainingSteps; let i = index" value="{{ step.value }}">
                {{ step.label }}
              </option>
            </select>
          </div>
        </div>
        <div fxFlex="1 1 100%" fxLayout="row" fxLayoutAlign="end" class="mt-2">
          <button
            fxFlex="0 0 auto"
            type="submit"
            class="btn btn-primary"
            [class.spinner]="busy && !busy.closed"
            [disabled]="busy && !busy.closed"
            (click)="submit()"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
