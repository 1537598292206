import {AuthContext} from "../services/authentication.service";

export const ApiEndpoints = {
  // Initialization
  getAppInitializationData: "Init/GetAppInitializationData",
  getIPASignature: (overrideIpaKey?: string) => {
    const baseEndpoint = "Init/GetIPASignature";
    return overrideIpaKey !== undefined ? `${baseEndpoint}${overrideIpaKey}` : baseEndpoint;
  },

  // Authentication
  loginWithCredentials: (context: AuthContext) => `Auth/Login/${context}`,
  completeRegistration: "Auth/CompleteRegistration/",
  storeUserToken: (context: AuthContext) => `Auth/StoreUserToken/${context}`,
  clearTokens: "Auth/ClearTokens/",
  acceptInvitation: (profileId?: string) => {
    const endpoint = "Auth/AcceptInvitation";
    return profileId ? `${endpoint}/${profileId}` : endpoint;
  },
  rejectInvitation: (profileId?: string) => {
    const endpoint = "Auth/RejectInvitation";
    return profileId ? `${endpoint}/${profileId}` : endpoint;
  },
  createInvitation: "Auth/CreateInvitation",

  //User
  acceptRoofingPassportLicenseAgreement: "User/acceptRoofingPassportLicenseAgreement",
  getTasks: "User/GetTasks",
  completeTask: "User/CompleteTask",

  // Reporting
  getOrdersByFilter: "Reporting/GetOrdersByFilter",
  getOrderStatistics: "Reporting/GetOrderStatistics",

  //Files
  downloadUserReport: (manufacturerId?: number) => {
    const baseEndpoint = "File/DownloadUserReportCSV";
    return manufacturerId !== undefined ? `${baseEndpoint}/${manufacturerId}` : baseEndpoint;
  },
  getUserReportByManufacturerId: (manufacturerId?: number) => {
    const baseEndpoint = "File/GetUserReportByManufacturerId";
    return manufacturerId !== undefined ? `${baseEndpoint}/${manufacturerId}` : baseEndpoint;
  },
  getSmartBuildReport: (jobId?: number) => {
    const baseEndpoint = "File/GetSmartBuildReport";
    return jobId !== undefined ? `${baseEndpoint}?jobid=${jobId}` : baseEndpoint;
  },
  getEagleViewReportFile: (jobId?: number, fileTypeId?: number) => {
    const baseEndpoint = "File/GetEagleViewReportFile";
    return jobId !== undefined || fileTypeId !== undefined
      ? `${baseEndpoint}?jobId=${jobId}&fileTypeId=${fileTypeId}`
      : baseEndpoint;
  },
  getRoofingWRXReportFile: (jobId?: number, fileName?: string) => {
    const baseEndpoint = "File/GetRoofingWRXReportFile";
    return jobId !== undefined || fileName !== undefined
      ? `${baseEndpoint}?jobId=${jobId}&fileName=${fileName}`
      : baseEndpoint;
  },
  getEagleViewPremiumReport: (jobId?: number) => {
    const baseEndpoint = "File/GetEagleViewPremiumReport";
    return jobId !== undefined ? `${baseEndpoint}?jobid=${jobId}` : baseEndpoint;
  },
  uploadRoofingWRXJobFiles: (jobId?: number) => {
    const baseEndpoint = "File/UploadRoofingWRXJobFiles";
    return jobId !== undefined ? `${baseEndpoint}/${jobId}` : baseEndpoint;
  },
  downloadUserGuide: "File/GetRPUserGuide",
  downloadAdminUserGuide: "File/GetRPAdminUserGuide ",

  // Profile
  updateManufacturerSettings: "Company/ManufacturerSettings",
  validateProfile: "Profile/ValidateProfile/",
  resendRPInvitation: "Profile/ResendRPInvitation",
  sendSmartBuildOutageMessage: "Profile/SendSmartBuildOutageMessage",
  updateRoofingWRXEnabled: "Profile/UpdateRoofingWRXEnabled",
  deactivateUser: "Profile/DeactivateUser",
  updateTrainingStatus: "Profile/UpdateTrainingStatus",
  acceptManufacturerLicenseAgreement: (manufacturerId?: number) =>
    `Profile/acceptManufacturerLicenseAgreement/${manufacturerId}`,

  // Order
  getSmartBuildProjects: "Order/GetProjectList",
  getJobSummary: "Order/GetJobStatusCount",
  processNewOrder: "Order/ProcessNewOrder",
  getRoofingWRXJobInfo: (jobId?: number) => {
    const baseEndpoint = "Order/GetRoofingWRXJobInfo";
    return jobId !== undefined ? `${baseEndpoint}/${jobId}` : baseEndpoint;
  },
  updateRoofingWRXJobComments: (jobId?: number) => {
    const baseEndpoint = "Order/UpdateRoofingWRXJobComments";
    return jobId !== undefined ? `${baseEndpoint}/${jobId}` : baseEndpoint;
  },

  // SmartBuildProject
  getSmartBuildToken: "SmartBuildProject/GetEditToken",
};
