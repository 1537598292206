import { NgModule } from "@angular/core";
import { EagleViewInitiateOrderComponent } from "./eagle-view-initiate-order/eagle-view-initiate-order.component";
import { RoofingWrxInitiateOrderComponent } from "./roofing-wrx-initiate-order/roofing-wrx-initiate-order.component";
import { XmlInitiateOrderComponent } from "./xml-initiate-order/xml-initiate-order.component";
import { CommonModule } from "@angular/common";
import { SharedModule } from "src/app/shared/shared.module";
import { FormsModule } from "@angular/forms";
import { SafePipe } from "src/app/shared/pipes/safe.pipe";
import { FlexLayoutModule } from "@angular/flex-layout";
import { DialogService } from "src/app/shared/services/dialog.service";
import { PictometryService } from "src/app/shared/services/pictometry.service";
import { OrderService } from "src/app/shared/services/order.service";

@NgModule({
  declarations: [
    EagleViewInitiateOrderComponent,
    RoofingWrxInitiateOrderComponent,
    XmlInitiateOrderComponent,
    SafePipe,
  ],
  imports: [CommonModule, SharedModule, FormsModule, FlexLayoutModule],
  exports: [
    EagleViewInitiateOrderComponent,
    RoofingWrxInitiateOrderComponent,
    XmlInitiateOrderComponent,
  ],
  providers: [OrderService, PictometryService, DialogService],
})
export class InitiateOrderModule {}
