<div *ngIf="auth$ | async as auth">
  <!--
    Account is linked
   -->
  <div *ngIf="isLinked">
    <div class="success-container">
      <svg class="icon-svg check">
        <use xlink:href="assets/img/icons.svg#check"></use>
      </svg>
      <h4 class="mb-2">Success!</h4>
      <p>Your {{ name }} account has been successfully linked to your Roofing Passport Account</p>
    </div>
    <div class="button-container">
      <button class="btn btn-primary" (click)="continue(auth)">Continue</button>
    </div>
  </div>
  <!--
    Account not yet linked
   -->
  <div *ngIf="!isLinked">
    <h4 role="heading" class="mb-2">
      {{ context }} Integration
      <span *ngIf="profile" class="manufacturer-name">({{ profile.manufacturer.name }})</span>
    </h4>
    <p *ngIf="!isAuthenticated" [innerHtml]="info"></p>
    <hr />
    <div fxLayout="column" *ngIf="this.useCredentials" [formGroup]="credentials">
      <h5>Log in with your {{ context }} Credentials</h5>
      <br />
      <div fxFlex="0 0 auto" class="form-field">
        <div class="form-label">Username</div>
        <input type="text" class="width-100-pct" formControlName="username" placeholder="" maxlength="50" />
      </div>
      <div fxFlex="0 0 auto" class="form-field">
        <div class="form-label">Password</div>
        <input type="password" class="width-100-pct" formControlName="password" placeholder="" maxlength="50" />
      </div>
      <hr />
    </div>
    <div class="error-container" *ngIf="errorMessage">
      <span [innerHtml]="errorMessage"></span>
      <button class="close" (click)="this.errorMessage = null">
        <svg class="icon-svg close">
          <use xlink:href="assets/img/icons.svg#close"></use>
        </svg>
      </button>
    </div>
    <div *ngIf="isAuthenticated">
      <p>You are currently logged in to {{ context }} with this account:</p>
      <br />
      <div class="claim" *ngFor="let claim of claims">
        <span class="claim-name">{{ claim.label }}</span
        >:&nbsp;
        <b class="claim-value">{{ claim.value }}</b>
      </div>
      <hr />
      <p>Would you like to link this account?</p>
      <hr />
      <div class="button-container">
        <button class="btn btn-link" type="button" [disabled]="isWorking" (click)="logout(auth)">
          Log out of {{ context }}
        </button>
        &nbsp;&nbsp;
        <button
          class="btn btn-primary"
          type="button"
          [class.spinner]="isWorking"
          [disabled]="isWorking"
          (click)="link(auth)"
        >
          Link this {{ context }} Account
        </button>
      </div>
    </div>
    <div class="button-container" *ngIf="!isAuthenticated && !useCredentials">
      <button
        class="btn btn-link"
        type="button"
        *ngIf="allowCredentials"
        [disabled]="isWorking"
        (click)="this.useCredentials = true"
      >
        Use Legacy Form
      </button>
      &nbsp;&nbsp;
      <button
        class="btn btn-primary"
        type="button"
        [class.spinner]="isWorking"
        [disabled]="isWorking"
        (click)="login(auth)"
      >
        {{ (auth.isPendingSignUp ? "Sign up" : "Log in") + " with " + context }}
      </button>
    </div>
    <div class="button-container" *ngIf="!isAuthenticated && useCredentials">
      <button class="btn btn-link" type="button" [disabled]="isWorking" (click)="this.useCredentials = false">
        Hide Legacy Form
      </button>
      &nbsp;&nbsp;
      <button
        class="btn btn-primary"
        type="button"
        [class.spinner]="isWorking"
        [disabled]="isWorking || !credentials.valid"
        (click)="legacyLogin(auth)"
      >
        Log in with Credentials
      </button>
    </div>
  </div>
</div>
