<div class="flex align-items-center justify-content-evenly mb-4">
  <ng-container *ngIf="!isLoadingStatistics && orderStatistics">
    <div class="w-12rem flex flex-column align-items-center justify-content-center shadow-2 py-2 border-round">
      <h3 class="text-base mb-1">Current month to date</h3>
      <p class="text-4xl font-bold">{{ orderStatistics.currentMonthToDate }}</p>
    </div>
    <div class="w-12rem flex flex-column align-items-center justify-content-center shadow-2 py-2 border-round">
      <h3 class="text-base mb-1">Current year to date</h3>
      <p class="text-4xl font-bold">{{ orderStatistics.currentYearToDate }}</p>
    </div>
    <div class="w-12rem flex flex-column align-items-center justify-content-center shadow-2 py-2 border-round">
      <h3 class="text-base mb-1">Rolling 12 months</h3>
      <p class="text-4xl font-bold">{{ orderStatistics.rolling12Months }}</p>
    </div>
  </ng-container>
  <ng-container *ngIf="isLoadingStatistics">
    <p-skeleton width="11rem" height="6rem"></p-skeleton>
    <p-skeleton width="11rem" height="6rem"></p-skeleton>
    <p-skeleton width="11rem" height="6rem"></p-skeleton>
  </ng-container>
</div>
