import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { AppState } from "./app-state";
import { ConfirmDialogComponent } from "src/app/features/confirm-dialog/confirm-dialog.component";

@Injectable({
  providedIn: "root",
})
export class DialogService {
  constructor(
    private appState: AppState,
    private dialog: MatDialog
  ) {}

  confirmDialog(
    title: string,
    message: string,
    primaryButtonText: string = "Yes",
    secondaryButtonText: string = "No",
    maxWidth: string = "800px"
  ): Observable<boolean> {
    return this.dialog
      .open(ConfirmDialogComponent, {
        data: { title, message, primaryButtonText, secondaryButtonText },
        minWidth: "400px",
        maxWidth,
        disableClose: true,
      })
      .afterClosed();
  }

  eagleViewNoCoverage(): Observable<boolean> {
    return this.confirmDialog(
      "No EagleView Coverage",
      "There is no detailed image data for this location.<br/><br/>If dimensioned sketches or plans are available for your project, we also provide the same services through Roofing WRX.",
      "Use Roofing WRX",
      "Retry Address"
    );
  }

  eagleViewUsernameChange(): Observable<boolean> {
    var message =
      "We are migrating usernames for all existing Roofing Passport EagleView accounts to <i>@eagleviewrp.com</i>. This is to solve a compatability issue with EagleView's new login system.<br/><br>If you are having trouble logging in with an existing EagleView account, please try changing the second half of your username to <i>@eagleviewrp.com</i>.<br/></br><b>Note:</b> Nothing has changed with your account other than the username.";
    if (this.appState.configs.EagleViewAuth.LegacyLogin) {
      message +=
        "<br/><br/> If you still are having issues logging in, try using the &quot;Use Legacy Form&quot; button below to log in with your credentials through Roofing Passport. This is a temporary workaround and will be removed once the migration is complete.";
    }
    return this.confirmDialog(
      "(5/17/2022) EagleView Username Change",
      message,
      "Continue to EagleView",
      "Cancel",
      "600px"
    );
  }

  invalidCreditCard(maxWidth = "800px"): Observable<boolean> {
    const dialogTitle = "Invalid credit card";

    const dialogMessage = `
      There is an issue with your account that has prevented your job from being submitted.
      This may be an issue with your credit card setup. To add a new credit card to your EagleView account:
      <br />
      <br />
      <ol>
        <li>Please login to your MyEagleview account by visiting
          <a href='http://my.eagleview.com/Login'>http://my.eagleview.com/Login</a>.
        </li>
        <li>Enter your new Eagleview - Roofing Passport Credentials.</li>
        <li>Once logged in, click on <b>"MYEAGLEVIEW”</b> and select <b>"BILLING INFORMATION"</b>.</li>
      </ol>
      If you are still having issues, please contact us at
      <a href="mailto:support-roofingpassport@sherwin.com">support-roofingpassport@sherwin.com</a>.
    `;

    const dialogOkButtonText = "OK";

    return this.confirmDialog(dialogTitle, dialogMessage, dialogOkButtonText, null, maxWidth);
  }

  roofingWRXBillingSetup(maxWidth = "800px"): Observable<boolean> {
    var branches = this.appState.roofingWRXBranches;
    var branchesNeedingSetup = branches.filter((b) => b.billing_status != 1);
    var canUpdatePayment = this.appState.isRoofingWRXAdmin && branchesNeedingSetup.length > 0;

    // determine message based on number of accessible branches and how many need to be set up
    var message = "Billing information has not been set up for ";
    if (branches.length > 1) {
      if (branchesNeedingSetup.length == branches.length) {
        message +=
          "any of your accessible Roofing WRX branches. You will not be able to create jobs through Roofing WRX or through XML upload until payment methods are updated.";
      } else {
        message +=
          "some of your accessible Roofing WRX branches. You will not be able to create jobs through Roofing WRX or through XML upload using these branches until payment is updated.";
      }
    } else if (branches.length > 0) {
      message +=
        "your Roofing WRX branch. You will not be able to create jobs through Roofing WRX or through XML upload until your payment method is updated.";
    } else {
      message =
        "You have not been assigned to any branches in Roofing WRX. You will not be able to create jobs through Roofing WRX or through XML upload until this is corrected on roofingwrx.com.";
    }

    if (canUpdatePayment) {
      message +=
        "<br/><br/>As a Roofing WRX admin, please update your billing profile in Roofing WRX using the button below.";
    } else {
      message +=
        "<br/><br/>Please contact your " +
        (this.appState.isBillingToManufacturer ? "associated manufacturer" : "company administrator") +
        " for assistance or more information.";
    }
    var afterClose = this.confirmDialog(
      "Roofing WRX Billing Setup",
      message,
      canUpdatePayment ? "Continue" : "OK",
      canUpdatePayment ? "Setup Billing" : null,
      maxWidth
    );
    afterClose.subscribe((clickedPrimary) => {
      if (!clickedPrimary && canUpdatePayment) {
        if (branchesNeedingSetup.length > 1) {
          window.open(this.appState.configs.RoofingWRXPath + "profile/view_profile", "_blank");
        } else {
          window.open(
            this.appState.configs.RoofingWRXPath + "profile/add_branch_payment/" + branchesNeedingSetup[0].id,
            "_blank"
          );
        }
      }
    });
    return afterClose;
  }

  roofingWRXBillingError(error_description: string, branchId: number, maxWidth = "800px"): Observable<boolean> {
    var canUpdatePayment = this.appState.isRoofingWRXAdmin;
    var message = "RoofingWRX encountered a billing error on your account. ";
    if (canUpdatePayment) {
      message += "<br/><br/>Please update your billing profile in RoofingWRX using the button below.";
    } else {
      message +=
        "<br/><br/>Please contact your " +
        (this.appState.isBillingToManufacturer ? "associated manufacturer" : "company administrator") +
        " for more information.";
    }
    if (this.appState.isBillingToCompany && error_description) {
      message += "<br/></br>" + "Error:" + "<br/>" + error_description;
    }
    var afterClose = this.confirmDialog(
      "Roofing WRX Billing Error",
      message,
      canUpdatePayment ? "Continue" : "OK",
      canUpdatePayment ? "Update Billing" : null,
      maxWidth
    );
    afterClose.subscribe((clickedPrimary) => {
      if (!clickedPrimary && canUpdatePayment) {
        window.open(this.appState.configs.RoofingWRXPath + "profile/update_branch_payment/" + branchId, "_blank");
      }
    });
    return afterClose;
  }
}

export interface ConfirmDialogData {
  title: string;
  message: string;
  primaryButtonText: string;
  secondaryButtonText: string;
}
