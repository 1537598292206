import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, timer } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SpinnerService {
  readonly defaultInitialDelay = 170;
  readonly defaultTitle = "Please Wait...";
  readonly status$: Observable<boolean>;
  readonly title$: Observable<string>;

  private statusSubject: BehaviorSubject<boolean>;
  private titleSubject: BehaviorSubject<string>;
  private isDelaying = false;

  private httpRequestInProgress = 0;

  constructor() {
    this.statusSubject = new BehaviorSubject<boolean>(false);
    this.titleSubject = new BehaviorSubject<string>(this.defaultTitle);

    this.status$ = this.statusSubject.asObservable();
    this.title$ = this.titleSubject.asObservable();
  }

  /**Shows the spinner and/or changes title of current spinner */
  show(
    title: string = this.defaultTitle,
    initialDelay: number = this.defaultInitialDelay,
    isHttpRequest = false
  ): void {
    title = title || this.defaultTitle;
    this.isDelaying = true;
    this.titleSubject.next(title);

    if (isHttpRequest) {
      this.httpRequestInProgress += 1;
    }

    if (initialDelay === 0) {
      this.statusSubject.next(true);
      this.isDelaying = false;
    } else {
      timer(initialDelay).subscribe(() => {
        if (this.isDelaying) {
          this.statusSubject.next(true);
        }
        this.isDelaying = false;
      });
    }
  }

  /** Hides the spinner */
  hide(isHttpRequest = false): void {
    if (isHttpRequest) {
      this.httpRequestInProgress > 0 ? (this.httpRequestInProgress -= 1) : 0;
    }

    if (this.httpRequestInProgress < 1) {
      this.isDelaying = false;
      this.statusSubject.next(false);
    }
  }

  /** Changes the title of the currently displayed spinner */
  changeTitle(title: string): void {
    this.titleSubject.next(title);
  }
}
