<p-panel class="admin-tasks-review">
  <ng-template pTemplate="header">
    <h5>Tasks</h5>
  </ng-template>
  <ng-template pTemplate="icons">
    <button pButton icon="pi pi-refresh" class="p-button-text" style="float: right" (click)="loadTasks()"></button>
  </ng-template>
  <div class="flex align-items-center justify-content-evenly mb-4">
    <ng-container *ngIf="!isLoadingTasks">
      <div class="w-12rem flex flex-column align-items-center justify-content-center shadow-2 py-2 border-round">
        <h3 class="text-base mb-1">New Company</h3>
        <p class="text-4xl font-bold">{{ newCompanyCount }}</p>
      </div>
      <div class="w-12rem flex flex-column align-items-center justify-content-center shadow-2 py-2 border-round">
        <h3 class="text-base mb-1">New User</h3>
        <p class="text-4xl font-bold">{{ newUserCount }}</p>
      </div>
    </ng-container>
    <ng-container *ngIf="isLoadingTasks">
      <p-skeleton width="11rem" height="6rem"></p-skeleton>
      <p-skeleton width="11rem" height="6rem"></p-skeleton>
    </ng-container>
  </div>

  <ng-template pTemplate="content">
    <p-table
      #tasksTable
      styleClass="p-datatable p-datatable-striped"
      [columns]="adminTasksTableColumns"
      [value]="tasks"
      [rows]="10"
      [paginator]="true"
      [loading]="isLoadingTasks"
      [scrollable]="true"
      [resizableColumns]="true"
      [rowHover]="true"
      [scrollable]="true"
      scrollHeight="750px"
      [resizableColumns]="true"
    >
      <ng-template pTemplate="header">
        <tr>
          <th
            *ngFor="let col of adminTasksTableColumns"
            pSortableColumn="{{ col.field }}"
            id="{{ col.field }}"
            scope="col"
          >
            {{ col.header }} <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
          <th></th>
        </tr>
        <tr>
          <th *ngFor="let col of adminTasksTableColumns" id="{{ col.field }}" scope="col">
            <input
              *ngIf="col.field !== 'createdOn'"
              pInputText
              type="text"
              (input)="tasksTable.filter($event.target.value, col.field, 'contains')"
              [value]="tasksTable.filters[col.field]?.value"
              [(ngModel)]="filters[col.field]"
              [placeholder]="col.header"
              class="w-full"
              class="w-full"
            />
            <p-calendar
              *ngIf="col.field === 'createdOn'"
              [(ngModel)]="filters.createdOn"
              (ngModelChange)="tasksTable.filter(this.filters.createdOn, 'createdOn', 'dateEquals')"
              [placeHolder]="col.header"
              [readonlyInput]="true"
              dateFormat="mm-dd-yy"
              styleClass="block"
              inputStyleClass="w-full"
              [placeholder]="col.header"
              panelStyleClass="w-25rem"
            ></p-calendar>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-task>
        <tr (click)="getTaskInfo(task)">
          <td>{{ task.typeDisplay }}</td>
          <td>{{ task.partnerSystem }}</td>
          <td>{{ task.inviteeFullName }}</td>
          <td>{{ task.inviteeCompanyName }}</td>
          <td>{{ task.createdOn | date: "MM/dd/yyyy" : "+0000" }}</td>
          <td>
            <button pButton pRipple icon="pi pi-eye" class="p-button-sm p-button-text p-button-primary mr-2"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-template>
</p-panel>
