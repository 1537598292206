import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subscription, of } from "rxjs";
import { AppConstants } from "src/app/shared/constants/app-constants";
import { ManufacturerProfile } from "src/app/core/data/models/AppInitializationData";
import { MessageService } from "primeng/api";
import { EagelViewFileTypeIds } from "src/app/core/data/models/EagleViewImageFileTypeIds";
import { EVImageFiles } from "src/app/core/data/models/SmartBuildProject";
import { WindowRefService } from "src/app/shared/helpers/window-ref.service";
import { AppState } from "src/app/shared/services/app-state";
import { OrderService } from "src/app/shared/services/order.service";
import { FileSaverService } from "src/app/shared/services/file-saver.service";
import { SmartBuildService } from "src/app/shared/services/smartbuild.service";
import { SmartBuildReportFileDownloadService } from "src/app/shared/services/smartbuild-report-file-download.service";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { HttpErrorResponse } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";

@Component({
  selector: "app-eagle-view-job-details",
  templateUrl: "./eagle-view-job-details.component.html",
  styleUrls: ["./eagle-view-job-details.component.scss"],
  providers: [MessageService],
})
export class EagleViewJobDetailsComponent implements OnInit {
  currentFiles: File[] = [];
  submitButtonDislabed = true;
  errorMessage?: string;
  busy?: Subscription;
  roofingWRXJobsEnabled = false;
  profile: ManufacturerProfile;
  profile$ = this.appState.profile$;
  uploadedFiles: any[] = [];
  items: any;
  evFiles: any[] = [];
  sbFiles: any[] = [];
  activeItem: any;
  comments: any;
  showUpdateJobModal = false;
  updateJobForm: FormGroup;
  smartBuildJobEditUrlBase = `${this.appState.configs.SmartBuildPath}Framer/EditWithToken/`;
  data: any;

  constructor(
    private appState: AppState,
    private dialogRef: DynamicDialogRef,
    private orderService: OrderService,
    private dialogConfig: DynamicDialogConfig,
    private fileSaverService: FileSaverService,
    private windowRef: WindowRefService,
    private smartbuildService: SmartBuildService,
    private smartbuildReportFileDownloadService: SmartBuildReportFileDownloadService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.data = this.dialogConfig.data;
    this.setMenuItems();
  }

  close() {
    this.dialogRef.close();
  }

  setMenuItems() {
    this.items = [{ label: "Download", icon: "pi pi-fw pi-download" }];
    this.data.status !== "New"
      ? (this.evFiles = [])
      : (this.evFiles = [
          {
            label: "Premium Roofing Report",
            icon: "pi pi-fw pi-file-pdf",
            value: EVImageFiles.PremiumPdf,
          },
          {
            label: "Top Image",
            icon: "pi pi-fw pi-image",
            value: EVImageFiles.TopImage,
          },
          {
            label: "North Side Image",
            icon: "pi pi-fw pi-image",
            value: EVImageFiles.NorthSideImage,
          },
          {
            label: "South Side image",
            icon: "pi pi-fw pi-image",
            value: EVImageFiles.SouthSideImage,
          },
          {
            label: "East Side Image",
            icon: "pi pi-fw pi-image",
            value: EVImageFiles.EastSideImage,
          },
          {
            label: "West Side Image",
            icon: "pi pi-fw pi-image",
            value: EVImageFiles.WestSideImage,
          },
        ]);
    this.data?.status === "New"
      ? (this.sbFiles = [{ label: "SmartBuild Output Report", icon: "pi pi-fw pi-image" }])
      : (this.sbFiles = []);
  }

  public downloadSmartBuildReportFile(): void {
    this.smartbuildReportFileDownloadService
      .downloadSmartBuildReportFile(this.data.jobId)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          var downloadErrorMessage: string;
          downloadErrorMessage = this.setSmartBuildOutputReportErrorMessage(error.status);
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: downloadErrorMessage,
          });
          return of(null);
        }),
        tap(() => {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Report has been downloaded.",
          });
        })
      )
      .subscribe();
  }

  downloadFile(fileType: number) {
    if (fileType === 0) {
      this.downloadPremiumPdf(this.data.jobId);
    } else {
      this.downloadImage(fileType, this.data.jobId);
    }
  }

  downloadImage(imageTypeId: number, jobId: any): void {
    this.orderService.downloadFile(jobId, imageTypeId).subscribe({
      next: this.handleDownloadImageFile.bind(this, jobId, imageTypeId),
      error: this.handleImageFileDownloadError.bind(this),
    });
  }

  downloadPremiumPdf(jobId): void {
    this.orderService.downloadPremiumPdf(jobId).subscribe({
      next: this.handleDownloadPremiumPdf.bind(this, jobId),
      error: this.handlePremiumPdfDownloadError.bind(this),
    });
  }

  openJobInSB() {
    this.openSB(this.data);
  }

  public openSB(params: any): void {
    this.smartbuildService.getSmartBuildToken().subscribe((token) => {
      this.windowRef.nativeWindow.open(`${this.smartBuildJobEditUrlBase}${params.jobId}?token=${token}`);
    });
  }

  private handleDownloadImageFile(jobId: number, imageTypeId: number, response: any): void {
    const blob: any = new Blob([response], {
      type: `${response.type}; charset=utf-8`,
    });
    const fileTypeName = EagelViewFileTypeIds[imageTypeId];
    const url = window.URL.createObjectURL(blob);
    this.fileSaverService.saveFile(blob, `job-${jobId}-${fileTypeName}.jpeg`);
  }

  private handleImageFileDownloadError(error: any) {
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: "Photo unavailable, try again later.",
    });
  }

  private setSmartBuildOutputReportErrorMessage(status: number) {
    var statusMessage: string;
    switch (status) {
      case 400:
        statusMessage = AppConstants.SmartBuildOutputReportErrors.NoDefaultOutputMessage;
        break;
      case 403:
        statusMessage = AppConstants.SmartBuildOutputReportErrors.UnauthorizedMessage;
        break;
      case 500:
        statusMessage = AppConstants.SmartBuildOutputReportErrors.ServerError;
        break;
      default:
        statusMessage = AppConstants.RoofingPassportCommunicationError;
    }
    return statusMessage;
  }

  private handleDownloadPremiumPdf(jobId: number, response: any): void {
    const blob: any = new Blob([response], {
      type: `application/pdf; charset=utf-8`,
    });
    const url = window.URL.createObjectURL(blob);
    this.fileSaverService.saveFile(blob, `${jobId}-EagleViewPremiumReport.pdf`);
  }

  private handlePremiumPdfDownloadError(error: any) {
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: "File download error, try again later.",
    });
  }
}
