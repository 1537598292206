import {Injectable, NgZone} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {AppState} from "./app-state";
import {catchError, map} from "rxjs/operators";
import {RoofingWRXJobInfo} from "src/app/core/data/models/RoofingWRXJobInfo";
import {ApiEndpoints} from "../constants/api-endpoints";
import {runInZone} from "../pipes/run-in-zone.pipe";
import {AuthenticationService} from "./authentication.service";
import {
  Address,
  CreateOrderRequest,
  IntegrationPartnerSystemData,
  OrderRoofData,
  OrderSequence
} from "../../core/data/models/CreateOrderRequest";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  constructor(
    private httpClient: HttpClient,
    private appState: AppState,
    private authService: AuthenticationService,
    private ngZone: NgZone
  ) {
  }

  processNewOrder(request: CreateOrderRequest, files: File[] = []) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });

    const formData: FormData = this.createOrderRequestToFormData(request);

    for (let i = 0; i < files.length; i++) {
      let file: File = files[i];
      formData.append("roofData.files", file, file.name);
    }

    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.processNewOrder}`;
    return this.httpClient.post(url, formData, {headers: headers});
  }

  public getRoofingWRXJobInfo(jobId: number): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.getRoofingWRXJobInfo(jobId)}`;

    return this.httpClient.get<RoofingWRXJobInfo>(url, {headers}).pipe(
      runInZone(this.ngZone),
      map((response) => {
        return response;
      }),
      catchError((err) => {
        return of([]);
      })
    );
  }

  addWRXJobComment(jobId: number, payload: any): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.updateRoofingWRXJobComments(jobId)}`;

    return this.httpClient.post<any>(url, payload, {headers});
  }

  addWRXJobFiles(jobId: number, files: File[]): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.uploadRoofingWRXJobFiles(jobId)}`;
    const formData: FormData = new FormData();
    files.forEach((file, index) => formData.append("files", file));
    return this.httpClient.post<any>(url, formData, {headers});
  }

  downloadFile(jobId: number, fileTypeId: number): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.getEagleViewReportFile(jobId, fileTypeId)}`;
    return this.httpClient.get(url, {headers, responseType: "blob"});
  }

  downloadWRXFile(jobId: number, fileName: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.getRoofingWRXReportFile(jobId, fileName)}`;
    return this.httpClient.get(url, {
      headers,
      responseType: "text" as "json",
    });
  }

  downloadPremiumPdf(jobId: number): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.profileId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.getEagleViewPremiumReport(jobId)}`;
    return this.httpClient.get(url, {headers, responseType: "blob"});
  }

  createOrderRequestToFormData(request: CreateOrderRequest) {
    const formData = new FormData();

    formData.append("jobName", request.jobName);
    formData.append("jobType", request.jobType);

    if (request.jobComments) {
      formData.append("jobComments", request.jobComments);
    }

    if (request.orderSequence) {
      formData.append("orderSequence", request.orderSequence?.toString());
    }

    if (request.address) {
      formData.append("address.street", request.address.street);
      formData.append("address.city", request.address.city);
      formData.append("address.state", request.address.state);
      formData.append("address.zip", request.address.zip);
      formData.append("address.countryCode", request.address.countryCode);
    }

    if (request.hasChangedInLast4Years !== null && request.hasChangedInLast4Years !== undefined) {
      formData.append("hasChangedInLast4Years", request.hasChangedInLast4Years ? 'true' : 'false');
    }

    if (request.roofData) {
      formData.append("roofData.orderModel", request.roofData.orderModel);
      formData.append("roofData.orderModelPartnerSystem", request.roofData.orderModelPartnerSystem?.toString());
    }

    if (request.integrationPartnerSystemData) {
      formData.append("integrationPartnerSystemData.externalOrderId", request.integrationPartnerSystemData.externalOrderId);
      formData.append("integrationPartnerSystemData.partnerSystem", request.integrationPartnerSystemData.partnerSystem?.toString());
    }

    return formData;
  }
}
