<div
  class="appNotificationContainer"
  [class]="containerPositionClass"
  [style.width]="notification.containerWidth"
  [style.height]="notification.containerHeight"
>
  <div class="appNotification" [style.height]="notification.height" [style.width]="notification.width">
    <div class="notificationContent" [innerHTML]="notification.content"></div>
    <div class="buttonContainer" *ngIf="notification.showOKButton">
      <button type="button" (click)="hideNotification()">{{ notification.okButtonText }}</button>
    </div>
  </div>
</div>
