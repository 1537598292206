import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormArray, FormGroup, UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { of, Subscription } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { AdminTrainingStep, TrainingStep } from "src/app/core/data/models/PartnerRegistration";
import { AppState } from "src/app/shared/services/app-state";
import { ProfileService } from "src/app/shared/services/profile.service";
import { MessageService } from "primeng/api";

@Component({
  selector: "update-training-status",
  templateUrl: "./update-training-status.component.html",
  styleUrls: ["./update-training-status.component.scss"],
  providers: [MessageService],
})
export class UpdateTrainingStatusComponent implements OnInit {
  trainingSteps = [
    { label: "- Not Started -", value: TrainingStep.NotStarted },
    {
      label: "A - RP Onboarding Invited",
      value: TrainingStep.RPOnboardInviteSent,
    },
    {
      label: "B - RP Onboarding Scheduled",
      value: TrainingStep.RPOnboardScheduled,
    },
    {
      label: "C - RP Onboarding Initiated",
      value: TrainingStep.RPOnboardInitiated,
    },
    {
      label: "D - RP Onboarding Complete",
      value: TrainingStep.RPOnboardComplete,
    },
  ];

  adminTrainingSteps = [
    { label: "- Not Started -", value: AdminTrainingStep.NotStarted },
    { label: "1 - Upload Materials", value: AdminTrainingStep.UploadMaterials },
    {
      label: "2 - Configure Assemblies",
      value: AdminTrainingStep.ConfigureAssemblies,
    },
    {
      label: "3 - Configure Outputs",
      value: AdminTrainingStep.ConfigureOutputs,
    },
    { label: "4 - Verify TakeOffs", value: AdminTrainingStep.VerifyTakeOffs },
    {
      label: "5 - Admin Training Complete",
      value: AdminTrainingStep.AdminTrainingComplete,
    },
  ];

  trainingStatusUpdatesForm: FormGroup;
  isForAdmin: boolean = false;
  isSmartBuild: boolean = true;
  busy: Subscription;
  errorMessage: string;
  showPassword: boolean = false;
  selectedUser: any;
  manufacturerId: string;

  staticImagesBasePath = this.appState.configs.StaticImagesBasePath.endsWith("/")
    ? this.appState.configs.StaticImagesBasePath
    : this.appState.configs.StaticImagesBasePath.padEnd(this.appState.configs.StaticImagesBasePath.length + 1, "/");
  imageUrl = `url(${this.staticImagesBasePath}roofing-passport-site-login-background.jpg)`;

  get usersToUpdateTrainingStatus(): FormArray {
    return (this.trainingStatusUpdatesForm?.get("usersToUpdateTrainingStatus") as FormArray) || null;
  }

  constructor(
    public appState: AppState,
    public profileService: ProfileService,
    public fb: UntypedFormBuilder,
    public route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.setupForm();
    this.selectedUser = JSON.parse(this.route.snapshot.queryParams.users);
    this.manufacturerId = this.route.snapshot.queryParams.manufacturerId;
    if (this.selectedUser[0].role === "Normal") {
      this.isForAdmin = false;
    } else {
      this.isForAdmin = true;
    }
    this.patchForm(this.selectedUser);
  }

  setupForm() {
    this.trainingStatusUpdatesForm = this.fb.group({
      companyName: "",
      firstName: "",
      lastName: "",
      email: "",
      trainingStatus: null,
      adminTrainingStatus: null,
      manufacturerId: null,
    });
  }

  patchForm(user: any) {
    this.trainingStatusUpdatesForm.patchValue({
      companyName: user[0].companyName,
      firstName: user[0].firstName,
      lastName: user[0].lastName,
      email: user[0].email,
      trainingStatus: user[0].trainingStatusId,
      adminTrainingStatus: user[0].adminTrainingStatusId,
      manufacturerId: this.manufacturerId,
    });
  }

  submit() {
    this.profileService
      .updateTrainingStatus(this.trainingStatusUpdatesForm.value)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.errorMessage = err.message;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: this.errorMessage,
          });
          return of(null);
        }),
        tap(() => {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Training Status has been successfully updated for selected user.",
          });
          this.router.navigate(["jobs"]);
        })
      )
      .subscribe();
  }
}
