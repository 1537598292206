import { NgModule } from "@angular/core";
import { ProfileAcceptComponent } from "./profile-accept/profile-accept.component";
import { ProfileLicenseComponent } from "./profile-license/profile-license.component";
import { ProfileLinkComponent } from "./profile-link/profile-link.component";
import { ProfileSelectComponent } from "./profile-select/profile-select.component";
import { ProfileSetupComponent } from "./profile-setup/profile-setup.component";
import { ProfileStatusComponent } from "./profile-status/profile-status.component";
import { CoreModule } from "src/app/core/core.module";
import { CommonModule } from "@angular/common";
import { SharedModule } from "src/app/shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ProfileRoutingModule } from "./profile-routing.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatListModule } from "@angular/material/list";
import { LicenseAgreementComponent } from "../../root/license-agreement/license-agreement.component";
import { ProfileService } from "src/app/shared/services/profile.service";
import { AppState } from "src/app/shared/services/app-state";
import { AuthenticationService } from "src/app/shared/services/authentication.service";
import { DialogService } from "src/app/shared/services/dialog.service";
import { UserInfoService } from "src/app/shared/services/user-info.service";
import { UpdateTrainingStatusComponent } from "../update-training-status/update-training-status.component";

@NgModule({
  declarations: [
    ProfileAcceptComponent,
    ProfileLicenseComponent,
    ProfileLinkComponent,
    ProfileSelectComponent,
    ProfileSetupComponent,
    ProfileStatusComponent,
    LicenseAgreementComponent,
    UpdateTrainingStatusComponent,
  ],
  imports: [
    ProfileRoutingModule,
    CoreModule,
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MatListModule,
  ],
  exports: [
    ProfileAcceptComponent,
    ProfileLicenseComponent,
    ProfileLinkComponent,
    ProfileSelectComponent,
    ProfileSetupComponent,
    ProfileStatusComponent,
    LicenseAgreementComponent,
  ],
  providers: [ProfileService, AppState, DialogService, AuthenticationService, UserInfoService],
})
export class ProfileModule {}
