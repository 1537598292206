<div *ngIf="this.profile != null">
  <h4 role="heading" class="mb-2">New Company Invitation</h4>
  <p>
    You have been invited to join company
    <b>{{ this.profile.manufacturer.name }}</b>
  </p>
  <br />
  <div *ngIf="this.hasManufacturerPayee">
    <p>
      If you accept this invitation {{ this.profile.manufacturer.name }} will absorb the cost of quotes created through
      the Roofing Passport under this profile.
    </p>
    <br />
  </div>
  <p>
    After accepting this invitation, please refer back to the Welcome to Roofing Passport email. This email contains
    step by step instructions and a downloadable user guide to assist you in setting up your account and has further
    information on how to use Roofing Passport.
  </p>
  <br />
  <p>
    A unique set of credentials for EagleView, SmartBuild and RoofingWRX have been created for your account and can be
    found in the Welcome to Roofing Passport email. Use these credentials to link Roofing Passport to the Partner
    Systems in the next steps.
  </p>
  <br />
  <div *ngIf="this.hasManufacturerTOS">
    <h5 role="heading" class="mb-1">Additional Company Terms Of Service</h5>
    <a [href]="this.profile.manufacturer.termsUrl" target="_blank"
      >License Agreement for {{ this.profile.manufacturer.name }}</a
    >
  </div>
  <p-divider></p-divider>
  <div>
    <small *ngIf="errorMessage" id="username-help" class="p-error">{{ errorMessage }}</small>
    <div *ngIf="!errorMessage" class="validation-message">
      Select the box below to accept the invitation then click the Next button.
    </div>
    <div class="steps-bottom">
      <div class="agreement">
        <div class="checkbox-container">
          <label>
            I accept the invitation.
            <input type="checkbox" [(ngModel)]="licenseAccepted" (click)="errorMessage = null" />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div class="button-container">
        <button class="btn btn-link cancel-button-link" (click)="reject()">Reject</button>
        <button class="btn btn-primary" [disabled]="!licenseAccepted" (click)="accept()">Accept</button>
      </div>
    </div>
  </div>
</div>
