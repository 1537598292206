import { PanelTypesEnum } from "../enums/panel-types.enum";

const panelTypesList = [
  {
    name: PanelTypesEnum.CFC,
    displayName: "Concealed Fastened Panel (Clip)",
  },
  {
    name: PanelTypesEnum.CFS,
    displayName: "Concealed Fastened Panel (Nail Strip)",
  },
  {
    name: PanelTypesEnum.XF,
    displayName: "Exposed Fastened Panel",
  },
];

export default panelTypesList;
