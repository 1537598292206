import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MenuItem } from "primeng/api";
import { SpinnerService } from "src/app/core/spinner/spinner.service";
import { BaseChildRedirectComponent } from "../base-child-route-redirect-component";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { INavMenu } from "src/app/core/data/models/i-nav-menu.model";
import { SubSink } from "subsink";
import { AppState } from "src/app/shared/services/app-state";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
})
export class AdminComponent extends BaseChildRedirectComponent implements OnInit, OnDestroy {
  @Output() onClose = new EventEmitter();
  private subs = new SubSink();
  errorMessage: string;
  menuItems$: Observable<INavMenu[]>;
  defaultRouterLink: string[];
  returnButtonLink = ["../jobs"];
  headerText = "Administration";
  adminMenuItems: MenuItem[] = [];

  constructor(
    public appState: AppState,
    public spinner: SpinnerService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected store: Store
  ) {
    super(route, router, store);
  }
  get canInviteUsers() {
    return this.appState.isAdmin && !this.appState.isThirdPartyAdmin;
  }

  get canEditManufacturerSettings() {
    return this.appState.isAdmin && !this.appState.isThirdPartyAdmin;
  }

  get canManageUsers() {
    return this.appState.isAdminOrRPAdmin || this.appState.isAdmin; // For now
  }

  get canUseReporting() {
    return this.appState.canUseReporting;
  }

  ngOnInit() {
    this.setMenuItems();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  setMenuItems() {
    if (this.canInviteUsers) {
      this.adminMenuItems.push({
        label: "Invite User",
        routerLink: "./invite-user",
      });
    }
    if (this.canEditManufacturerSettings) {
      this.adminMenuItems.push({
        label: "Settings",
        routerLink: "./manufacturer-settings",
      });
    }
    if (this.canManageUsers) {
      this.adminMenuItems.push({
        label: "User Management",
        routerLink: "./user-management",
      });
    }
    if (this.canUseReporting) {
      this.adminMenuItems.push({
        label: "Reporting",
        routerLink: "./reporting",
      });
    }

    // this.adminMenuItems.push({
    //   label: "Work queue",
    //   routerLink: "./work-queue",
    // });

    if (this.appState.canUseAdminTasks) {
      this.adminMenuItems.push({
        label: "Tasks",
        routerLink: "./tasks",
      });
    }

  }

  returnToProjectList() {
    this.router.navigate(["../jobs"], { relativeTo: this.route });
  }
}
