<p-toast [style]="{ marginBottom: '10px' }" position="bottom-right"></p-toast>
<header class="page-header">
  <div class="header-container">
    <img src="{{ siteLogoUrl }}" alt="Site Logo" />
    <div class="elevate">
      <div *ngIf="claims" (click)="$event.stopPropagation()">
        <p-button (click)="menu.toggle($event)" styleClass="p-button-raised p-button-text">
          <div fxFlex="0 0 100%" fxLayout="column" fxLayoutAlign="center end">
            <div fxFlex="0 0 auto">
              <b>{{ claims.name }}</b>
            </div>
            <div class="manufacturer-name" *ngIf="profile$ | async" fxFlex="0 0 auto">
              {{ (profile$ | async)?.manufacturer.name }}
            </div>
          </div>
        </p-button>
        <p-tieredMenu #menu [model]="menuItems" [popup]="true"></p-tieredMenu>
      </div>
    </div>
  </div>
</header>
