<form novalidate [formGroup]="userForm" (keydown.enter)="$event.preventDefault()">
  <div class="card">
    <p-toast [style]="{ marginBottom: '40px' }" position="bottom-right"></p-toast>
    <p-toolbar styleClass="mb-4 gap-2">
      <ng-template pTemplate="left">
        <p-dropdown
          (onChange)="onManufacturerSelectionChanged($event)"
          id="companyId"
          [options]="manufacturers | async"
          optionLabel="name"
          optionValue="id"
          formControlName="companyId"
          [filter]="(manufacturers | async)?.length > 1"
          [disabled]="(manufacturers | async)?.length <= 1"
        ></p-dropdown>
      </ng-template>
      <ng-template pTemplate="right">
        <button
          pButton
          pRipple
          label="Download User Report"
          icon="p-button-raised pi pi-download"
          class="p-button-success"
          (click)="exportUsers()"
        ></button>
      </ng-template>
    </p-toolbar>
    <p-table
      #dt
      styleClass="p-datatable-sm p-datatable-striped"
      [value]="adminManufacturerUsers"
      [rows]="10"
      [paginator]="true"
      [tableStyle]="{ 'min-width': '100rem' }"
      [(selection)]="selectedUser"
      [rowHover]="true"
      dataKey="id"
      [scrollable]="true"
      scrollHeight="600px"
      [resizableColumns]="true"
      (sortFunction)="customSort($event)"
      [customSort]="true"
    >
      <ng-template pTemplate="caption">
        <div class="header-container">
          <h3 class="m-0">Manage Users</h3>
          <div class="clear-container">
            <p-button
              pRipple
              icon="pi pi-filter-slash"
              iconPos="left"
              styleClass="p-button-text p-button-info"
              (click)="clear(dt)"
              pTooltip="Reset filters"
              *ngIf="isClearVisible | async"
            ></p-button>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="fullName" style="min-width: 15rem" (click)="showClear(dt)">
            Name<p-sortIcon field="fullName"></p-sortIcon>
          </th>
          <th pSortableColumn="companyName" (click)="showClear(dt)">
            Company Name<p-sortIcon field="companyName"></p-sortIcon>
          </th>
          <th style="text-align: center" pSortableColumn="loggedInAndConnectedToPartnerSystems" (click)="showClear(dt)">
            Linked To Partn Sys<p-sortIcon field="loggedInAndConnectedToPartnerSystems"></p-sortIcon>
          </th>
          <th
            style="text-align: center"
            pSortableColumn="trainingCompleted"
            style="min-width: 10rem"
            (click)="showClear(dt)"
          >
            Training Status <p-sortIcon field="trainingCompleted"></p-sortIcon>
          </th>
          <th style="text-align: center" pSortableColumn="eagleViewOrders" (click)="showClear(dt)">
            EagleView Orders<p-sortIcon field="eagleViewOrders"></p-sortIcon>
          </th>
          <th style="text-align: center" pSortableColumn="roofingWRXOrders" (click)="showClear(dt)">
            WRX Orders<p-sortIcon field="roofingWRXOrders"></p-sortIcon>
          </th>
          <th style="text-align: center" pSortableColumn="xmlOrders" (click)="showClear(dt)">
            XML Orders<p-sortIcon field="xmlOrders"></p-sortIcon>
          </th>
          <th pSortableColumn="rpLastConnected" style="min-width: 10rem" (click)="showClear(dt)">
            Last Connected <p-sortIcon field="rpLastConnected"></p-sortIcon>
          </th>
          <th></th>
        </tr>
        <tr>
          <th>
            <input
              style="width: 85%"
              pInputText
              type="text"
              (input)="dt.filter($event.target.value, 'fullName', 'contains')"
              [value]="dt.filters['fullName']?.value"
              formControlName="fullNameFilterValue"
              placeholder="Search by Name"
              class="w-full"
              (keyup)="showClear(dt)"
            />
          </th>
          <th>
            <input
              style="width: 90%"
              pInputText
              type="text"
              (input)="dt.filter($event.target.value, 'companyName', 'contains')"
              [value]="dt.filters['companyName']?.value"
              formControlName="companyNameFilterValue"
              placeHolder="Search by Company"
              class="w-full"
              (keyup)="showClear(dt)"
            />
          </th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
        <tr (click)="viewUserDetails(user)">
          <td>{{ user.fullName }}</td>
          <td>{{ user.companyName }}</td>
          <td style="text-align: center">
            <i *ngIf="user.loggedInAndConnectedToPartnerSystems" class="pi pi-check" style="color: green"></i>
            <i *ngIf="!user.loggedInAndConnectedToPartnerSystems" class="pi pi-times" style="color: red"></i>
          </td>
          <td style="text-align: center">{{ user.trainingCompleted }}</td>
          <td style="text-align: center">{{ user.eagleViewOrders }}</td>
          <td style="text-align: center">{{ user.roofingWRXOrders }}</td>
          <td style="text-align: center">{{ user.xmlOrders }}</td>
          <td style="text-align: center">
            {{ user.rpLastConnected | date: "MM/dd/yyyy" }}
          </td>
          <td>
            <button
              stlye="width:50%;"
              pButton
              pRipple
              icon="pi pi-pencil"
              class="p-button-sm p-button-text p-button-primary mr-2"
              (click)="viewUserDetails(user)"
              pTooltip="Edit User"
            ></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div class="flex align-items-center justify-content-between">
          In total there are
          {{ adminManufacturerUsers ? adminManufacturerUsers.length : 0 }}
          users.
        </div>
      </ng-template>
    </p-table>
  </div>
  <p-dialog
    appendTo="body"
    (onClose)="clearSelectedUser()"
    [(visible)]="viewUserDetailsDialog"
    [draggable]="false"
    [style]="{ width: '60vw' }"
    header="User Details"
    [modal]="true"
    styleClass="p-fluid"
  >
    <p-toolbar styleClass="mb-4 gap-2">
      <ng-template pTemplate="right">
        <div *ngIf="resendButton | async">
          <p-button
            (click)="resendRPInvitation()"
            label="Resend Invite"
            styleClass="p-button-raised p-button-text"
          ></p-button>
        </div>
        <div *ngIf="enableWRX | async">
          <p-button
            (click)="addRoofingWRX()"
            label="Enable WRX"
            styleClass="p-button-raised p-button-text p-button-secondary"
          ></p-button>
        </div>
        <div>
          <p-button
            (click)="deactivateUser()"
            label="Deactivate"
            styleClass="p-button-raised p-button-text p-button-danger"
          ></p-button>
        </div>
      </ng-template>
    </p-toolbar>
    <p-accordion>
      <p-accordionTab header="Update User Training Status">
        <div>
          <label>RP Onboard Training</label>
          <p-dropdown
            id="trainingStatusId"
            [options]="trainingSteps"
            formControlName="trainingStatusId"
            optionLabel="label"
            optionValue="value"
          ></p-dropdown>
        </div>
        <div *ngIf="userForm?.controls.role?.value !== 'Normal'">
          <label>Smartbuild Admin Training</label>
          <p-dropdown
            id="adminTrainingStatusId"
            [options]="adminTrainingSteps"
            formControlName="adminTrainingStatusId"
            optionLabel="label"
            optionValue="value"
          ></p-dropdown>
        </div>
      </p-accordionTab>
    </p-accordion>
    <p-table [value]="selectedUsers" styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '50rem' }">
      <ng-template pTemplate="caption">
        <h4>
          {{ userForm?.controls.firstName.value }}
          {{ userForm?.controls.lastName.value }}
        </h4>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 11%; text-align: center">Invite Sent</th>
          <th style="width: 11%; text-align: center">Invite Accepted</th>
          <th style="width: 11%; text-align: center">Invite Date</th>
          <th style="width: 16%; text-align: center">Invitation Reedeemed Date</th>
          <th style="width: 16%; text-align: center">License Agreement Accepted</th>
          <th style="width: 12%; text-align: center">Completion Date</th>
          <th style="min-width: 10rem; text-align: center">Invited By</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-currentUser>
        <tr>
          <td style="width: 11%; text-align: center">
            <i *ngIf="currentUser.credentialsCreated" class="pi pi-check" style="color: green"></i>
            <i *ngIf="!currentUser.credentialsCreated" class="pi pi-times" style="color: red"></i>
          </td>
          <td style="width: 11%; text-align: center">
            <i
              *ngIf="currentUser.completionDate !== 'null' && currentUser.completionDate !== ''"
              class="pi pi-check"
              style="color: green"
            ></i>
            <i
              *ngIf="currentUser.completionDate === 'null' || currentUser.completionDate === ''"
              class="pi pi-times"
              style="color: red"
            ></i>
          </td>
          <td style="width: 11%; text-align: center">
            {{ currentUser.createdDate || "N/A" }}
          </td>
          <td style="width: 16%; text-align: center">
            {{ currentUser.invitationRedeemedDateTime || "N/A" }}
          </td>
          <td style="width: 16%; text-align: center">
            {{ currentUser.licenseAgreementAcceptedDate || "N/A" }}
          </td>
          <td style="width: 12%; text-align: center">
            {{ currentUser.completionDate || "N/A" }}
          </td>
          <td style="text-align: center">
            {{ currentUser.inviterName || "N/A" }}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <p-table [value]="selectedUsers" styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '50rem' }">
      <ng-template pTemplate="header">
        <tr>
          <th style="text-align: center">SB Credentials Created Date:</th>
          <th style="text-align: center">EV Credentials Created Date:</th>
          <th style="text-align: center">WRX Credentials Created Date</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-currentUser>
        <tr>
          <td style="text-align: center">
            {{ currentUser.smartBuildRegistrationCompleted || "N/A" }}
          </td>
          <td style="text-align: center">
            {{ currentUser.eagleViewRegistrationCompleted || "N/A" }}
          </td>
          <td style="text-align: center">
            {{ currentUser.roofingWRXRegistrationCompleted || "N/A" }}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <p-table [value]="selectedUsers" styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '50rem' }">
      <ng-template pTemplate="header">
        <tr>
          <th style="text-align: center">SB Token Present:</th>
          <th style="text-align: center">EV Token Present:</th>
          <th style="text-align: center">WRX Token Present</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-currentUser>
        <tr>
          <td style="text-align: center">
            <i *ngIf="currentUser.sbTokenPresent" class="pi pi-check" style="color: green"></i>
            <i *ngIf="!currentUser.sbTokenPresent" class="pi pi-times" style="color: red"></i>
          </td>
          <td style="text-align: center">
            <i *ngIf="currentUser.evTokenPresent" class="pi pi-check" style="color: green"></i>
            <i *ngIf="!currentUser.evTokenPresent" class="pi pi-times" style="color: red"></i>
          </td>
          <td style="text-align: center">
            <i *ngIf="currentUser.wrxTokenPresent" class="pi pi-check" style="color: green"></i>
            <i *ngIf="!currentUser.wrxTokenPresent" class="pi pi-times" style="color: red"></i>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <ng-template pTemplate="body">
      <div class="card">
        <div class="formgrid grid">
          <div class="field col-12 md:col-6">
            <label for="companyName"><b>Company Name:</b> {{ userForm?.controls.companyName?.value }}</label>
          </div>
          <div class="field col-12 md:col-6">
            <label for="companyAddress"><b>Company Address:</b> {{ userForm?.controls.companyAddress?.value }}</label>
          </div>
          <div class="field col-12 md:col-6">
            <label for="email"><b>Email:</b> {{ userForm?.controls.email?.value }}</label>
          </div>
          <div class="field col-12 md:col-6">
            <label for="phoneNumber"><b>Phone Number:</b> {{ userForm?.controls.phoneNumber?.value }}</label>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="footer">
      <button pButton pRipple label="Close" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
      <button
        pButton
        pRipple
        label="Save"
        icon="pi pi-check"
        class="p-button-text"
        (click)="saveUserTrainingStatus()"
      ></button>
    </ng-template>
  </p-dialog>
</form>
