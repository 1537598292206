<div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
  <h1 fxFlex="1 1" mat-dialog-title>Frequently Asked Questions</h1>
</div>
<hr />
<div class="faq-container" mat-dialog-content>
  <div class="faq-entry" *ngFor="let faqEntry of entries">
    <div class="font-weight-bold blue">{{ faqEntry.question }}</div>
    <div>{{ faqEntry.answer }}</div>
  </div>
</div>
