import { Component, OnInit } from "@angular/core";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { skipWhile, take } from "rxjs/operators";
import { FAQEntry } from "src/app/core/data/models/AppInitializationData";
import { AppState } from "src/app/shared/services/app-state";

@Component({
  selector: "app-faq-dialog",
  templateUrl: "./faq-dialog.component.html",
  styleUrls: ["./faq-dialog.component.css"],
})
export class FaqDialogComponent implements OnInit {
  entries: FAQEntry[] = [];

  constructor(
    private appState: AppState,
    private dialogRef: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    this.appState.appData$
      .pipe(
        skipWhile((d) => d === null),
        take(1)
      )
      .subscribe((data) => (this.entries = data.faqEntries));
  }

  close() {
    this.dialogRef.close();
  }
}
