import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  constructor() {}

  getLocalStorage(): Storage {
    return localStorage;
  }
}
