import { RouterModule, Routes } from "@angular/router";
import { AdminInvitationComponent } from "./admin-invitation/admin-invitation.component";
import { AdminSettingsComponent } from "./admin-settings/admin-settings.component";
import { AdminUsersComponent } from "./admin-users/admin-users.component";
import { NgModule } from "@angular/core";
import { AdminComponent } from "./admin.component";
import { AdminReportingComponent } from "./admin-reporting/admin-reporting.component";
import { WorkQueueComponent } from "./work-queue/work-queue.component";
import { AdminTasksReviewComponent } from "./admin-tasks/admin-tasks-review.component";


const AdminRoutes: Routes = [
  {
    path: "admin",
    component: AdminComponent,
    children: [
      {
        path: "",
        component: AdminInvitationComponent,
      },
      {
        path: "invite-user",
        component: AdminInvitationComponent,
      },
      {
        path: "manufacturer-settings",
        component: AdminSettingsComponent,
      },
      {
        path: "user-management",
        component: AdminUsersComponent,
      },
      {
        path: "reporting",
        component: AdminReportingComponent,
      },
      // {

      //   path: "work-queue",
      //   component: WorkQueueComponent,
      // }
      {
        path: "tasks",
        component: AdminTasksReviewComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(AdminRoutes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
