import { NgModule } from "@angular/core";
import { HomeComponent } from "./home.component";
import { CoreModule } from "src/app/core/core.module";
import { CommonModule } from "@angular/common";
import { AppState } from "src/app/shared/services/app-state";

@NgModule({
  declarations: [HomeComponent],
  imports: [CoreModule, CommonModule],
  providers: [AppState],
})
export class HomeModule {}
