import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, ChildActivationStart, NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { Observable, ReplaySubject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppConstants } from "src/app/shared/constants/app-constants";
import { AuthStatus, ManufacturerProfile, ThirdPartyAuth } from "src/app/core/data/models/AppInitializationData";
import { AppState } from "src/app/shared/services/app-state";

@Component({
  selector: "app-profile-setup",
  templateUrl: "./profile-setup.component.html",
  styleUrls: ["./profile-setup.component.scss"],
})
export class ProfileSetupComponent implements OnInit, OnDestroy {
  @ViewChild(RouterOutlet) outlet: RouterOutlet;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  // set up observable for a current profile
  profile: ManufacturerProfile;
  profile$: Observable<ManufacturerProfile>;

  showSteps = false;
  licenseAgreementAccepted = false;
  busy: Subscription;
  profileSub: Subscription;
  routerSub: Subscription;

  staticImagesBasePath = this.appState.configs.StaticImagesBasePath.endsWith("/")
    ? this.appState.configs.StaticImagesBasePath
    : this.appState.configs.StaticImagesBasePath.padEnd(this.appState.configs.StaticImagesBasePath.length + 1, "/");
  imageUrl = `url(${this.staticImagesBasePath}roofing-passport-site-login-background.jpg)`;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appState: AppState
  ) {}

  ngOnInit(): void {
    // subscribe to route changes
    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((route) => {
      // we need to deactivate the outlet for child routes
      if (route instanceof ChildActivationStart && route.snapshot.url[0]?.path === "profile") {
        this.outlet.deactivate();
      }
      // if we navigate directly to the parent '/profile' route and we already have a loaded profile
      // we should process it to proceed to the necessary 'next' route.
      if (route instanceof NavigationEnd && route.url === "/profile") {
        this.waitForProfile();
      }
    });

    // subscribe to profile changes
    this.appState.profile$.pipe(takeUntil(this.destroyed$)).subscribe(this.onUpdatedProfile.bind(this));

    // once we have init data, wait for a current profile profile
    this.appState.appDataOnce$.subscribe(this.waitForProfile.bind(this));
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  waitForProfile() {
    if (this.appState.needsCurrentProfile) {
      this.router.navigate(["select"], { relativeTo: this.route });
    } else if (this.profile) {
      this.onUpdatedProfile(this.profile);
    } else {
      this.busy = this.appState.profileOnce$.subscribe();
    }
  }

  /**
   * Called when a new profile selection is observed
   * @param profile The newly selected profile
   */
  onUpdatedProfile(profile: ManufacturerProfile) {
    this.profile = profile;

    // check if there is a next auth context that needs to be linked and notify
    const nextAuth = this.appState.nextInvalidAuth();

    // set local state
    this.licenseAgreementAccepted = profile.licenseAgreement?.accepted;
    this.showSteps =
      !this.appState.currentProfileIsPending &&
      !this.appState.needsCurrentProfile &&
      !this.appState.showRegistrationStatus;
    // navigate to child route based on current profile
    if (this.appState.currentProfileIsPending && !this.appState.needsCurrentProfile) {
      this.router.navigate(["accept"], { relativeTo: this.route });
    } else if (this.appState.needsCurrentProfile) {
      this.router.navigate(["select"], { relativeTo: this.route });
    } else if (!this.licenseAgreementAccepted) {
      this.router.navigate(["license"], { relativeTo: this.route });
    } else if (nextAuth) {
      this.router.navigate(["link", nextAuth.context], {
        relativeTo: this.route,
      });
    } else {
      this.router.navigate(["jobs"]);
    }
  }

  isAuthActive(auth: ThirdPartyAuth) {
    return this.licenseAgreementAccepted && auth.context === this.appState.nextInvalidAuth()?.context;
  }

  isAuthComplete(auth: ThirdPartyAuth) {
    return auth.status == AuthStatus.Valid;
  }

  getAuthName(auth: ThirdPartyAuth): string {
    return AppConstants.ThirdPartyServiceName[auth.context] || "";
  }
}
