import { AuthContext } from "src/app/shared/services/authentication.service";
import { AddressData } from "./ManufacturerInvitation";
import { AdminTrainingStep, TrainingStep } from "./PartnerRegistration";

export interface AdminTask {
  id: string;
  typeId: number;
  type: string;
  typeDisplay: string;
  partnerSystemId?: number;
  partnerSystem?: AuthContext;

  inviteeUserId?: number;
  inviteeIsAdmin?: boolean;
  inviteeFirstName?: string;
  inviteeLastName?: string;
  inviteeFullName?: string;
  inviteeEmail?: string;
  inviteePhoneNumber?: string;
  inviteeCompanyId?: number;
  inviteeCompanyName?: string;
  inviteeCompanyPhoneNumber?: string;
  inviteeCompanyAddress?: AddressData;

  inviterUserId?: number;
  inviterFirstName?: string;
  inviterLastName?: string;
  inviterFullName?: string;
  inviterEmail?: string;
  inviterPhoneNumber?: string;
  inviterCompanyId?: number;
  inviterCompanyName?: string;
  inviterCompanyAddress?: AddressData;
  inviterCompanyPhoneNumber?: string;

  lastCompletedTrainingStep?: TrainingStep;
  lastCompletedTrainingStepDate?: any;
  lastCompletedAdminTrainingStep?: AdminTrainingStep;
  lastCompletedAdminTrainingStepDate?: any;

  createdBy: number;
  createdOn: string;

  completedBy?: number;
  completedOn?: string;
}

export enum AdminTaskTypeIds {
  NewCompany = 1,
  NewUser = 2,
}
