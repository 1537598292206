import { Component, OnInit } from "@angular/core";
import { OrderStatistics } from "src/app/core/data/models/Reporting";
import { ReportingService } from "src/app/shared/services/reporting.service";
import { MessageService } from "primeng/api";

@Component({
  selector: "app-order-statistics",
  templateUrl: "./order-statistics.component.html",
  styleUrls: ["./order-statistics.component.scss"],
  providers: [MessageService],
})
export class OrderStatisticsComponent implements OnInit {
  orderStatistics: OrderStatistics | null = null;
  isLoadingStatistics: boolean = false;

  constructor(
    private reportingService: ReportingService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.loadOrderStatistics();
  }

  loadOrderStatistics(): void {
    this.isLoadingStatistics = true;
    this.reportingService.getOrderStatistics().subscribe({
      next: (statistics) => {
        this.orderStatistics = statistics;
        this.isLoadingStatistics = false;
      },
      error: (err) => {
        this.showErrorToast(err.message);
        this.isLoadingStatistics = false;
      },
    });
  }

  private showErrorToast(message: string): void {
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: message,
    });
  }
}
