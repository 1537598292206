import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, of, ReplaySubject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { find as _find } from "lodash-es";

import stateList from "../../../../core/data/states";
import jobTypesList from "src/app/core/data/job-types";
import { AppConstants } from "src/app/shared/constants/app-constants";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { AppState } from "src/app/shared/services/app-state";
import { DialogService } from "src/app/shared/services/dialog.service";
import { PictometryService } from "src/app/shared/services/pictometry.service";
import { OrderService } from "src/app/shared/services/order.service";
import { MessageService } from "primeng/api";
import { CreateOrderRequest, OrderSequence, PartnerSystem } from "../../../../core/data/models/CreateOrderRequest";

@Component({
  selector: "app-eagle-view-initiate-order",
  templateUrl: "./eagle-view-initiate-order.component.html",
  styleUrls: ["./eagle-view-initiate-order.component.scss"],
  providers: [MessageService],
})

/** EagleViewInitiateOrder component*/
export class EagleViewInitiateOrderComponent implements OnInit {
  @Output() onSelectTab = new EventEmitter<number>();
  checked = true;
  message = "";
  states = stateList;
  jobTypes = jobTypesList;
  submitButtonDisabled = false;
  newProjectForm: UntypedFormGroup = this.fb.group({
    projectName: ["", [Validators.required, Validators.maxLength(100)]],
    address: ["", [Validators.required, Validators.maxLength(50)]],
    city: ["", [Validators.required, Validators.maxLength(50)]],
    state: ["", [Validators.required]],
    zip: [
      "",
      [
        Validators.required,
        Validators.pattern(new RegExp(`${AppConstants.USZipRegex}|${AppConstants.CAZipRegex}`, "i")),
      ],
    ],
    promoCode: ["", [Validators.pattern("[a-zA-Z0-9-]{0,30}")]],
    jobType: ["", [Validators.required]],
    comments: ["", []],
  });

  toolTipMessage = this.appState.configs.ChangesinLast4YearsToolTip;
  pictometryUrl = "about:blank";
  latitude: number;
  longitude: number;
  changesInLast4Years: boolean;
  searchResultMessage = this.appState.configs.SearchResultMessage;
  addressDiffMessage = this.appState.configs.MapAddressChangedMessage;
  multipleManyMapResultsFoundMessage = this.appState.configs.TooManyMapResultsFoundMessage;
  showAddressDiffMessage = false;
  showSearchResultMessage = false;
  profile$ = this.appState.profile$;
  busy: Subscription;

  private $destroyed: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: DynamicDialogRef,
    private appState: AppState,
    private pictometryService: PictometryService,
    private orderService: OrderService,
    private dialogService: DialogService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.pictometryService
      .onImageDataSet()
      .pipe(takeUntil(this.$destroyed))
      .subscribe(this.handleImageDataSet.bind(this));

    this.pictometryService
      .onPinPositionSet()
      .pipe(takeUntil(this.$destroyed))
      .subscribe(this.handlePinPositionSet.bind(this));

    this.pictometryService
      .onAddressSearchComplete()
      .pipe(takeUntil(this.$destroyed))
      .subscribe(this.handleAddressSearchComplete.bind(this));
  }

  ngOnDestroy() {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

  close() {
    this.dialogRef.close();
  }

  public createProject() {
    this.validateAllFormFields(this.newProjectForm);

    if (!this.newProjectForm.valid) {
      return;
    }

    this.submitButtonDisabled = true;

    const orderModel = {
      ...this.newProjectForm.value,
      latitude: this.latitude,
      longitude: this.longitude,
      changesInLast4Years: this.changesInLast4Years,
    };

    const payload: CreateOrderRequest = {
      jobName: this.projectName.getRawValue(),
      jobType: this.jobType.getRawValue(),
      jobComments: this.comments.getRawValue(),
      address: {
        street: this.address.getRawValue(),
        city: this.city.getRawValue(),
        state: this.usState.getRawValue(),
        zip: this.zip.getRawValue(),
        countryCode: "USA"
      },
      orderSequence: OrderSequence.SmartBuildToEagleView,
      hasChangedInLast4Years: this.changesInLast4Years,
      roofData: {
        orderModel: JSON.stringify(orderModel),
        orderModelPartnerSystem: PartnerSystem.EagleView,
      },
    }

    this.orderService.processNewOrder(payload).subscribe(
      (_response) => {
        this.close();
      },
      (error) => {
        this.submitButtonDisabled = false;
        this.handleNewOrderErrorResponse(error);
      }
    );
  }

  setIpaUrl(ipaUrl: string) {
    this.pictometryUrl = ipaUrl;
  }

  onChecked(): void {
    this.changesInLast4Years = !this.changesInLast4Years;
  }

  findAddress() {
    this.showAddressDiffMessage = false;
    this.showSearchResultMessage = false;
    this.message = "";
    this.latitude = null;
    this.longitude = null;
    const addressString = `${this.address.value},${this.city.value},${this.usState.value},${this.zip.value}`;
    this.pictometryService.setAddress(addressString);
  }

  get projectName() {
    return this.newProjectForm.get("projectName");
  }

  get comments() {
    return this.newProjectForm.get("comments");
  }

  get address() {
    return this.newProjectForm.get("address");
  }

  get city() {
    return this.newProjectForm.get("city");
  }

  get usState() {
    return this.newProjectForm.get("state");
  }

  get zip() {
    return this.newProjectForm.get("zip");
  }

  get promoCode() {
    return this.newProjectForm.get("promoCode");
  }

  get jobType() {
    return this.newProjectForm.get("jobType");
  }

  private validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  private handleImageDataSet(response): void {
    if (response) {
      this.submitButtonDisabled = false;
    } else {
      this.submitButtonDisabled = true;
      this.dialogService.eagleViewNoCoverage().subscribe((clickedPrimary) => {
        if (clickedPrimary) {
          this.onSelectTab.emit(1);
        }
      });
    }
  }

  private handlePinPositionSet(latlong: { lat: number; long: number }) {
    this.latitude = latlong.lat;
    this.longitude = latlong.long;
  }

  private handleAddressSearchComplete(response: any): void {
    this.latitude = null;
    this.longitude = null;
    const selectedFeature =
      response.featureCount > 1 ? _find(response.features, { success: true }) : response.features[0];

    this.showSearchResultMessage = true;
    const addressFields = selectedFeature.fields;
    if (_find(addressFields, { name: "Street" })?.value !== this.newProjectForm.controls["address"].value) {
      this.newProjectForm.controls["address"].patchValue(_find(addressFields, { name: "Street" }).value);
      this.showAddressDiffMessage = true;
    }
    if (_find(addressFields, { name: "State" })?.value !== this.newProjectForm.controls["state"].value) {
      this.newProjectForm.controls["state"].patchValue(_find(addressFields, { name: "State" }).value);
      this.showAddressDiffMessage = true;
    }
    if (_find(addressFields, { name: "City" })?.value !== this.newProjectForm.controls["city"].value) {
      this.newProjectForm.controls["city"].patchValue(_find(addressFields, { name: "City" }).value);
      this.showAddressDiffMessage = true;
    }
    let postalCode: string = _find(addressFields, { key: "postalCode" })?.value;
    const caZipMatch = new RegExp(AppConstants.CAZipRegex, "i").exec(postalCode);
    if (caZipMatch == null) {
      // for US postal addresses, only use the first 5 digits
      postalCode = postalCode.split("-")[0];
    }
    if (postalCode !== this.newProjectForm.controls["zip"].value) {
      this.newProjectForm.controls["zip"].patchValue(postalCode);
      this.showAddressDiffMessage = true;
    }
  }

  private handleNewOrderErrorResponse(error: HttpErrorResponse): Observable<any> {
    this.message = error.error.detail ?? this.appState.configs.NewOrderCommunicationErrorMessage;

    if (this.message && this.message.includes("Invalid credit card")) {
      this.dialogService.invalidCreditCard();
    } else {
      this.showToastErrorMessage(this.message);
    }

    this.resetFlagsAndButtons();

    return of({});
  }

  private showToastErrorMessage(message: string): void {
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: message,
    });
  }

  private resetFlagsAndButtons(): void {
    this.showAddressDiffMessage = false;
    this.showSearchResultMessage = false;
    this.submitButtonDisabled = false;
  }
}
