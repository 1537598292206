export interface SmartBuildProject {
  jobId: number;
  name: string;
  streetAddress: string;
  cityStateZip: string;
  status: string;
  createdDate: string;
  modifiedDate: string;
  canOpen: boolean;
  eagleViewAction: string;
  jobNotes: string;
  externalSource: ExternalSource;
  externalId: number;
  customData: CustomData;
}

export interface CustomData {
  canCancel: boolean;
  hasPremiumReport: boolean;
  xmlSource: string;
  roofingWRXJobId: string;
  roofingWRXStatus: RoofingWRXStatus;
  roofingWRXStatusMessage: string;
}

export enum ExternalSource {
  None = 0,
  EagleView = 1,
  RoofingWRX = 2,
  XMLFile = 3,
}

export enum RoofingWRXStatus {
  Pending = "Pending",
  Working = "Working",
  Incomplete = "Incomplete",
  Complete = "Complete",
}

export enum EVImageFiles {
  PremiumPdf = 0,
  TopImage = 6,
  NorthSideImage = 22,
  SouthSideImage = 23,
  EastSideImage = 24,
  WestSideImage = 25,
}

export enum SmartBuildFileType {
  OutputReport = 99,
}

export enum XmlSource {
  RoofingWRX = "roofing-wrx",
  EagleView = "eagle-view",
  Generic = "generic",
}
