import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ProfileSetupComponent } from "./profile-setup/profile-setup.component";
import { ProfileSelectComponent } from "./profile-select/profile-select.component";
import { ProfileAcceptComponent } from "./profile-accept/profile-accept.component";
import { ProfileLicenseComponent } from "./profile-license/profile-license.component";
import { ProfileStatusComponent } from "./profile-status/profile-status.component";
import { ProfileLinkComponent } from "./profile-link/profile-link.component";
import { HomeComponent } from "src/app/root/home/home.component";
import { UpdateTrainingStatusComponent } from "../update-training-status/update-training-status.component";

const routes: Routes = [
  {
    path: "profile",
    component: ProfileSetupComponent,
    children: [
      {
        path: "select",
        component: ProfileSelectComponent,
      },
      {
        path: "accept",
        component: ProfileAcceptComponent,
      },
      {
        path: "license",
        component: ProfileLicenseComponent,
      },
      {
        path: "status",
        component: ProfileStatusComponent,
      },
      {
        path: "link/:context",
        component: ProfileLinkComponent,
      },
      {
        path: "acceptinvitation",
        component: HomeComponent,
      },
    ],
  },
  {
    path: "update-training-status",
    component: UpdateTrainingStatusComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProfileRoutingModule {}
