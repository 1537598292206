<div class="container admin-panel-container">
  <div class="grid">
    <div class="col-3">
      <div class="text-center p-3">
        <roofing-passport-side-navigation
          [headerText]="headerText"
          [returnButtonText]="'Return To Job List'"
          [returnButtonRouterLink]="returnButtonLink"
          [menuItems]="adminMenuItems"
        >
        </roofing-passport-side-navigation>
      </div>
    </div>
    <div class="col-9 adminContainer">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
