import { Component, Input, OnInit } from "@angular/core";
import { UiTemplateData } from "src/app/core/data/models/AppInitializationData";
import { WindowRefService } from "../../shared/helpers/window-ref.service";
import { AppState } from "src/app/shared/services/app-state";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
})
export class FooterComponent implements OnInit {
  @Input() footerData: UiTemplateData[];
  currentYear = new Date().getFullYear();
  copyrightUrl: UiTemplateData;
  contactData: UiTemplateData[];
  policyData: UiTemplateData[];
  marketingData: UiTemplateData[];
  configs = this.appState.configs;
  staticImagesBasePath = this.appState.configs.StaticImagesBasePath.endsWith("/")
    ? this.appState.configs.StaticImagesBasePath
    : this.appState.configs.StaticImagesBasePath.padEnd(this.appState.configs.StaticImagesBasePath.length + 1, "/");
  marketingBannerUrl = `${this.staticImagesBasePath}roofing-passport-site-marketing-banner.jpg?${this.appState.imageRefreshToken}`;

  constructor(
    private windwService: WindowRefService,
    public appState: AppState
  ) {}

  ngOnInit(): void {
    this.copyrightUrl = this.footerData.filter((f) => f.key === "copyright")[0];
    this.contactData = this.footerData.filter((f) => f.key === "contact");
    this.policyData = this.footerData.filter((f) => f.key === "policy");
    this.marketingData = this.footerData.filter((f) => f.key === "marketing");
  }

  elevateYourProducts() {
    this.windwService.nativeWindow.open(this.appState.configs.ElevateYourProducts);
  }
}
