import { Action, NgxsAfterBootstrap, NgxsOnInit, State, StateContext, StateToken, Store } from "@ngxs/store";
import { APP_DEFAULTS, AppStateModel } from "./app.models";
import { Injectable } from "@angular/core";
import { AuthenticationService } from "src/app/shared/services/authentication.service";
import { AppInitializerService } from "src/app/shared/services/app-initializer.service";
import { PictometryService } from "src/app/shared/services/pictometry.service";
import { OrderService } from "src/app/shared/services/order.service";
import { ProfileService } from "src/app/shared/services/profile.service";
import { SmartBuildService } from "src/app/shared/services/smartbuild.service";
import { UserInfoService } from "src/app/shared/services/user-info.service";
import { AppActions } from "./app.actions";

export const APP_STATE_TOKEN = new StateToken<AppStateModel>("app");

@State<AppStateModel>({
  name: APP_STATE_TOKEN,
  defaults: APP_DEFAULTS,
})
@Injectable()
export class AppStateComponent implements NgxsOnInit, NgxsAfterBootstrap {
  constructor(
    private authService: AuthenticationService,
    private appInitializerService: AppInitializerService,
    private pictometryService: PictometryService,
    private orderService: OrderService,
    private profileService: ProfileService,
    private smartBuildService: SmartBuildService,
    private userInfoSetting: UserInfoService,
    private store: Store
  ) {}

  async ngxsAfterBootstrap({ patchState, getState, dispatch }: StateContext<AppStateModel>) {
    const state = getState();

    this.initializeServices(state, dispatch);
  }

  private initializeServices(state: AppStateModel, dispatch: any) {
    // const userId = state.userConfig.userId;
    // const manufacturerId = state.userConfig.manufacturerId;
    // todo: pass in Data to each necessary service.
  }

  async ngxsOnInit() {}

  @Action(AppActions.SetManufacturers)
  public setManufacturers(
    { dispatch, patchState, getState }: StateContext<AppStateModel>,
    { manufacturers }: AppActions.SetManufacturers
  ) {
    patchState({
      manufacturers: manufacturers,
    });
  }

  @Action(AppActions.SetAppStateError)
  public appStateError(
    { patchState }: StateContext<AppStateModel>,
    { type, code, message }: AppActions.SetAppStateError
  ) {
    return patchState({
      error: {
        type,
        code,
        message,
      },
    });
  }

  @Action(AppActions.ClearAppStateError)
  public clearAppStateError({ patchState }: StateContext<AppStateModel>) {
    return patchState({
      error: null,
    });
  }
}
