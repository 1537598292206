<div class="side-nav-wrapper">
  <p-panel>
    <p-header *ngIf="headerText">
      <div class="isActiveNav side-nav-header" [pTooltip]="headerText">
        {{ headerText }}
      </div>
    </p-header>
    <p-menu styleClass="side-nav-menu" [model]="menuItems"></p-menu>
  </p-panel>

  <div class="back-btn-container" *ngIf="returnButtonRouterLink">
    <button
      pButton
      type="button"
      label="{{ returnButtonText }}"
      class="p-button-primary side-nav-back"
      icon="pi pi-chevron-left"
      iconPos="left"
      style="display: inline-block"
      [routerLink]="returnButtonRouterLink"
    ></button>
  </div>
</div>
