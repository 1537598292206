import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ReplaySubject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ManufacturerProfile } from "src/app/core/data/models/AppInitializationData";
import { ProfileStatus } from "src/app/core/store/app.models";
import { AppState } from "src/app/shared/services/app-state";
import { ProfileService } from "src/app/shared/services/profile.service";

@Component({
  selector: "app-profile-select",
  templateUrl: "./profile-select.component.html",
})
export class ProfileSelectComponent implements OnInit {
  private destroyed$ = new ReplaySubject<boolean>(1);

  constructor(
    private appState: AppState,
    private profileService: ProfileService,
    private router: Router
  ) {}

  profiles: ManufacturerProfile[] = [];
  busy: Subscription;

  ngOnInit(): void {
    this.appState.appDataOnce$.subscribe((data: any) => this.filterProfiles(data.profiles));

    this.appState.activeProfiles$.pipe(takeUntil(this.destroyed$)).subscribe((profiles) => {
      this.filterProfiles(profiles);
    });
  }

  selectProfile(profile: ManufacturerProfile) {
    this.busy = this.profileService.chooseProfile(profile.manufacturer.id).subscribe(() => {
      this.router.navigate(["profile"]);
    });
  }

  filterProfiles(profiles: ManufacturerProfile[]) {
    this.profiles =
      profiles.filter(
        (x: ManufacturerProfile) =>
          (x.roofingWRXEnabled && x.roofingWRXJobsEnabled && x.allRegistrationCompleted) ||
          x.nonWRXRegistrationCompleted
      ) ?? [];
  }
}
